import { CardContent } from '@material-ui/core';
import {
  Box,
  Divider,
  Typography,
  styled,
  IconButton,
  Collapse,
  Dialog,
  DialogTitle, DialogContent, DialogActions, Button,
} from '@mui/material';
import React, { useState } from 'react';
import WhereToVoteIcon from '@mui/icons-material/WhereToVote';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import ListIcon from '@mui/icons-material/List';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import ReplyOutlinedIcon from '@mui/icons-material/ReplyOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import BaseCard from './BaseCard';
import DeliveryRoutePrintout from '../../containers/fulfillment/containers/deliveries/DeliveryRoutePrintout';
import RequestService from '../../services/RequestService';
import config from '../../config';

const DeliveryRouteCard = ({ deliveryRoute, onDeleteDeliveryRoute, showMarkDelivered }) => {
  const [itemsExpanded, setItemsExpanded] = useState(false);
  const [delivered, setDelivered] = useState(deliveryRoute.deliveries[0].delivered);

  const deliver = () => {
    RequestService.post(`${config.backendUrl}/delivery-routes/deliver`, { deliveryIds: deliveryRoute.deliveries.map((d) => d.id) }, () => {
      setDelivered(true);
    });
  };

  // dialog variables
  const [dialogOpen, setDialogOpen] = useState(false);
  const handleDialogOpen = () => setDialogOpen(true);
  const handleDialogClose = () => setDialogOpen(false);

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const handleConfirmDeleteDeliveryRoute = () => setShowDeleteConfirmation(true);

  // Initialize address variables from object
  const deliveryArray = deliveryRoute.deliveries;
  const firstAddy = deliveryArray[0].deliveryAddress;
  const lastAddy = deliveryArray[deliveryArray.length - 1].deliveryAddress;
  // addy string slicing
  const newFirstAddy = firstAddy.split(', ').slice(0, 1);
  const newLastAddy = lastAddy.split(', ').slice(0, 1);

  // calculate total items in the whole route for items section
  // create new array storing all reservations items as objects
  let totalItems = 0;
  const allItemsArray = [];

  for (let i = 0; i < deliveryArray.length; i += 1) {
    const reservationItemsArray = deliveryArray[i].reservationItems;

    for (let j = 0; j < reservationItemsArray.length; j += 1) {
      const quantityOfItems = reservationItemsArray[j].reservedQuantity;
      totalItems += quantityOfItems;

      // push objects into allitemsarray
      allItemsArray.push(reservationItemsArray[j]);
    }
  }

  // EXPAND MORE Function from Resevation Card
  const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  }));

  const handleDeliveryRoutePrint = (id) => {
    window.open(`/fulfillment/delivery-routes/${id}/print`);
  };

  const handleDeleteDeliveryRoute = (id) => {
    RequestService.delete(`${config.backendUrl}/delivery-routes/${id}`, () => {
      onDeleteDeliveryRoute(id);
    });
    setShowDeleteConfirmation(false);
  };

  return (
    <BaseCard>
      <CardContent>
        {/* Green Header */}
        <Box className="route-card-style header">
          <Typography variant="h6" pr={1}>
            Route
          </Typography>
          <Typography variant="h6">{deliveryRoute.id}</Typography>
        </Box>
        <Box className="route-card-style route-body">
          <Box className="Total stops addy-info">
            <PlaceOutlinedIcon />
            <Typography ml={0.5}>
              {deliveryRoute.deliveries.length}
              {' '}
              Stops
            </Typography>

            <ExpandMore
              expand={itemsExpanded}
              onClick={() => setItemsExpanded(!itemsExpanded)}
              aria-expanded={itemsExpanded}
              aria-label="show more"
              size="small"
            >
              <ExpandLessIcon fontSize="8" />
            </ExpandMore>
          </Box>

          <Collapse
            in={!itemsExpanded}
            timeout="auto"
            className="start-end-addys"
          >
            {deliveryArray.length > 1
              ? (
                <Box className="Starting Address addy-info">
                  <WhereToVoteIcon className="green" />
                  <Typography className="addy-details" variant="body1">
                    {newFirstAddy}
                  </Typography>
                </Box>
              ) : (
                null
              )}
            <Box className="Ending Address addy-info" mb={1}>
              <LocationOnIcon className="gray" />
              <Typography className="addy-details" variant="body1">
                {newLastAddy}
              </Typography>
            </Box>
          </Collapse>
        </Box>

        {/* Items drop down list */}
        <Box className=" route-card-style items">
          <ListIcon className="list-icon" />
          <Typography mr={0.5}>{totalItems}</Typography>
          <Typography>Items</Typography>

          <ExpandMore
            expand={itemsExpanded}
            onClick={() => setItemsExpanded(!itemsExpanded)}
            aria-expanded={itemsExpanded}
            aria-label="show more"
            size="small"
          >
            <ExpandMoreIcon fontSize="8" />
          </ExpandMore>
        </Box>

        <Collapse in={itemsExpanded} timeout="auto">
          <Box overflow="hidden" height="125px">
            {allItemsArray.map((reservationItem) => (
              <Box className="card-content-row">
                <Box pr={2}>
                  <Typography>
                    <strong>{reservationItem.reservedQuantity}</strong>
                  </Typography>
                </Box>
                <Typography>{reservationItem.itemName}</Typography>
              </Box>
            ))}
          </Box>
        </Collapse>

        <Collapse in={!itemsExpanded} timeout="auto">
          <Box className="route-card-style whitespace" />
        </Collapse>

        <Divider />

        <Box className="route-card-style footer">
          <Box py={0.5}>
            <IconButton onClick={handleDialogOpen}>
              <UnfoldMoreIcon
                className="expand"
                style={{ fontSize: '20px' }}
              />
            </IconButton>
            <Dialog
              maxWidth="md"
              open={dialogOpen}
              onClose={handleDialogClose}
              PaperProps={{
                sx: {
                  borderRadius: '10px',
                },
              }}
            >
              <Box className="dialog-reservation-overlay">
                <DeliveryRoutePrintout
                  deliveryRoute={deliveryRoute}
                  handleDialogClose={handleDialogClose}
                />
              </Box>
            </Dialog>
            <IconButton sx={{ ml: '8px' }}>
              <PrintOutlinedIcon
                style={{ padding: '2px' }}
                onClick={() => {
                  handleDeliveryRoutePrint(deliveryRoute.id);
                }}
              />
            </IconButton>
          </Box>
          {showMarkDelivered
            && (!delivered ? (
              <Box px={1}>
                <Button variant="outlined" onClick={deliver}>
                  <Box px={2}>
                    Deliver
                  </Box>
                </Button>
              </Box>
            ) : (
              <Box>
                Delivered!
              </Box>
            )
            )}
          <Box>
            <IconButton>
              <ReplyOutlinedIcon style={{ padding: '2px' }} />
            </IconButton>
            <IconButton
              sx={{ ml: '5px' }}
              onClick={handleConfirmDeleteDeliveryRoute}
            >
              <DeleteIcon style={{ padding: '2px' }} />
            </IconButton>
            <Dialog
              open={showDeleteConfirmation}
              onClose={() => setShowDeleteConfirmation(false)}
            >
              <DialogTitle>Confirm Deletion</DialogTitle>
              <DialogContent>
                Are you sure you want to delete this Delivery Route?
              </DialogContent>
              <DialogActions>
                <Button
                  variant="secondary"
                  onClick={() => setShowDeleteConfirmation(false)}
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    handleDeleteDeliveryRoute(deliveryRoute.id);
                  }}
                  variant="danger"
                >
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
        </Box>
      </CardContent>
    </BaseCard>
  );
};

export default DeliveryRouteCard;
