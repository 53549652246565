import { Container } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router';
import { APPS } from '../../constants';

const useStyles = makeStyles(() => ({
  adminContainer: {
  },
}));

const Admin = (props) => {
  const match = useRouteMatch();
  const classes = useStyles();
  return (
    <Container className={classes.adminContainer}>
      <Switch>
        {Object.values(APPS.ADMIN.pages).map((page) => (
          <Route key={page.title} path={`${match.path}/${page.baseRoute}`}>
            <page.component {...props} />
          </Route>
        ))}
      </Switch>
    </Container>
  );
};

export default Admin;
