import {
  Typography, Box, List, ListItem,
} from '@mui/material';
import React from 'react';
import { observer } from 'mobx-react';
import { useHistory, useLocation } from 'react-router';
import Fuse from 'fuse.js';
import PropTypes from 'prop-types';
import { PAGES as ADMIN_PAGES } from '../admin/constants';
import { APPS } from '../../constants';
import { PAGES as INVENTORY_PAGES } from '../inventory/constants';

const queryString = require('query-string');

const SEARCH_RESULT_MAPPINGS = {
  Property: '/property-management/properties/',
  Client: '/property-management/clients/',
};

const PAGES_SEARCH_LIBRARY = [
  {
    searchable: 'scanner app application',
    title: 'Scanner Application',
    url: '/scanner',
  },
  {
    searchable: 'employees',
    title: 'Employee Management',
    url: `${APPS.ADMIN.baseRoute}/${ADMIN_PAGES.EMPLOYEES.baseRoute}`,
  },
  {
    searchable: 'items',
    title: 'Item Management',
    url: `${APPS.INVENTORY.baseRoute}/${INVENTORY_PAGES.ITEMS.baseRoute}`,
  },
];

const Search = ({ searchStore }) => {
  const location = useLocation();
  const history = useHistory();

  const fuseOptions = { keys: ['searchable'] };
  const fuse = new Fuse(PAGES_SEARCH_LIBRARY, fuseOptions);

  const parsed = queryString.parse(location.search);
  const searchString = parsed.search;
  searchStore.getSearchResults(searchString);
  const pageResults = fuse.search(searchString);

  return (
    <div>
      <Typography variant="h5">
        Pages Result
      </Typography>
      <Box>
        <List>
          {pageResults.map((result) => (
            <ListItem
              button
              onClick={() => history.push(result.item.url)}
            >
              <Typography variant="body">{result.item.title}</Typography>
            </ListItem>
          ))}
        </List>
      </Box>

      <Typography variant="h5">
        Records Result
      </Typography>
      <Box>
        <List>
          {searchStore.searchResults.map((result) => (
            <ListItem
              button
              onClick={() => history.push(`${SEARCH_RESULT_MAPPINGS[result.searchable_type]}${result.searchable_id}`)}
            >
              <Typography variant="body">
                <b>
                  {result.searchable_type}
                  :
                  {' '}
                </b>
                {' '}
                {result.content}
              </Typography>
            </ListItem>
          ))}
        </List>
      </Box>
    </div>
  );
};

Search.propTypes = {
  searchStore: PropTypes.any,
};

export default observer(Search);
