import React from 'react';
import { Button, CircularProgress, Box } from '@mui/material';

const WushButton = ({
  loading, disabled, children, ...rest
}) => (
  <Button
    disabled={disabled || loading}
    {...rest}
  >
    {loading
      ? (
        <Box pl={5} pr={5}>
          <CircularProgress size={10} />
        </Box>
      )
      : children}
  </Button>
);

export default WushButton;
