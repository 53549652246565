import React, { useState } from 'react';
import {
  Box,
  Button,
  Menu,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography,
} from '@mui/material';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import { addDays, addMonths } from 'date-fns';

const DateFilter = ({ dateFilter, handleDateFilterCheck }) => {
  const dateOptions = [
    { label: 'Today', value: new Date() },
    { label: 'This Week', value: addDays(new Date(), 7) },
    { label: 'This Month', value: addMonths(new Date(), 1) },
  ];
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleDateFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleDateFilterClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box classname="date-filter">
      <Button
        className="filterButton"
        display="flex"
        id="date-button"
        onClick={handleDateFilterClick}
      >
        <CalendarTodayOutlinedIcon />
        <Typography ml={1}>Select Dates</Typography>
      </Button>
      <Menu id="date-menu" anchorEl={anchorEl} open={open} onClose={handleDateFilterClose}>
        <FormGroup>
          {dateOptions.map((date) => (
            <FormControlLabel
              sx={{
                width: '200px',
                m: 0,
                p: 0,
              }}
              control={(
                <Checkbox
                  onChange={(e) => handleDateFilterCheck(date.label, e.target.checked, date.value)}
                  checked={dateFilter.includes(date.label)}
                />
              )}
              label={date.label}
            />
          ))}
        </FormGroup>
      </Menu>
    </Box>
  );
};

export default DateFilter;
