import { Box, Typography } from '@mui/material';
import React from 'react';

const Reports = () => (
  <Box py={2}>
    <Typography variant="h3">Reports</Typography>
  </Box>
);

export default Reports;
