import {
  Autocomplete, Box, Button, Grid, Stack, TextField,
} from '@mui/material';
import React from 'react';
import { useRouteMatch } from 'react-router';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import PrintIcon from '@mui/icons-material/Print';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import EmailIcon from '@mui/icons-material/Email';
import RequestService from '../../../../services/RequestService';
import config from '../../../../config';
import Loader from '../../../../components/loader/Loader';
import PickupCard from './PickupCard';

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,
  borderRadius: '10px',

  // change background colour if dragging
  background: isDragging ? 'white' : 'white',
  transform: isDragging ? 'rotate(10deg)' : 'rotate(10deg)',
  boxShadow: isDragging ? '8px 8px 10px darkgrey' : '',

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? 'lightgrey' : 'lightgrey',
  borderRadius: '10px',
  padding: grid,
  width: '100%',
});

const Pickup = ({ employeeStore }) => {
  const [originalItems, setOriginalItems] = React.useState([]);
  const [items, setItems] = React.useState([]);
  const [routeName, setRouteName] = React.useState(null);
  const [employee, setEmployee] = React.useState(null);
  const [needsSave, setNeedsSave] = React.useState(false);

  const match = useRouteMatch();
  const { pickupId } = match.params;

  if (items.length === 0) {
    RequestService.get(`${config.backendUrl}/pickup-routes/${pickupId}`, (response) => {
      setOriginalItems(response.data.pickups);
      setItems(response.data.pickups);
      setRouteName(response.data.name);
      setEmployee(response.data.employee);
    });
  }

  if (items.length === 0) return <Loader />;

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const reorderedItems = reorder(
      items,
      result.source.index,
      result.destination.index,
    );

    setItems(reorderedItems);
    setNeedsSave(true);
  };

  const savePickup = () => {
    RequestService.post(`${config.backendUrl}/pickup-routes/${pickupId}`, {
      pickupRoute: {
        pickups: items,
        employeeId: employee?.id,
        name: routeName,
      },
    }, () => setNeedsSave(false));
  };

  const resetOrder = () => {
    setItems(originalItems);
    setNeedsSave(false);
  };

  const pickupAll = () => {
    RequestService.post(`${config.backendUrl}/pickup-routes/pickup`, { pickupIds: items.map((item) => item.id) }, (response) => {
      setItems(response.data);
      setOriginalItems(response.data);
    });
  };

  return (
    <Box pt={2}>
      <Grid container className="hide-print">
        <Grid item md={6}>
          <Box mb="2rem">
            <TextField fullWidth label="Route name" value={routeName} onChange={(e) => { setRouteName(e.target.value); setNeedsSave(true); }} />
          </Box>
          <Box mb="2rem">
            <Autocomplete
              id="combo-box-demo"
              options={employeeStore.employees}
              getOptionLabel={(option) => (option.firstName ? `${option.firstName} ${option.lastName}` : '')}
              renderInput={(params) => <TextField {...params} label="Assign Employee" variant="outlined" />}
              value={employee}
              onChange={(_event, value) => {
                setEmployee(value);
                setNeedsSave(true);
              }}
            />
          </Box>
        </Grid>
        <Grid item md={6}>
          <Box px={3}>
            <Stack spacing={2} direction="row">
              <Button disabled={!needsSave} onClick={resetOrder} variant="outlined">Undo</Button>
              <Button disabled={!needsSave} onClick={savePickup} variant="contained">Save</Button>
              <Button onClick={pickupAll}>
                Pickup All
              </Button>
            </Stack>
          </Box>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item md={8}>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
              {(providedUpper, snapshotUpper) => (
                <Box
                  {...providedUpper.droppableProps}
                  ref={providedUpper.innerRef}
                  style={getListStyle(snapshotUpper.isDraggingOver)}
                >
                  {items.map((item, index) => (
                    <Draggable key={item.id} draggableId={`${item.id}`} index={index}>
                      {(provided, snapshot) => (
                        <Box
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style,
                          )}
                        >
                          <PickupCard pickup={item} />
                        </Box>
                      )}
                    </Draggable>
                  ))}
                  {providedUpper.placeholder}
                </Box>
              )}
            </Droppable>
          </DragDropContext>
        </Grid>
        <Grid item md={4} className="hide-print">
          <Box pl={2} mb={2}>
            <Button variant="contained" onClick={() => window.print()}>
              <Box mr="1rem">
                <PrintIcon />
              </Box>
              Print Pickup
            </Button>
          </Box>
          <Box pl={2} mb={2}>
            <Button disabled variant="outlined" onClick={() => window.print()}>
              <Box mr="1rem">
                <PhoneAndroidIcon />
              </Box>
              Send to phone
            </Button>
          </Box>
          <Box pl={2}>
            <Button disabled variant="outlined" onClick={() => window.print()}>
              <Box mr="1rem">
                <EmailIcon />
              </Box>
              Email
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Pickup;
