import { Box } from '@mui/material';
import React from 'react';

const WushCard = ({ children, ...rest }) => (
  <Box border="1px solid lightgrey" borderRadius="10px" p="0.5rem" boxShadow="1px 1px 3px lightgrey" {...rest}>
    {children}
  </Box>
);

export default WushCard;
