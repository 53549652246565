import { TextField } from '@mui/material';
import React from 'react';

const WushTextField = ({
  formik, name, label, ...rest
}) => (
  <TextField
    fullWidth
    id={name}
    name={name}
    label={label}
    value={formik.values[name]}
    onChange={formik.handleChange}
    error={formik.touched[name] && Boolean(formik.errors[name])}
    helperText={formik.touched[name] && formik.errors[name]}
    {...rest}
  />
);

export default WushTextField;
