import { Box, TextField, Typography } from '@mui/material';
import React from 'react';

const CashPayment = ({ paymentAmount }) => {
  const [cashAmount, setCashAmount] = React.useState(null);
  const calculateChange = () => {
    const change = cashAmount - paymentAmount;
    return change >= 0 ? change : 0;
  };
  return (
    <Box>
      <Box>
        <TextField onChange={(e) => setCashAmount(e.target.value)} id="standard-basic" label="Cash Amount" variant="standard" />
      </Box>
      <Box>
        <Typography variant="body1">
          <b>Change:</b>
          {' '}
          $
          {calculateChange()}
        </Typography>
      </Box>
    </Box>
  );
};

export default CashPayment;
