import {
  LinearProgress, Typography, IconButton, Button, Box,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { DataGrid } from '@material-ui/data-grid';
import { observer } from 'mobx-react';
import { format } from 'date-fns';
import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import ItemCategoryAutocomplete from '../../components/ItemCategoryAutocomplete';

const useStyles = makeStyles(() => ({
  itemsTableContainer: {
    height: '500px',
  },
  itemsContainer: {
    paddingTop: '2rem',
  },
  buttonContainer: {
    marginBottom: '1rem',
  },
  categoriesCombo: {
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingTop: '8px',
  },
}));

const Items = ({ itemStore, itemCategoryStore }) => {
  const history = useHistory();
  const classes = useStyles();
  itemCategoryStore.getItemCategories();
  itemStore.getItems();
  itemStore.clearSelectedItem();

  if (!itemStore.itemsPreLoaded) {
    return (<LinearProgress />);
  }

  const columns = [
    {
      field: 'item',
      headerName: '',
      width: 50,
      renderCell: (params) => (
        <IconButton
          size="small"
          onClick={() => {
            history.push(`/inventory-management/items/${params.id}`);
          }}
        >
          <EditOutlinedIcon />
        </IconButton>
      ),
      editable: false,
      sortable: false,
    },
    {
      field: 'name',
      headerName: 'Name',
      width: 150,
      editable: true,
    },
    {
      field: 'itemCategoryId',
      headerName: 'Category',
      width: 300,
      renderCell: (params) => (
        <ItemCategoryAutocomplete
          itemCategoryStore={itemCategoryStore}
          params={params}
          onUpdate={itemStore.updateItem}
        />
      ),
      editable: false,
      sortable: false,
    },
    {
      field: 'description',
      headerName: 'Description',
      width: 150,
      editable: true,
    },
    {
      field: 'cost',
      headerName: 'Cost',
      width: 150,
      editable: true,
    },
    {
      field: 'weight',
      headerName: 'Weight',
      width: 150,
      editable: true,
    },
    {
      field: 'legacyScanId',
      headerName: 'Scan ID',
      width: 150,
      editable: true,
    },
    {
      field: 'halfDayRate',
      headerName: 'Half Day',
      width: 150,
      editable: true,
      type: 'number',
    },
    {
      field: 'fullDayRate',
      headerName: 'Full Day',
      width: 200,
      editable: true,
      type: 'number',
    },
    {
      field: 'weekRate',
      headerName: 'Week',
      width: 200,
      editable: true,
      type: 'number',
    },
    {
      field: 'retailSalesPrice',
      headerName: 'Retail Price',
      width: 200,
      editable: true,
      type: 'number',
    },
    {
      field: 'usedSalesPrice',
      headerName: 'Used Sales Price',
      width: 200,
      editable: true,
      type: 'number',
    },
    {
      field: 'taxRate',
      headerName: 'Tax Rate',
      width: 200,
      editable: true,
    },
    {
      field: 'createdAt',
      headerName: 'Created At',
      width: 200,
      editable: false,
      type: 'date',
    },
    {
      field: 'Actions',
      headerName: '',
      width: 100,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="secondary"
          onClick={() => itemStore.deleteItem(params.id)}
        >
          Delete
        </Button>
      ),
      editable: false,
      sortable: false,
    },
  ];

  const itemRows = itemStore.items.map((item) => ({
    id: item.id,
    itemCategoryId: item.itemCategoryId,
    name: item.name,
    description: item.description,
    cost: item.cost,
    halfDayRate: item.halfDayRate,
    fullDayRate: item.fullDayRate,
    weekRate: item.weekRate,
    retailSalesPrice: item.retailSalesPrice,
    usedSalesPrice: item.usedSalesPrice,
    taxRate: item.taxRate,
    createdAt: item.createdAt && format(new Date(item.createdAt), 'PP'),
    weight: item.weight,
    legacyScanId: item.legacyScanId,
  }));

  return (
    <Box py={2}>
      <Typography variant="h3">Items</Typography>
      <div className={classes.buttonContainer}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => itemStore.addItem()}
        >
          Add Item
        </Button>

      </div>
      <div className={classes.itemsTableContainer}>
        <DataGrid
          rows={itemRows}
          columns={columns}
          pageSize={10}
          disableSelectionOnClick
          onCellEditCommit={itemStore.updateItem}
        />
      </div>
    </Box>
  );
};

Items.propTypes = {
  itemStore: PropTypes.any,
  itemCategoryStore: PropTypes.any,
};

export default observer(Items);
