import { observable, decorate, action } from 'mobx';
import config from '../config';
import RequestService from '../services/RequestService';
import BaseStore from './BaseStore';

class LocationZoneStore extends BaseStore {
  constructor() {
    super()
    this.buildFromSession();
  }

  locationZones = null;
  locationZonesPreLoaded = false;

  getLocationZones = (forceUpdate = false, callback) => {
    (!this.locationZonesPreLoaded || forceUpdate) && RequestService.get(`${config.backendUrl}/location-zones`, (response) => {
      response.data.sort((a,b) => { return a.id - b.id })
      this.locationZones = response.data
      this.locationZonesPreLoaded = true
      this.writeToSession()
      callback && callback()
    })
  }

  addLocationZone = () => {
    this.addRecord('location-zones', {locationZone: {name: 'placeholder'}}, this.locationZones)
  }

  updateLocationZone = (locationZone) => {
    const updatePayload = {
      locationZone: {
        [locationZone.field]: locationZone.value
      }
    }
    RequestService.post(`${config.backendUrl}/location-zones/${locationZone.id}`, updatePayload, (response) => {
      this.selectedLocationZone = response.data
      let updatedlocationZones = this.locationZones;
      updatedlocationZones.splice(updatedlocationZones.findIndex((s) => s.id === locationZone.id), 1, response.data)
      this.locationZones = updatedlocationZones
      this.writeToSession()
    })
  }

  writeToSession = () => {
    const json = JSON.stringify(this);
    sessionStorage.setItem('LocationZoneStore', json);
  }
  
  buildFromSession = () => {
    const json = JSON.parse(sessionStorage.getItem('LocationZoneStore'))
    if (json) {
      this.locationZones = json.locationZones
      this.locationZonesPreLoaded = json.locationZonesPreLoaded
    }
  }
}

decorate(LocationZoneStore, {
  getlocationZones: action,
  locationZones: observable,
  locationZonesPreLoaded: observable
});

export default LocationZoneStore;