import React from 'react';
import DashboardIcon from '@mui/icons-material/Dashboard';
import Dashboard from './containers/dashboard/Dashboard';

export const PAGES = {
  DASHBOARD: {
    title: "Dashboard",
    baseRoute: "dashboard",
    icon: <DashboardIcon />
  }
}