import React from 'react';
import {
  Box, CssBaseline, List, ListItemButton, ListItemText,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useHistory } from 'react-router';
import ListItemIcon from '@mui/material/ListItemIcon';
import MuiDrawer from '@mui/material/Drawer';
import PropTypes from 'prop-types';
import { APPS } from '../../constants';
import Header from '../header/Header';
import { getCurrentApp } from '../../utils';

const drawerWidth = window.innerWidth > 1600 ? 200 : 75;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    zIndex: 0,
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
      zIndex: 1200,
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
      zIndex: 0,
    }),
  }),
);

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  zIndex: 0,
}));

const HeaderSidebar = ({ childProps }) => {
  const [open, setOpen] = React.useState(true);
  const history = useHistory();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  // const handleDrawerClose = () => {
  //   setOpen(false);
  // };

  const currentApp = getCurrentApp();

  const allowedAccess = (app) => {
    if (!app.auth) return true;
    return (
      app.auth.includes(childProps.authStore.accountType)
    );
  };

  return (
    <Box>
      <CssBaseline />
      <Header
        {...childProps}
        currentApp={currentApp}
        handleDrawerOpen={handleDrawerOpen}
        open={open}
      />
      {childProps.authStore.loggedIn && (
        <Drawer
          variant="permanent"
          open={open}
          className={`hide-print ${open ? '' : 'z-index-0'}`}
          PaperProps={{
            sx: {
              border: 'none',
            },
          }}
        >
          <DrawerHeader sx={{ justifyContent: 'center', paddingTop: '10px' }}>
            {/* <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton> */}
            {/* eslint-disable-next-line */}
            <img width="75" src={require('./wush_logo.png')} alt="logo" />
          </DrawerHeader>
          <List style={{ padding: 0 }}>
            {Object.keys(APPS).map((text) => (
              allowedAccess(APPS[text]) && (
                <ListItemButton
                  key={text}
                  style={{
                    minHeight: 32,
                    justifyContent: open ? 'initial' : 'center',
                    margin: '12px 0px 12px 16px',
                    display: 'flex',
                    borderRadius: '50px 0px 0px 50px',
                    background: currentApp.title === APPS[text].title ? '#00b4d2' : '',
                    '&:hover': {
                      background: '#00b4d2',
                    },
                  }}
                  onClick={() => history.push(`/${APPS[text].baseRoute}/${APPS[text].baseSubRoute || ''}`)}
                >
                  <Box mr={2}>
                    <ListItemIcon
                      style={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                      }}
                    >
                      {APPS[text].icon}
                    </ListItemIcon>
                  </Box>
                  {window.innerWidth > 1600
                    && (
                      <ListItemText
                        primary={APPS[text].title}
                        style={{ opacity: open ? 1 : 0 }}
                      />
                    )}
                </ListItemButton>
              )
            ))}
          </List>
        </Drawer>
      )}
    </Box>
  );
};

HeaderSidebar.propTypes = {
  childProps: PropTypes.any,
};

export default HeaderSidebar;
export { DrawerHeader };
