import { alpha, InputBase } from '@mui/material';
import { makeStyles } from '@mui/styles';
import SearchIcon from '@mui/icons-material/Search';
import React, { useState } from 'react';
import { useHistory } from 'react-router';

const useStyles = makeStyles((theme) => ({
  search: {
    position: 'relative',
    borderRadius: theme.shape?.borderRadius,
    backgroundColor: theme.palette && alpha(theme.palette?.common.white, 0.15),
    '&:hover': {
      backgroundColor: theme.palette && alpha(theme.palette?.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
  },
  searchIcon: {
    paddingRight: '1rem',
    height: '100%',
    position: 'absolute',
    right: 0,
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: '0.5rem',
    // vertical padding + font size from searchIcon
    transition: theme.transitions?.create('width'),
    width: '100%',
    [theme.breakpoints?.up('md')]: {
      width: '20ch',
    },
  },
}));

const SearchInput = () => {
  const classes = useStyles();
  const history = useHistory();
  const [search, setSearch] = useState('');

  const handleChange = (e) => {
    setSearch(e.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') history.push(`/search?search=${search}`);
  };

  return (
    <div className={classes.search}>
      <div className={classes.searchIcon}>
        <SearchIcon />
      </div>
      <InputBase
        placeholder="Search…"
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        inputProps={{ 'aria-label': 'search' }}
        value={search}
        onChange={(e) => handleChange(e)}
        onKeyDown={handleKeyDown}
      />
    </div>
  );
};

export default SearchInput;
