import { observable, decorate } from 'mobx';
import config from '../config';
import RequestService from '../services/RequestService';
import BaseStore from './BaseStore';

class CustomerStore extends BaseStore {
  constructor() {
    super()
    this.buildFromSession();
  }

  customers = null;
  customersPreLoaded = false;

  getCustomers = () => {
    !this.customersPreLoaded && RequestService.get(`${config.backendUrl}/customers`, (response) => {
      response.data.sort((a,b) => { return a.id - b.id })
      this.customers = response.data
      this.customersPreLoaded = true
      this.writeToSession()
    })
  }

  addCustomer = () => {
    this.addRecord('customers', {customer: {firstName: 'placeholder'}}, this.customers)
  }

  createCustomer = (values, callback) => {
    this.addRecord('customers', {
      customer: values
    }, this.customers, callback)
  }

  updateCustomer = (customer) => {
    const updatePayload = {
      customer: {
        [customer.field]: customer.value
      }
    }
    RequestService.post(`${config.backendUrl}/customers/${customer.id}`, updatePayload, (response) => {
      let updatedCustomers = this.customers;
      updatedCustomers.splice(updatedCustomers.findIndex((c) => c.id === customer.id), 1, response.data)
      this.writeToSession()
    })
  }

  deleteCustomer = (id) => {
    RequestService.delete(`${config.backendUrl}/customers/${id}`, () => {
      let updatedCustomers = this.customers
      updatedCustomers.splice(updatedCustomers.findIndex((customer) => customer.id === id), 1)
      this.customers = updatedCustomers
      this.writeToSession()
    })
  }

  writeToSession = () => {
    const json = JSON.stringify(this);
    sessionStorage.setItem('CustomerStore', json);
  }
  
  buildFromSession = () => {
    const json = JSON.parse(sessionStorage.getItem('CustomerStore'))
    if (json) {
      this.customers = json.customers
      this.customersPreLoaded = json.customersPreLoaded
    }
  }
}

decorate(CustomerStore, {
  customers: observable,
  customersPreLoaded: observable
});

export default CustomerStore;