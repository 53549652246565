import * as React from 'react';
import Card from '@mui/material/Card';
import { Box, CardActionArea } from '@mui/material';

const WushButtonCard = ({
  onClick, children, minHeight, disabled, active,
}) => (
  <Card
    sx={{
      maxWidth: '100%',
      minHeight,
      border: active ? '2px solid #0c6b74' : 'none',
      background: active || disabled ? 'lightgrey' : '',
    }}
    onClick={() => { if (!disabled) onClick(); }}
  >
    <CardActionArea sx={{ height: '100%' }}>
      <Box px={3} py={2}>
        {children}
      </Box>
    </CardActionArea>
  </Card>
);

export default WushButtonCard;
