import React from 'react';
import { Typography } from '@mui/material';
// import PropTypes from 'prop-types';

const ReservationItems = () => <Typography variant="h1">Reservation Items</Typography>;

// ReservationItems.propTypes = {
//   reservation: PropTypes.object,
// };

export default ReservationItems;
