import {
  Box, Typography, CircularProgress, Button, TextField,
} from '@mui/material';
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react';

import { useHistory, useRouteMatch } from 'react-router';
import WushCard from '../../../components/WushCard';
import WushModal from '../../../components/WushModal';
import config from '../../../config';
import RequestService from '../../../services/RequestService';

const ViewPayment = ({ alertStore }) => {
  const [payment, setPayment] = useState(null);
  const [amount, setAmount] = useState(null);
  const [showRefundModal, setShowRefundModal] = useState(false);
  const [refunding, setRefunding] = useState(false);
  const match = useRouteMatch();
  const { paymentId } = match.params;

  const history = useHistory();

  const fetchPayment = () => {
    RequestService.get(`${config.backendUrl}/payments/${paymentId}`, (response) => {
      setPayment(response.data);
      setAmount(response.data.amount);
    });
  };

  const refundPayment = () => {
    setRefunding(true);
    RequestService.post(`${config.backendUrl}/refunds`, { refund: { paymentId, amount } }, () => {
      fetchPayment();
      setRefunding(false);
      setShowRefundModal(false);
    }, () => {
      setShowRefundModal(false);
      setRefunding(false);
      alertStore.setAlertMessage('Refund failed!', 'error');
    });
  };

  useEffect(() => {
    fetchPayment();
  }, []);

  if (!payment) return <CircularProgress />;

  return (
    <Box pt={2}>
      <Button onClick={() => history.goBack()}>Back</Button>
      <WushCard>
        <Box>
          <Box>
            <Typography variant="h4">
              Payment ID:
              {paymentId}
            </Typography>
          </Box>
          <Box>
            <Typography variant="h6">
              Date:
              {payment.paymentDate}
            </Typography>
          </Box>
          <Box>
            <Typography variant="h6">
              Amount: $
              {payment.amount}
            </Typography>
          </Box>
          <Box>
            <Typography variant="h6">
              Paid for:
              {' '}
              {' '}
              {payment.eventId ? `Event - ${payment.eventName}` : `Event Series - ${payment.eventSeriesName}`}
            </Typography>
          </Box>
          <Box>
            <Typography variant="h6">
              Status:
              {payment.paymentStatus}
            </Typography>
          </Box>
          <Box>
            <Typography variant="h6">
              Refunded Amount: $
              {payment.refundedAmount}
            </Typography>
          </Box>
          <Box>
            <Typography variant="h6">
              Last Refund At:
              {format(new Date(payment.refundedAt), 'LLLL dd, yyyy HH:mm:ss')}
            </Typography>
          </Box>
          <Box>
            <Button onClick={() => setShowRefundModal(true)} variant="contained" color="secondary">
              Refund
            </Button>
          </Box>
        </Box>
      </WushCard>
      <WushModal
        open={showRefundModal}
        onClose={() => setShowRefundModal(false)}
      >
        <Box>
          <Box mb={2}>
            <Typography variant="h6">Are you sure you want to initiate a refund?</Typography>
            <Typography variant="body1">You will NOT be able to undo this action</Typography>
          </Box>
          <Box>
            <TextField
              id="refundAmount"
              label="Refund Amount"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />
          </Box>
          <Box mt={3}>
            <Button onClick={refundPayment} variant="contained" color="secondary">
              {refunding ? <CircularProgress /> : 'Refund'}
            </Button>
            <Button onClick={() => setShowRefundModal(false)}>
              Cancel
            </Button>
          </Box>
        </Box>
      </WushModal>
    </Box>
  );
};

export default ViewPayment;
