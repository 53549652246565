import {
  Box, Button, Divider, FormControl, FormControlLabel, FormLabel,
  Grid, Radio, RadioGroup, Typography,
} from '@mui/material';
import { Form, Formik } from 'formik';
import React from 'react';
import WushCard from '../../../components/WushCard';
import config from '../../../config';
import RequestService from '../../../services/RequestService';
import CashPayment from './CashPayment';
import CheckPayment from './CheckPayment';
import OnlinePayment from './OnlinePayment';
import CreditPayment from './CreditPayment';

const paymentMethods = (paymentAmount, invoiceId) => [
  {
    label: 'Cash',
    value: 'cash',
    component: <CashPayment paymentAmount={paymentAmount} />,
  },
  {
    label: 'Credit Card',
    value: 'credit',
    component: <CreditPayment invoiceId={invoiceId} />,
  },
  {
    label: 'Check',
    value: 'check',
    component: <CheckPayment />,
  },
  {
    label: 'Paid Online',
    value: 'online',
    component: <OnlinePayment />,
  },
  {
    label: 'Pay Later',
    value: 'later',
    component: <Box pt="1rem"><Typography>Customer will pay later</Typography></Box>,
  },
];

const Payment = ({ setInvoice, invoice }) => {
  const [paymentMethod, setPaymentMethod] = React.useState(invoice.payment?.paymentMethod);
  const [paid] = React.useState(!!invoice.payment);
  const submitPayment = (values) => {
    RequestService.post(`${config.backendUrl}/payments`, { payment: { paymentMethod, ...values } }, (response) => {
      setInvoice(response.data);
    });
  };

  const renderPaymentInfo = () => {
    if (!paymentMethod) return <Typography>Please select payment method</Typography>;
    return paymentMethods(invoice.totalCost, invoice.id).filter(
      (method) => method.value === paymentMethod,
    )[0].component;
  };

  const renderPaidFor = (payment) => (
    <Typography variant="body1">{`Paid $${payment.amount} by ${payment.paymentMethod} on ${payment.paymentDate}.`}</Typography>
  );

  const handleCreditSuccess = (result) => {
    submitPayment({
      reservationId: invoice.id,
      paymentMethod: 'credit',
      onlinePaymentId: result.paymentIntent.id,
    });
  };

  const renderPaymentForm = () => {
    if (paymentMethod !== 'credit' || paid) {
      return (
        <Formik
          initialValues={{
            reservationId: invoice.id,
            checkNumber: null,
            payerName: null,
            onlinePaymentId: null,
          }}
          onSubmit={submitPayment}
        >
          {
            ({ handleSubmit }) => (
              <Form>
                <Divider />
                <Box>
                  {invoice.payment
                    ? renderPaidFor(invoice.payment)
                    : renderPaymentInfo()}
                </Box>
                {!paid && (
                  <Box px="2rem">
                    <Button onClick={handleSubmit} variant="contained" fullWidth disabled={!paymentMethod}>
                      Pay (
                      {invoice.totalCost}
                      )
                    </Button>
                  </Box>
                )}
              </Form>
            )
          }
        </Formik>
      );
    }

    return (
      <CreditPayment invoiceId={invoice.id} handleSuccess={handleCreditSuccess} />
    );
  };

  return (
    <Box className="hide-print">
      <WushCard>
        <Box>
          <Typography variant="h6">
            Payment Method
          </Typography>
        </Box>
        <Divider />
        <FormControl fullWidth>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            value={paymentMethod}
            name="paymentMethod"
            onChange={(e) => setPaymentMethod(e.target.value)}
          >
            {
              paymentMethods().map((method) => (
                <Grid item md={6}>
                  <FormLabel>
                    <FormControlLabel disabled={paid} control={<Radio />} type="radio" name="paymentMethod" value={method.value} label={method.label} />
                  </FormLabel>
                </Grid>
              ))
            }
          </RadioGroup>
        </FormControl>
        {renderPaymentForm()}
      </WushCard>
    </Box>
  );
};

export default Payment;
