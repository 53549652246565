import {
  Box, Button, Typography, Grid,
} from '@mui/material';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import GroupsIcon from '@mui/icons-material/Groups';
import WushButtonCard from '../../../components/WushButtonCard';
import config from '../../../config';
import RequestService from '../../../services/RequestService';

const EventSeries = () => {
  const [eventSeries, setEventSeries] = useState(null);
  const history = useHistory();

  const fetchEventSeries = () => {
    RequestService.get(`${config.backendUrl}/event_series`, (response) => {
      setEventSeries(response.data);
    });
  };

  if (!eventSeries) fetchEventSeries();

  return (
    <Box py={2}>
      <Typography variant="h3">Event Series</Typography>
      <Box mb={3}>
        <Button variant="contained" onClick={() => history.push('event-series/new')}>Create Event Series</Button>
      </Box>
      {eventSeries && eventSeries.map((eventSerie) => eventSerie.name && (
        <Box mb={2}>
          <WushButtonCard
            onClick={() => history.push(`/event-management/event-series/${eventSerie.id}`)}
          >
            <Grid container>
              <Grid item md={4}>
                {eventSerie.name}
              </Grid>
              <Grid item md={4}>
                <GroupsIcon />
                {' '}
                {eventSerie.registrationsCount}
                {' '}
                /
                {' '}
                {eventSerie.capacity}
              </Grid>
            </Grid>
          </WushButtonCard>
        </Box>
      ))}
    </Box>
  );
};

export default EventSeries;
