import {
  Box, Button, Grid, TextField, Autocomplete,
} from '@mui/material';
import { Field, Form, Formik } from 'formik';
import React from 'react';
import PropTypes from 'prop-types';

const ItemTransferForm = ({ properties, storages, makeTransfer }) => (
  <Formik
    initialValues={{
      startLocationId: '',
      endLocationId: '',
      quantity: 0,
    }}
    onSubmit={makeTransfer}
  >
    {({
      values, handleChange, handleSubmit, setFieldValue,
    }) => (
      <Form>
        <Grid row>
          <Autocomplete
            options={storages.concat(properties).filter(
              (property) => property.id !== values.endLocationId,
            )}
            renderInput={(params) => <TextField {...params} label="Start Location" />}
            getOptionLabel={(option) => option.name}
            onChange={(_event, property) => setFieldValue('startLocationId', property.id)}
            groupBy={(option) => option.locationType}
          />
        </Grid>
        <Grid row>
          <Box mb={2}>
            <Autocomplete
              options={storages.concat(properties).filter(
                (property) => property.id !== values.startLocationId,
              )}
              renderInput={(params) => <TextField {...params} label="End Location" />}
              getOptionLabel={(option) => option.name}
              onChange={(_event, property) => setFieldValue('endLocationId', property.id)}
              groupBy={(option) => option.locationType}
            />
          </Box>
        </Grid>
        <Grid row>
          <Box mb={2}>
            <Field
              name="quantity"
              onChange={handleChange}
              placeholder="Enter quantity"
            />
          </Box>
        </Grid>
        <Grid row>
          <Button onClick={handleSubmit}>
            Make Transfer
          </Button>
        </Grid>
      </Form>
    )}
  </Formik>
);

ItemTransferForm.propTypes = {
  properties: PropTypes.array,
  storages: PropTypes.array,
  makeTransfer: PropTypes.func,
};

export default ItemTransferForm;
