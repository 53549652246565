import React from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {
  Box, FormGroup, FormLabel, Grid, InputAdornment, TextField,
} from '@mui/material';
import { Field } from 'formik';

const WushInput = ({
  label, value, onChange, name, gridCol = 12, inputType = null, options = [], mb = 0,
}) => {
  const renderInput = () => {
    switch (inputType) {
    case 'date':
      return (
        <FormGroup>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label={label}
              value={value}
              onChange={onChange}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </FormGroup>
      );
    case 'datetime':
      return (
        <FormGroup>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              renderInput={(props) => <TextField {...props} />}
              label={label}
              value={value}
              onChange={onChange}
            />
          </LocalizationProvider>
        </FormGroup>
      );
    case 'checkbox':
      return (
        <FormGroup>
          <FormLabel><strong>{label}</strong></FormLabel>
          <Field type="checkbox" name={name} />
        </FormGroup>
      );
    case 'money':
      return (
        <FormGroup>
          <TextField
            type="number"
            label={label}
            value={value}
            onChange={onChange}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
          />
        </FormGroup>
      );
    case 'number':
      return (
        <FormGroup>
          <TextField
            type="number"
            label={label}
            value={value}
            onChange={onChange}
          />
        </FormGroup>
      );
    case 'textarea':
      return (
        <FormGroup>
          <TextField label={label} multiline value={value} onChange={onChange} />
        </FormGroup>
      );
    case 'radioGroup':
      return (
        <FormGroup>
          <Box mb={1}>
            <FormLabel><strong>{label}</strong></FormLabel>
          </Box>
          {options.map((option) => (
            <FormLabel>
              <Field type="radio" name={name} value={option.value} />
              {option.label}
            </FormLabel>
          ))}
        </FormGroup>
      );
    default:
      return (
        <FormGroup>
          <TextField label={label} value={value} onChange={onChange} />
        </FormGroup>
      );
    }
  };
  return (
    <Grid item md={gridCol}>
      <Box p="0.25rem" mb={mb}>
        {renderInput()}
      </Box>
    </Grid>
  );
};

export default WushInput;
