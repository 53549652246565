import {
  Box, Button,
} from '@mui/material';
import React from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
  Elements,
  useStripe,
  useElements,
  PaymentElement,
} from '@stripe/react-stripe-js';
import Loader from '../../../components/loader/Loader';
import RequestService from '../../../services/RequestService';
import config from '../../../config';

const stripePromise = loadStripe('pk_test_JHuNOTBzfEaYENswXTpzkb9w');

const CreditPayment = ({ invoiceId, handleSuccess }) => {
  const [clientSecret, setClientSecret] = React.useState(null);
  const getOptions = () => ({
    clientSecret,
  });

  React.useEffect(() => {
    if (!clientSecret) {
      RequestService.get(`${config.backendUrl}/invoices/${invoiceId}/setup-stripe`, (response) => {
        setClientSecret(response.data.clientSecret);
      });
    }
  });

  if (!clientSecret) return <Loader />;

  const CheckoutForm = () => {
    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async (event) => {
      // We don't want to let default form submission happen here,
      // which would refresh the page.
      event.preventDefault();

      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }

      const result = await stripe.confirmPayment({
        // `Elements` instance that was used to create the Payment Element
        elements,
        confirmParams: {
          return_url: 'https://google.com',
        },
        redirect: 'if_required',
      });

      if (result.error) {
        // Show error to your customer (for example, payment details incomplete)
      } else {
        handleSuccess(result);
      }
    };

    return (
      <form onSubmit={handleSubmit}>
        <Box mb="2rem">
          <PaymentElement />
        </Box>
        <Button type="submit" variant="contained" disabled={!stripe}>Submit</Button>
      </form>
    );
  };

  return (
    <Box>
      <Elements stripe={stripePromise} options={getOptions()}>
        <CheckoutForm />
      </Elements>
    </Box>
  );
};

export default CreditPayment;
