import { Container } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';

import { Route, Switch, useRouteMatch } from 'react-router';
import Dashboard from './containers/dashboard/Dashboard';

const useStyles = makeStyles(() => ({
  superAdminContainer: {
  },
}));

const SuperAdmin = (props) => {
  const match = useRouteMatch();
  const classes = useStyles();

  return (
    <Container className={classes.superAdminContainer}>
      <Switch>
        <Route path={`${match.path}`}>
          <Dashboard {...props} />
        </Route>
      </Switch>
    </Container>
  );
};

export default SuperAdmin;
