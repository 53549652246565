import {
  Typography, LinearProgress, Button, Box,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react';
import { DataGrid } from '@material-ui/data-grid';
import { format } from 'date-fns';
import React from 'react';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  customersTableContainer: {
    height: '500px',
  },
  customersContainer: {
    paddingTop: '2rem',
  },
  buttonContainer: {
    marginBottom: '1rem',
  },
  customersCombo: {
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingTop: '8px',
  },
}));

const Customers = ({ customerStore }) => {
  const classes = useStyles();
  customerStore.getCustomers();

  if (!customerStore.customersPreLoaded) {
    return (<LinearProgress />);
  }

  const columns = [
    {
      field: 'firstName',
      headerName: 'First Name',
      width: 150,
      editable: true,
    },
    {
      field: 'lastName',
      headerName: 'Last Name',
      width: 150,
      editable: true,
    },
    {
      field: 'address1',
      headerName: 'Address 1',
      width: 150,
      editable: true,
    },
    {
      field: 'address2',
      headerName: 'Address 2',
      width: 150,
      editable: true,
    },
    {
      field: 'city',
      headerName: 'City',
      width: 150,
      editable: true,
    },
    {
      field: 'state',
      headerName: 'State',
      width: 150,
      editable: true,
    },
    {
      field: 'zip',
      headerName: 'Zip Code',
      width: 150,
      editable: true,
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 150,
      editable: true,
    },
    {
      field: 'phone',
      headerName: 'Phone',
      width: 150,
      editable: true,
    },
    {
      field: 'taxExempt',
      headerName: 'Tax Exempt?',
      width: 150,
      editable: true,
    },
    {
      field: 'Actions',
      headerName: '',
      width: 100,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="secondary"
          onClick={() => customerStore.deleteCustomer(params.id)}
        >
          Delete
        </Button>
      ),
      editable: false,
      sortable: false,
    },
  ];

  const customerRows = customerStore.customers.map((customer) => ({
    id: customer.id,
    firstName: customer.firstName,
    lastName: customer.lastName,
    address1: customer.address1,
    address2: customer.address2,
    city: customer.city,
    state: customer.state,
    zip: customer.zip,
    email: customer.email,
    phone: customer.phone,
    taxExempt: customer.taxExempt,
    createdAt: customer.createdAt && format(new Date(customer.createdAt), 'PP'),
  }));

  return (
    <Box py={2}>
      <Typography variant="h3">Customers</Typography>
      <div className={classes.buttonContainer}>
        <Button
          variant="contained"
          color="primary"
          onClick={customerStore.addCustomer}
        >
          Add Customer
        </Button>
      </div>
      <div className={classes.customersTableContainer}>
        <DataGrid
          rows={customerRows}
          columns={columns}
          pageSize={10}
          disableSelectionOnClick
          onCellEditCommit={customerStore.updateCustomer}
        />
      </div>
    </Box>
  );
};

Customers.propTypes = {
  customerStore: PropTypes.any,
};

export default observer(Customers);
