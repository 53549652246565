import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router';
import { Container } from '@mui/material';
import Reports from './Reports';
import EventReports from './containers/EventReports';

const ReportsManagement = (props) => {
  const match = useRouteMatch();
  return (
    <Container>
      <Switch>
        <Route path={`${match.path}/events`}>
          <EventReports {...props} />
        </Route>
        <Route path={`${match.path}`}>
          <Reports {...props} />
        </Route>
      </Switch>
    </Container>
  );
};

export default ReportsManagement;
