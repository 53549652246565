import {
  Box, FormLabel,
} from '@mui/material';
import { Field } from 'formik';
import React from 'react';

const CheckPayment = () => (
  <Box>
    <Box p="0.5rem">
      <Box>
        <FormLabel>Name on Check</FormLabel>
      </Box>
      <Box>
        <Field name="payerName" />
      </Box>
    </Box>
    <Box p="0.5rem">
      <Box>
        <FormLabel>Check Number</FormLabel>
      </Box>
      <Box>
        <Field name="checkNumber" />
      </Box>
    </Box>
  </Box>
);

export default CheckPayment;
