import React from 'react';
import { PAGES as InventoryPages } from "./containers/inventory/constants";
import { PAGES as AdminPages } from "./containers/admin/constants";
import { PAGES as SuperAdminPages } from "./containers/superAdmin/constants";
import { PAGES as ReservationPages } from "./containers/reservations/constants";
import { PAGES as PropertyPages } from "./containers/properties/constants";
import { PAGES as ScannerPages } from "./containers/scanner/constants";
import { PAGES as EventPages } from './containers/events/constants';
import { PAGES as ReportPages } from './containers/reports/constants';
import { PAGES as FulfillmentPages } from './containers/fulfillment/constants';
import LibraryBooks from "@mui/icons-material/LibraryBooks";
import InventoryIcon from '@mui/icons-material/Inventory';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import HomeIcon from '@mui/icons-material/Home';
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import SuperscriptIcon from '@mui/icons-material/Superscript';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';

export const TEST_ORGANIZATION_ID = 199;

export const ACCOUNT_TYPES = {
  SUPER_ADMIN: "super_admin",
  ADMIN: "admin",
  SHARED: "shared"
}

export const APPS = {
  MISSION_CONTROL: {
    title: "Home",
    baseRoute: "home",
    color: "#00b4d2",
    pages: {
      NEW_RESERVATION: {
        title: "New Reservation",
        directLink: "reservation-management/new-reservation",
        baseRoute: "reservation-management"
      }
    },
    icon: <HomeIcon />
  },
  INVENTORY: {
    title: "Inventory",
    baseRoute: "inventory-management",
    baseSubRoute: "dashboard",
    color: "#00b4d2",
    pages: InventoryPages,
    icon: <InventoryIcon />
  },
  RESERVATIONS: {
    title: "Reservations",
    baseRoute: "reservation-management",
    baseSubRoute: "new-reservation",
    color: "#00b4d2",
    pages: ReservationPages,
    icon: <BookmarkAddIcon />
  },
  FULFILLMENT: {
    title: "Fulfillment",
    baseRoute: "fulfillment",
    baseSubRoute: "deliveries",
    color: "#00b4d2",
    pages: FulfillmentPages,
    icon: <LocalShippingIcon />
  },
  EVENTS: {
    title: "Events",
    baseRoute: "event-management",
    baseSubRoute: "events",
    color: "#00b4d2",
    pages: EventPages,
    icon: <CalendarMonthIcon />
  },
  REPORTS: {
    title: "Reports",
    baseRoute: "reports",
    color: "#ee855b",
    pages: ReportPages,
    icon: <LibraryBooks />
  },
  PROPERTIES: {
    title: "Properties",
    baseRoute: "property-management",
    baseSubRoute: "properties",
    color: "#00b4d2",
    pages: PropertyPages,
    icon: <MapsHomeWorkIcon />
  },
  SCANNER: {
    title: "Scanner",
    baseRoute: "scanner",
    color: "#00b4d2",
    pages: ScannerPages,
    icon: <QrCodeScannerIcon />
  },
  ADMIN: {
    title: "Admin",
    baseRoute: "admin",
    baseSubRoute: "dashboard",
    color: "#f9c538",
    pages: AdminPages,
    icon: <AdminPanelSettingsIcon />
  },
  SUPER_ADMIN: {
    title: "Super Admin",
    baseRoute: "super-admin",
    color: "#f9c538",
    pages: SuperAdminPages,
    icon: <SuperscriptIcon />,
    auth: [ACCOUNT_TYPES.SUPER_ADMIN]
  }
}
