import {
  Autocomplete,
  Box, TextField, Typography, CircularProgress, Grid, Button,
} from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';
import { Formik } from 'formik';
import Fuse from 'fuse.js';
import { useHistory } from 'react-router';
import WushCalendar from '../components/WushCalendar';
import RequestService from '../../../services/RequestService';
import config from '../../../config';
import { mapCalendarEventToWush, mapWushEventToCalendar } from '../services';
import EventForm from '../components/EventForm';
import WushModal from '../../../components/WushModal';

const Events = ({ eventCategoryStore, employeeStore }) => {
  const [myEvents, setEvents] = useState(null);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const [newEvent, setNewEvent] = useState(null);
  const [selectedEventCategory, setSelectedEventCategory] = useState(null);
  const [eventSeries, setEventSeries] = useState(null);
  const [selectedEventSeries, setSelectedEventSeries] = useState(null);
  const [search, setSearch] = useState('');

  const history = useHistory();

  const fetchEventSeries = () => {
    RequestService.get(`${config.backendUrl}/event_series`, (response) => {
      setEventSeries(response.data);
    });
  };

  if (!eventSeries) fetchEventSeries();

  eventCategoryStore.getEventCategories();

  const fetchEvents = () => {
    RequestService.get(`${config.backendUrl}/events`, (response) => {
      const mappedEvents = response.data.map(mapWushEventToCalendar);
      return setEvents(mappedEvents);
    });
  };

  if (!myEvents) {
    fetchEvents();
  }

  const handleSelectSlot = useCallback(
    (event) => {
      setNewEvent(mapCalendarEventToWush(event));
      setShowCreateModal(true);
    },
    [setEvents],
  );

  const handleSelectEvent = useCallback(
    (event) => {
      setNewEvent(mapCalendarEventToWush(event));
      setShowViewModal(true);
    },
  );

  const moveEvent = useCallback(
    ({ event, start, end }) => {
      const updateEvent = {
        startDatetime: start,
        endDatetime: end,
      };
      setEvents((prev) => {
        const existing = prev.find((ev) => ev.id === event.id) ?? {};
        const filtered = prev.filter((ev) => ev.id !== event.id);
        return [...filtered, { ...existing, start, end }];
      });
      RequestService.patch(`${config.backendUrl}/events/${event.id}`, { event: updateEvent });
    },
    [setEvents],
  );

  const resizeEvent = useCallback(
    ({ event, start, end }) => {
      const updateEvent = {
        startDatetime: start,
        endDatetime: end,
      };
      setEvents((prev) => {
        const existing = prev.find((ev) => ev.id === event.id) ?? {};
        const filtered = prev.filter((ev) => ev.id !== event.id);
        return [...filtered, { ...existing, start, end }];
      });
      RequestService.patch(`${config.backendUrl}/events/${event.id}`, { event: updateEvent });
    },
    [setEvents],
  );

  const handleCreate = (values) => {
    RequestService.post(`${config.backendUrl}/events`, {
      event: {
        eventCategoryId: selectedEventCategory?.id,
        eventSeriesId: selectedEventSeries?.id,
        ...values,
      },
    }, (response) => {
      if (Array.isArray(response.data)) {
        fetchEvents();
      } else {
        setEvents((prev) => [...prev, mapWushEventToCalendar(response.data)]);
      }
      setShowCreateModal(false);
    });
  };

  const handleUpdate = (values) => {
    RequestService.patch(`${config.backendUrl}/events/${values.id}`, { event: values }, (response) => {
      setEvents((prev) => {
        const updatedEvents = prev;
        updatedEvents.splice(
          updatedEvents.findIndex((ev) => (ev.id === values.id)),
          1,
          mapWushEventToCalendar(response.data),
        );
        return updatedEvents;
      });
      setShowCreateModal(false);
    });
  };

  const handleDelete = (eventId) => {
    RequestService.delete(`${config.backendUrl}/events/${eventId}`, () => {
      setEvents((prev) => {
        const updatedEvents = prev;
        updatedEvents.splice(
          updatedEvents.findIndex((ev) => (ev.id === eventId)),
          1,
        );
        return updatedEvents;
      });
      setShowCreateModal(false);
    });
  };

  const handleDeleteRepetition = (eventId) => {
    RequestService.delete(`${config.backendUrl}/events/${eventId}/destroy_repetition`, () => {
      fetchEvents();
      setShowCreateModal(false);
    });
  };

  const { scrollToTime } = useMemo(
    () => ({
      scrollToTime: new Date(1970, 1, 1, 4),
    }),
    [],
  );

  const handleUpdateRepeats = (values) => {
    RequestService.patch(`${config.backendUrl}/events/${values.id}/update_repetition`, { event: values }, () => {
      fetchEvents();
      setShowCreateModal(false);
    });
  };

  const fuseOptions = { keys: ['title', 'desc'] };
  const fuse = new Fuse(myEvents || [], fuseOptions);

  const filterEvents = () => {
    if (search) {
      const searchedEvents = fuse.search(search).map((item) => item.item);
      return (selectedEventCategory || selectedEventSeries)
      // eslint-disable-next-line
      ? searchedEvents.filter((event) => event.eventCategoryId === selectedEventCategory?.id || event.eventSeriesId === selectedEventSeries?.id)
        : searchedEvents;
    }

    return (selectedEventCategory || selectedEventSeries)
    // eslint-disable-next-line
        ? myEvents.filter((event) => event.eventCategoryId === selectedEventCategory?.id || event.eventSeriesId === selectedEventSeries?.id)
      : myEvents;
  };

  return (
    <Box py={2}>
      <Box mb={5}>
        <Typography variant="h3">Events Schedule</Typography>
      </Box>
      <Box mb={5}>
        <TextField
          fullWidth
          label="Search"
          variant="outlined"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
      </Box>
      <Grid container>
        <Grid item md={4}>
          <Box pr={4}>
            <Autocomplete
              id="combo-box-demo"
              options={eventCategoryStore.eventCategories}
              getOptionLabel={(option) => (option.name)}
              className={null}
              renderInput={(params) => <TextField {...params} label="Select Event Category" variant="outlined" />}
              value={selectedEventCategory}
              onChange={(_event, value) => {
                setSelectedEventCategory(value);
              }}
            />
          </Box>
        </Grid>
        <Grid item md={4}>
          <Box pl={4} mb={5}>
            <Autocomplete
              id="combo-box-demo"
              options={eventSeries}
              getOptionLabel={(option) => (option.name)}
              className={null}
              renderInput={(params) => <TextField {...params} label="Select Event Series" variant="outlined" />}
              value={selectedEventSeries}
              onChange={(_event, value) => {
                setSelectedEventSeries(value);
              }}
            />
          </Box>
        </Grid>
        <Grid item md={4} />
      </Grid>
      <Box mt={5}>
        {myEvents ? (
          <WushCalendar
            events={filterEvents()}
            handleSelectSlot={handleSelectSlot}
            scrollToTime={scrollToTime}
            onSelectEvent={handleSelectEvent}
            onEventDrop={moveEvent}
            onEventResize={resizeEvent}
            popup
            resizable
          />
        ) : <CircularProgress />}
      </Box>
      <WushModal
        open={showCreateModal}
      >
        <Box p={1}>
          <Formik
            initialValues={{
              repetition: null,
              visibleDate: null,
              capacity: 0,
              ...selectedEventSeries,
              ...newEvent,
              repeatEnd: selectedEventSeries && new Date(selectedEventSeries.endDatetime),
              id: newEvent?.id,
              startDatetime: newEvent?.startDatetime,
              endDatetime: newEvent?.endDatetime,
            }}
            onSubmit={(values) => (newEvent?.id ? handleUpdate(values) : handleCreate(values))}
          >
            {(formik) => (
              <EventForm
                formik={formik}
                values={formik.values}
                employeeStore={employeeStore}
                selectedEvent={newEvent}
                handleSubmit={formik.handleSubmit}
                onCancel={() => setShowCreateModal(false)}
                handleDelete={() => handleDelete(newEvent.id)}
                handleDeleteRepetition={() => handleDeleteRepetition(newEvent.id)}
                eventInSeries={selectedEventSeries?.name}
                eventCategory={selectedEventCategory?.name}
                defaultEndRepeat={selectedEventSeries?.endDatetime}
                handleUpdateRepeats={() => handleUpdateRepeats(formik.values)}
              />
            )}
          </Formik>
        </Box>
      </WushModal>
      <WushModal
        open={showViewModal}
        onClose={() => setShowViewModal(false)}
      >
        {newEvent && (
          <Box>
            <Box mb={3}>
              <Typography variant="h5">{newEvent.name}</Typography>
              <Typography variant="body1">
                {newEvent.registrationsCount}
                {' '}
                currently enrolled
              </Typography>
            </Box>
            <Box mb={2}>
              <Button onClick={() => history.push(`/event-management/events/${newEvent.id}`)} variant="contained">View Event</Button>
            </Box>
            {newEvent.eventSeriesId && (
              <Box>
                <Button onClick={() => history.push(`/event-management/event-series/${newEvent.eventSeriesId}`)} variant="contained">View Event Series</Button>
              </Box>
            )}
          </Box>
        )}
      </WushModal>
    </Box>
  );
};

export default Events;
