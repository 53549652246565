import React from 'react';
import { Container } from '@mui/material';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { APPS } from '../../constants';
import ViewItem from './containers/items/ViewItem';
import ViewSubscription from './containers/subscriptions/ViewSubscription';

const Inventory = (props) => {
  const match = useRouteMatch();
  return (
    <Container>
      <Switch>
        <Route key="item" path={`${match.path}/items/:itemId`}>
          <ViewItem {...props} />
        </Route>
        <Route key="subscription" path={`${match.path}/subscriptions/:subscriptionId`}>
          <ViewSubscription {...props} />
        </Route>
        {Object.values(APPS.INVENTORY.pages).map((page) => (
          <Route key={page.title} path={`${match.path}/${page.baseRoute}`}>
            <page.component {...props} />
          </Route>
        ))}
      </Switch>
    </Container>
  );
};

export default Inventory;
