import React from 'react';
import { Box, Typography } from '@mui/material';
import WushCard from '../../../components/WushCard';
import PercentCapacity from './components/PercentCapacity';

const EventReports = () => (
  <Box py={2}>
    <Typography color="initial" variant="h3">Event Reports</Typography>
    <Box>
      <WushCard>
        <PercentCapacity />
      </WushCard>
    </Box>
  </Box>
);

export default EventReports;
