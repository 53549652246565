import React, { useState } from 'react';
import { Popover } from '@mui/material';

const WushPopover = ({ anchor, children, ...props }) => {
  const [anchorEl, setAnchorEl] = useState();
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {/* eslint-disable-next-line */}
      <div onClick={handleClick}>
        {anchor}
      </div>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        {...props}
      >
        {children}
      </Popover>
    </>
  );
};

export default WushPopover;
