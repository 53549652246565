import {
  Box, Button, FormGroup, Typography,
} from '@mui/material';
import { Form, Formik } from 'formik';
import React from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@gabmarini/ckeditor5-build-classic-base64upload';
import { useHistory } from 'react-router';
import MuiFormikTextField from '../../../../components/muiFormik/MuiFormikTextField';
import createWaiver from '../../../../services/waivers/createWaiver';

const NewWaiver = () => {
  const history = useHistory();

  return (
    <Box>
      <Box mb={2}>
        <Typography variant="h3">
          New Waiver
        </Typography>
      </Box>
      <Box>
        <Formik
          initialValues={{
            name: '',
            description: '',
            content: '',
          }}
          onSubmit={(values) => {
            createWaiver(values, () => {
              history.push('waivers');
            });
          }}
        >
          {
            (formik) => (
              <Form>
                <FormGroup>
                  <Box mb={2}>
                    <MuiFormikTextField
                      name="name"
                      formik={formik}
                      value={formik.values.name}
                      label="Name"
                      fullWidth
                    />
                  </Box>
                  <Box mb={2}>
                    <MuiFormikTextField
                      name="description"
                      formik={formik}
                      value={formik.values.description}
                      label="Description"
                      fullWidth
                    />
                  </Box>
                  <Box mb={2}>
                    <CKEditor
                      editor={ClassicEditor}
                      data={formik.values.content}
                      onChange={(event, editor) => {
                        formik.setFieldValue('content', editor.getData());
                      }}
                    />
                  </Box>
                </FormGroup>
                <Box pb={2}>
                  <Button color="primary" variant="contained" onClick={formik.handleSubmit}>
                    Save
                  </Button>
                </Box>
              </Form>
            )
          }
        </Formik>
      </Box>
    </Box>
  );
};

export default NewWaiver;
