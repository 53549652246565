import {
  Box, CardActions, CardContent, CardHeader, Divider, IconButton, Typography,
} from '@mui/material';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import React from 'react';
import { useHistory } from 'react-router';
import BaseCard from './BaseCard';

const WaiverCard = ({ waiver }) => {
  const history = useHistory();

  return (
    <BaseCard>
      <CardHeader
        title={waiver.name}
      />
      <CardContent>
        <Box my={1}>
          <Typography variant="body1">
            {waiver.description}
          </Typography>
        </Box>
      </CardContent>
      <Divider />
      <CardActions className="MuiCardFooter">
        <Box py={2}>
          <IconButton size="small" onClick={() => history.push(`waivers/${waiver.id}`)}>
            <OpenInFullIcon className="expand-styles" />
          </IconButton>
        </Box>
      </CardActions>
    </BaseCard>
  );
};

export default WaiverCard;
