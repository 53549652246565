import React from 'react';
import { Form } from 'react-bootstrap';
import {
  Box, TextField, Typography, Button,
} from '@mui/material';
import { observer } from 'mobx-react';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { useHistory } from 'react-router';
import Loader from '../../components/loader/Loader';
import WushCard from '../../components/WushCard';

const Login = ({ baseStore, authStore, alertStore }) => {
  const history = useHistory();
  baseStore.getHealthCheck();
  if (authStore.loggedIn) return <Redirect to="/home" />;
  if (baseStore.healthLoading) return <Loader message="WUSH is waking up..." />;
  if (authStore.loginLoading) return <Loader />;
  return (
    <Box py={10} textAlign="center" width="100%">
      <Box width="50%" mr="auto" ml="auto">
        <WushCard>
          <Box p={5}>
            <Box mb={2} textAlign="center">
              <Typography variant="h3">Login</Typography>
            </Box>
            <Formik
              initialValues={{ email: '', password: '' }}
              onSubmit={(values) => authStore.login(
                values.email,
                values.password,
                () => { history.push('/home'); },
                () => alertStore.setAlertMessage('Invalid user name or password!', 'error'),
              )}
            >
              {
                ({ values, handleChange, handleSubmit }) => (
                  <Form>
                    <Box>
                      <Box mb={2}>
                        <TextField
                          fullWidth
                          required
                          id="email"
                          label="Email"
                          type="email"
                          autoComplete="current-email"
                          variant="outlined"
                          onChange={handleChange}
                          value={values.email}
                        />
                        <TextField
                          fullWidth
                          required
                          id="password"
                          label="Password"
                          type="password"
                          margin="normal"
                          autoComplete="current-password"
                          variant="outlined"
                          onChange={handleChange}
                          value={values.password}
                          onKeyUp={(e) => { if (e.key === 'Enter') handleSubmit(); }}
                        />
                      </Box>
                      <Box mb={2}>
                        <Button variant="contained" fullWidth onClick={handleSubmit}>
                          Login
                        </Button>
                      </Box>
                    </Box>
                  </Form>
                )
              }
            </Formik>
          </Box>
        </WushCard>
      </Box>
    </Box>
  );
};

Login.propTypes = {
  authStore: PropTypes.object,
  baseStore: PropTypes.object,
};

export default observer(Login);
