import React, { useState, useEffect } from 'react';
import {
  Box, Typography, CircularProgress, Button, Autocomplete, TextField, Slider,
} from '@mui/material';

import RequestService from '../../../../services/RequestService';
import config from '../../../../config';
import PercentCapacityChart from './PercentCapacityChart';

const PercentCapacity = () => {
  const [report, setReport] = useState(null);
  const [reportLoading, setReportLoading] = useState(false);
  const [eventSeries, setEventSeries] = useState(null);
  const [selectedEventSeries, setSelectedEventSeries] = useState(null);
  const [maxEnrollment, setMaxEnrollment] = useState(100);

  const fetchEventSeries = () => {
    RequestService.get(`${config.backendUrl}/event_series`, (response) => {
      setEventSeries(response.data);
    });
  };

  const fetchReport = () => {
    setReportLoading(true);
    const queryString = selectedEventSeries ? `?eventSeriesId=${selectedEventSeries ? selectedEventSeries.id : null}` : '';
    RequestService.get(`${config.backendUrl}/reports/event_percent_capacity${queryString}`, (response) => {
      setReport(response.data);
      setReportLoading(false);
    });
  };

  useEffect(() => {
    fetchEventSeries();
  }, []);

  const filterData = () => report.filter((event) => event.percentEnrollment < maxEnrollment);

  return (
    <Box>
      <Typography variant="h5">Percent Capacities</Typography>
      <Box mb={2}>
        {eventSeries && (
          <Autocomplete
            id="combo-box-demo"
            options={eventSeries}
            getOptionLabel={(option) => (option.name)}
            className={null}
            renderInput={(params) => <TextField {...params} label="Select Event Series" variant="outlined" />}
            value={selectedEventSeries}
            onChange={(_event, value) => {
              setSelectedEventSeries(value);
            }}
          />
        )}
      </Box>
      <Button variant="contained" onClick={fetchReport}>{reportLoading ? <CircularProgress size="small" /> : 'Fetch Report'}</Button>
      <Button onClick={() => setReport(null)}>Clear</Button>
      <Box sx={{ width: 300 }}>
        <Slider
          aria-label="Temperature"
          value={maxEnrollment}
          getAriaValueText={(value) => value}
          valueLabelDisplay="auto"
          step={5}
          marks
          min={0}
          max={100}
          onChange={(e, value) => setMaxEnrollment(value)}
        />
      </Box>
      {report && !reportLoading && <PercentCapacityChart data={filterData()} />}
    </Box>
  );
};

export default PercentCapacity;
