import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import {
  Box, Button, CircularProgress, Divider, Grid, Typography,
} from '@mui/material';
import { format } from 'date-fns';
import { useHistory } from 'react-router';
import WushCard from '../../components/WushCard';
import RequestService from '../../services/RequestService';
import config from '../../config';

const Home = ({
  baseStore, reservationStore, scannerStore,
}) => {
  const [deliveries, setDeliveries] = React.useState(null);
  const history = useHistory();
  baseStore.getHelloWorld();
  reservationStore.getReservations();
  scannerStore.getReservations();

  if (!deliveries) {
    RequestService.get(`${config.backendUrl}/deliveries`, (response) => {
      setDeliveries(response.data);
    });
  }

  return (
    <Box p={2}>
      <Grid container>
        <Grid item md={6}>
          <Box p={1}>
            <WushCard>
              <Box pt={1}>
                <Typography variant="h5">New Orders</Typography>
              </Box>
              <Box pb={1}>
                <Typography variant="body1">{format(new Date(), 'EEEE LLLL dd, yyyy')}</Typography>
              </Box>
              <Divider />
              {reservationStore.reservationsPreLoaded ? (
                <Box p={2}>
                  {
                    reservationStore.reservations.slice(0, 10).map((reservation) => (
                      <Box my={1}>
                        <Grid item container>
                          <Grid item md={2}>
                            <Typography variant="body1">
                              #
                              {reservation.id}
                            </Typography>
                          </Grid>
                          <Grid item md={10}>
                            <Box>
                              <Typography variant="body1">{reservation.customerName}</Typography>
                            </Box>
                            <Box>
                              <Typography variant="body1">{reservation.customerEmail}</Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    ))
                  }
                  <Box onClick={() => history.push('/reservation-management/reservations')} p={2} textAlign="right">
                    <Button variant="contained">
                      View All
                    </Button>
                  </Box>
                </Box>
              ) : <CircularProgress />}
            </WushCard>
          </Box>
        </Grid>
        <Grid item md={6}>
          <Box p={1}>
            <WushCard>
              <Box pt={1}>
                <Typography variant="h5">Deliveries</Typography>
              </Box>
              <Divider />
              <Box p={2}>
                {deliveries && deliveries.slice(0, 3).map((delivery) => (
                  <Box my={1}>
                    <Grid container>
                      <Grid item md={2}>
                        <Typography variant="body1">{format(new Date(delivery.deliveryDate), 'MM/dd')}</Typography>
                      </Grid>
                      <Grid item md={8}>
                        <Box>
                          <Typography variant="body1">{delivery.reservation.customerName}</Typography>
                        </Box>
                        <Box>
                          <Typography variant="body1">
                            <b>
                              {delivery.reservationItems.length}
                              {' '}
                              Reserved
                            </b>
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item md={2}>
                        <Box textAlign="right">
                          {delivery.status}
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                ))}
              </Box>
              <Box onClick={() => history.push('/reservation-management/deliveries')} p={2} textAlign="right">
                <Button variant="contained">
                  View All
                </Button>
              </Box>
            </WushCard>
          </Box>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item md={6}>
          <Box p={1}>
            <WushCard>
              <Box pt={1}>
                <Typography variant="h5">Scanner</Typography>
              </Box>
              <Divider />
              {scannerStore.reservationsPreLoaded ? (
                <Box p={2}>
                  {
                    scannerStore.reservations.slice(0, 10).map((reservation) => (
                      <Box my={1}>
                        <Grid item container>
                          <Grid item md={2}>
                            <Typography variant="body1">
                              #
                              {reservation.id}
                            </Typography>
                          </Grid>
                          <Grid item md={10}>
                            <Box>
                              <Typography variant="body1">{reservation.propertyName}</Typography>
                            </Box>
                            <Box>
                              <Typography variant="body1">
                                <b>
                                  {reservation.scannerTickets.length}
                                  {' '}
                                  Bags
                                </b>
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    ))
                  }
                  <Box onClick={() => history.push('/scanner')} p={2} textAlign="right">
                    <Button variant="contained">
                      Scan Now
                    </Button>
                  </Box>
                </Box>
              ) : <CircularProgress />}
            </WushCard>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

Home.propTypes = {
  baseStore: PropTypes.object,
};

export default observer(Home);
