import {
  Box, Breadcrumbs, Chip, CircularProgress, Grid, Link, Stack, Typography, Button, Divider,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useRouteMatch } from 'react-router';
import { format } from 'date-fns';
import { Formik, Form } from 'formik';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@gabmarini/ckeditor5-build-classic-base64upload';
import { CSVLink } from 'react-csv';
import config from '../../../config';
import RequestService from '../../../services/RequestService';
import WushCard from '../../../components/WushCard';
import ImageUploader from '../components/ImageUploader';
import WushModal from '../../../components/WushModal';
import WushTable from '../../../components/WushTable';
import WushInputV2 from '../components/WushInputV2';
import EventForm from '../components/EventForm';

import EventCustomerRegistration from '../components/EventCustomerRegistration';

const ViewEvent = ({ alertStore, employeeStore }) => {
  const match = useRouteMatch();
  const { eventId } = match.params;
  const [event, setEvent] = useState(null);
  const [showUnenrollModal, setShowUnenrollModal] = useState(false);
  const [selectedRegistration, setSelectedRegistration] = useState(false);
  const [showUnenroll, setShowUnenroll] = useState(false);
  const [showSendEmail, setShowSendEmail] = useState(false);
  const [showEnrollCustomer, setShowEnrollCustomer] = useState(false);

  const fetchEvent = () => {
    RequestService.get(`${config.backendUrl}/events/${eventId}`, (response) => {
      setEvent(response.data);
    });
  };

  useEffect(fetchEvent, []);

  const handleUnenrollment = ({ refund, refundAmount }) => {
    RequestService.delete(`${config.backendUrl}/event_registrations/${selectedRegistration.id}?refund=${refund}&refundAmount=${refundAmount}`,
      () => {
        fetchEvent();
        setShowUnenrollModal(false);
        alertStore.setAlertMessage('Successfully unenrolled!');
      }, () => {
        setShowUnenrollModal(false);
        alertStore.setAlertMessage('Failed to unenroll', 'error');
      });
  };

  const handleUpdate = (values) => {
    RequestService.patch(`${config.backendUrl}/events/${values.id}`, { event: values }, () => {
      fetchEvent();
      alertStore.setAlertMessage('Event updated!');
    });
  };

  const handleSendEmail = ({ subject, body }) => {
    RequestService.post(`${config.backendUrl}/events/${eventId}/email`, {
      email: {
        subject,
        body,
      },
    }, () => {
      setShowSendEmail(false);
      alertStore.setAlertMessage('Email sent!');
    });
  };

  if (!event) return <CircularProgress />;

  const csvHeaders = ['registrationId', 'registrationUuid', 'customerEmail', 'customerFirstName', 'customerLastName'];
  if (event.items) {
    event.items.forEach((item) => {
      item.variations.forEach((variation) => {
        csvHeaders.push(variation.name);
      });
    });
  }

  const csvData = event.registrations.map((registration) => {
    const baseCsv = [
      registration.id, registration.uuid, registration.customerEmail,
      registration.customerFirstName, registration.customerLastName,
    ];
    if (event.items) {
      event.items.forEach((item) => {
        item.variations.forEach((variation) => {
          const purchaseItem = registration.eventItemPurchases.filter(
            (itemPurchase) => itemPurchase.itemId === variation.id,
          )[0];
          baseCsv.push(purchaseItem?.purchaseQuantity || 0);
        });
      });
    }
    return baseCsv;
  });

  const calculateItemRevenue = () => {
    let revenue = 0;
    event.registrations.forEach((registration) => {
      registration.eventItemPurchases.forEach((purchase) => {
        revenue += purchase.purchaseQuantity * purchase.itemRetailSalesPrice;
      });
    });
    return revenue / 100;
  };

  return (
    <Box pt={2}>
      <Breadcrumbs aria-label="breadcrumb" mb={2}>
        <Link underline="hover" color="inherit" href="/event-management/events">
          Events
        </Link>
        <Typography color="text.primary">
          {event.name}
        </Typography>
      </Breadcrumbs>
      <Typography variant="h3">{event.name}</Typography>
      <Box>
        <Stack direction="row" spacing={1}>
          {event.enabled && <Chip label="Enabled" color="primary" />}
          {event.registrations.length > 0 && <Chip label="Customers registered" color="success" />}
        </Stack>
      </Box>
      {event.allowAnonymous && (
        <Box my={2}>
          <Typography variant="body1">
            Event URL: /public-events/
            {event.id}
          </Typography>
        </Box>
      )}
      {!event.isEventSeries
        && (
          <Box my={2}>
            <Typography variant="h6">
              Event Revenue
            </Typography>
            <Typography variant="body1">
              Registrations: $
              {(event.registrations.length * event.costCents) / 100}
            </Typography>
            <Typography variant="body1">
              Items: $
              {calculateItemRevenue()}
            </Typography>
            <Divider />
            <Typography variant="body1">
              <strong>
                Total: $
                {calculateItemRevenue() + ((event.registrations.length * event.costCents) / 100)}
              </strong>
            </Typography>
          </Box>
        )}
      <Box mt={2}>
        <Grid container>
          <Grid item md={6}>
            <Box pr={4}>
              <WushCard>
                <Formik
                  initialValues={event}
                  onSubmit={(values) => (handleUpdate(values))}
                >
                  {(formik) => (
                    <EventForm
                      formik={formik}
                      values={formik.values}
                      employeeStore={employeeStore}
                      selectedEvent={event}
                      handleSubmit={formik.handleSubmit}
                    />
                  )}
                </Formik>
              </WushCard>
            </Box>
          </Grid>
          <Grid item md={6}>
            <WushCard>
              <Typography variant="h5">Participants</Typography>
              <Box>
                <WushTable
                  data={event.participants}
                  headers={[
                    {
                      label: 'Name',
                      customGetter: ({ first_name, last_name }) => (
                        <Typography>
                          {first_name}
                          {' '}
                          {last_name}
                        </Typography>
                      ),
                    },
                    { label: 'Id', name: 'id' },
                    { label: 'Registered on', customGetter: (row) => format(new Date(row.created_at), 'MM/dd/yyyy') },
                    { label: 'Item', customGetter: (row) => row.item?.name },
                  ]}
                />
              </Box>
            </WushCard>
            <WushCard>
              <Typography variant="h5">Customers</Typography>
              <Box>
                <WushTable
                  onRowClick={(row) => {
                    setSelectedRegistration(row);
                    setShowUnenrollModal(true);
                  }}
                  data={event.registrations}
                  headers={[
                    {
                      label: 'Name',
                      customGetter: ({ customerFirstName, customerLastName }) => (
                        <Typography>
                          {customerFirstName}
                          {' '}
                          {customerLastName}
                        </Typography>
                      ),
                    },
                    { label: 'Email', name: 'customerEmail' },
                    { label: 'Registered on', customGetter: (row) => format(new Date(row.createdAt), 'MM/dd/yyyy') },
                  ]}
                />
              </Box>
              <Box my={2}>
                <Stack direction="row" spacing={2} marginBottom={2}>
                  <Button variant="contained" onClick={() => setShowSendEmail(true)}>Send Email</Button>
                  <CSVLink
                    data={csvData}
                    headers={csvHeaders}
                    filename={`event-${event.id}-csv-export.csv`}
                    className="btn btn-primary"
                  >
                    Export to CSV
                  </CSVLink>
                </Stack>
                <Stack direction="row">
                  <Button variant="contained" onClick={() => setShowEnrollCustomer(true)}>
                    Enroll a Customer
                  </Button>
                </Stack>
              </Box>
              {showEnrollCustomer && (
                <Box>
                  <EventCustomerRegistration
                    event={event}
                    handleSuccess={() => {
                      alertStore.setAlertMessage('Successfully enrolled user!');
                      fetchEvent();
                      setShowEnrollCustomer(false);
                    }}
                  />
                </Box>
              )}
            </WushCard>
          </Grid>
          <Grid item md={6}>
            <ImageUploader
              endpoint={`${config.backendUrl}/events/${event.id}`}
              imageUrl={event.imageUrl}
              onSuccess={fetchEvent}
            />
          </Grid>
        </Grid>
      </Box>
      <WushModal
        open={showUnenrollModal}
        onClose={() => setShowUnenrollModal(false)}
      >
        <Box>
          <Box mb={2}>
            <Typography variant="h6">
              {selectedRegistration.customerFirstName}
              {' '}
              {selectedRegistration.customerLastName}
              &apos;s registration
            </Typography>
          </Box>
          <Box mb={2}>
            {!showUnenroll && (
              <Button onClick={() => setShowUnenroll(true)}>
                Unenroll from Event
              </Button>
            )}
          </Box>
          { showUnenroll && (
            <Formik
              initialValues={{
                refund: false,
                refundAmount: null,
              }}
              onSubmit={handleUnenrollment}
            >
              { (formik) => (
                <Form>
                  <Grid container>
                    <WushInputV2
                      formik={formik}
                      name="refund"
                      label="Refund Payment"
                      inputType="checkbox"
                      gridCol={3}
                    />
                    <WushInputV2
                      formik={formik}
                      name="refundAmount"
                      label="Refund Amount"
                      inputType="money"
                      gridCol={9}
                      disabled={!formik.values.refund}
                    />
                    <Box mt={2}>
                      <Button onClick={formik.handleSubmit} variant="contained">Unenroll from Event</Button>
                    </Box>
                  </Grid>
                </Form>
              )}
            </Formik>
          ) }
        </Box>
      </WushModal>
      <WushModal
        open={showSendEmail}
        onClose={() => setShowSendEmail(false)}
      >
        <Formik
          initialValues={{
            subject: '',
            body: '',
          }}
          onSubmit={handleSendEmail}
        >
          { (formik) => (
            <Box>
              <Typography variant="h6">
                Compose email to all registrants
              </Typography>
              <Box my={2}>
                <Box mb={2}>
                  <WushInputV2
                    formik={formik}
                    name="subject"
                    label="Subject"
                  />
                </Box>

                <CKEditor
                  editor={ClassicEditor}
                  data={formik.values.body}
                  onChange={(_e, editor) => {
                    formik.setFieldValue('body', editor.getData());
                  }}
                />
              </Box>
              <Button onClick={formik.handleSubmit} variant="contained">Send</Button>
            </Box>
          )}
        </Formik>
      </WushModal>
    </Box>
  );
};

export default ViewEvent;
