import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router';
import { Container } from '@mui/material';
import Events from './containers/Events';
import EventCategories from './containers/EventCategories';
import ViewEvent from './containers/ViewEvent';
import EventSeries from './containers/EventSeries';
import NewEventSeries from './containers/new/NewEventSeries';
import ViewEventSeries from './containers/ViewEventSeries';
import EventSeriesRegistrations from './containers/EventSeriesRegistrations';
import EventSeriesCustomerRegistrations from './containers/EventSeriesCustomerRegistrations';
import Customers from './containers/Customers';
import ViewCustomer from './containers/ViewCustomer';
import ViewPayment from './containers/ViewPayment';
import Waivers from './containers/waivers/Waivers';
import NewWaiver from './containers/waivers/NewWaiver';
import ViewWaiver from './containers/waivers/ViewWaiver';

const EventManagement = (props) => {
  const match = useRouteMatch();
  return (
    <Container>
      <Switch>
        <Route path={`${match.path}/events/:eventId`}>
          <ViewEvent {...props} />
        </Route>
        <Route path={`${match.path}/events`}>
          <Events {...props} />
        </Route>
        <Route path={`${match.path}/event-categories`}>
          <EventCategories {...props} />
        </Route>
        <Route path={`${match.path}/event-series/new`}>
          <NewEventSeries {...props} />
        </Route>
        <Route path={`${match.path}/event-series/:eventSeriesId/registrations/:customerId`}>
          <EventSeriesCustomerRegistrations {...props} />
        </Route>
        <Route path={`${match.path}/event-series/:eventSeriesId/registrations`}>
          <EventSeriesRegistrations {...props} />
        </Route>
        <Route path={`${match.path}/event-series/:eventSeriesId`}>
          <ViewEventSeries {...props} />
        </Route>
        <Route path={`${match.path}/event-series`}>
          <EventSeries {...props} />
        </Route>
        <Route path={`${match.path}/customers/:customerId`}>
          <ViewCustomer {...props} />
        </Route>
        <Route path={`${match.path}/customers`}>
          <Customers {...props} />
        </Route>
        <Route path={`${match.path}/payments/:paymentId`}>
          <ViewPayment {...props} />
        </Route>
        <Route path={`${match.path}/waivers/new`}>
          <NewWaiver {...props} />
        </Route>
        <Route path={`${match.path}/waivers/:waiverId`}>
          <ViewWaiver {...props} />
        </Route>
        <Route path={`${match.path}/waivers`}>
          <Waivers {...props} />
        </Route>
      </Switch>
    </Container>
  );
};

export default EventManagement;
