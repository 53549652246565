import React, { useState, useEffect } from 'react';
import { useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';
import {
  Box, Button, Grid, Stack, Typography,
} from '@mui/material';
import config from '../../../../config';
import RequestService from '../../../../services/RequestService';
import Loader from '../../../../components/loader/Loader';
import WushPopover from '../../../../components/misc/WushPopover';

const ViewDelivery = () => {
  const match = useRouteMatch();
  const { deliveryId } = match.params;

  const [delivery, setDelivery] = useState(null);

  const fetchDelivery = () => {
    RequestService.get(`${config.backendUrl}/deliveries/${deliveryId}`, (response) => {
      setDelivery(response.data);
    });
  };

  useEffect(() => {
    fetchDelivery();
  }, [deliveryId]);

  const handleDeliver = () => {
    RequestService.post(`${config.backendUrl}/deliveries/${deliveryId}/deliver`, {}, (response) => {
      setDelivery(response.data);
    });
  };

  if (!delivery) return <Loader />;

  return (
    <Box py={2}>
      <Grid container>
        <Grid item sm={6}>
          <Typography variant="h4">
            Delivery #
            {deliveryId}
          </Typography>
        </Grid>
        <Grid item sm={6}>
          <Stack justifyContent="right" direction="row" spacing={2}>
            <WushPopover
              anchor={(
                <Button variant="outlined">
                  More Actions
                </Button>
              )}
            >
              <Box p={2}>
                <Button disabled={delivery.delivered} onClick={handleDeliver}>
                  {delivery.delivered ? 'Already delivered' : 'Deliver'}
                </Button>
              </Box>
            </WushPopover>
          </Stack>
        </Grid>
      </Grid>
      <Box>
        <Typography variant="h5">
          {delivery.reservation.propertyName}
        </Typography>
        <Typography variant="h5">
          {delivery.reservation.propertyAddress}
          {', '}
          {delivery.reservation.propertyCity}
          {', '}
          {delivery.reservation.propertyState}
          {' '}
          {delivery.reservation.propertyZip}
        </Typography>
        <Typography variant="h5">
          Delivered:
          {' '}
          {delivery.delivered ? 'Yes' : 'No'}
        </Typography>
        <Typography variant="h5">
          Reservation ID:
          {' '}
          <Link to={`/reservation-management/reservations/${delivery.reservationId}`}>
            {delivery.reservationId}
          </Link>
        </Typography>
        <Typography variant="h5">
          Status:
          {' '}
          <i>{delivery.delivered ? 'Delivered' : 'Not Delivered'}</i>
        </Typography>
      </Box>
    </Box>
  );
};

export default ViewDelivery;
