import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { Redirect, useRouteMatch } from 'react-router';
import {
  Box, Breadcrumbs, Checkbox, Chip, CircularProgress, FormControlLabel, Grid,
  LinearProgress, Link, TextField, Typography, Autocomplete,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { DataGrid } from '@material-ui/data-grid';
import { APPS } from '../../../../constants';
import Bag from './components/Bag';

const useStyles = makeStyles(() => ({
  propertyItemsContainer: {
    height: '300px',
  },
  buttonPadding: {
    marginRight: '15px',
  },
}));

const propertyBreadcrumbs = (property) => (
  <Breadcrumbs aria-label="breadcrumb">
    <Link underline="hover" color="inherit" href={`/${APPS.PROPERTIES.baseRoute}`}>
      {APPS.PROPERTIES.title}
    </Link>
    <Link underline="hover" color="inherit" href={`/${APPS.PROPERTIES.baseRoute}/${APPS.PROPERTIES.pages.PROPERTIES.baseRoute}`}>
      {APPS.PROPERTIES.pages.PROPERTIES.title}
    </Link>
    <Typography color="text.primary">{property.name}</Typography>
  </Breadcrumbs>
);

const Property = ({
  propertyStore, itemStore, propertyItemStore, bagStore, bagItemStore,
}) => {
  const classes = useStyles();
  const match = useRouteMatch();
  const { propertyId } = match.params;

  itemStore.getItems();
  propertyStore.getProperty(propertyId);
  bagStore.getBags();

  const [selectedItem, setSelectedItem] = useState(null);
  const [maxBagWeight, setMaxBagWeight] = useState(null);
  const [iterations, setIterations] = useState(10);

  if (!bagStore.bagsPreLoaded
      || !propertyStore.selectedPropertyPreLoaded) return <LinearProgress />;

  const property = propertyStore.selectedProperty;
  if (!property) return <Redirect to={`/${APPS.PROPERTIES.baseRoute}`} />;

  propertyItemStore.getPropertyItems(propertyId);
  if (!propertyItemStore.propertyItemsPreLoaded) return <LinearProgress />;

  propertyStore.getBagStatus(false, property.id);

  const propertyItemRows = propertyItemStore.propertyItems.map((propertyItem) => ({
    id: propertyItem.id,
    itemId: propertyItem.itemId,
    propertyId: propertyItem.propertyId,
    itemName: propertyItem.itemName,
    quantity: propertyItem.quantity,
  }));

  const bags = property.bags.map((bag) => (
    <Bag
      bag={bag}
      updateBagItem={(bagItem) => {
        bagItemStore.updateBagItem(bagItem, () => {
          propertyStore.getProperty(propertyId, true,
            () => propertyStore.getBagStatus(true, propertyId));
        });
      }}
      deleteBag={() => propertyStore.deleteBag(bag.id)}
    />
  ));

  const columns = [
    {
      field: 'itemName',
      headerName: 'Item',
      width: 150,
      editable: false,
    },
    {
      field: 'quantity',
      headerName: 'Quantity',
      width: 150,
      editable: true,
    },
    {
      field: 'id',
      headerName: 'Actions',
      width: 150,
      editable: true,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="error"
          onClick={() => propertyItemStore.deletePropertyItem(params.id)}
        >
          Delete
        </Button>
      ),
    },
  ];

  return (
    <Box pt={2}>
      {propertyBreadcrumbs(property)}
      <Typography variant="h3">{property.name}</Typography>
      <Typography variant="h5">{property.address1}</Typography>
      <Typography variant="h5">{property.address2}</Typography>
      <Typography variant="h5">
        {property.city}
        {' '}
        {property.state}
        {', '}
        {property.zip}
      </Typography>
      <hr />
      <Grid container justifyContent="space-around">
        <Box item sx={{ width: '50%' }} pr={2}>
          <Typography variant="h5">Add Item to Property</Typography>
          <Box mb={1}>
            <Autocomplete
              id="kit-combo-box"
              options={itemStore.items.sort((item) => (item.itemCategoryName === 'Linens' ? -1 : 1))}
              getOptionLabel={(option) => (option.name)}
              renderInput={(params) => <TextField {...params} label="Please select" variant="outlined" />}
              onChange={(event, value) => setSelectedItem(value)}
              groupBy={(option) => option.itemCategoryName}
            />
          </Box>
          <Button onClick={() => propertyItemStore.addPropertyItem({
            itemId: selectedItem.id, propertyId: property.id,
          })}
          >
            Add Item
          </Button>
          <div className={classes.propertyItemsContainer}>
            <DataGrid
              rows={propertyItemRows}
              columns={columns}
              pageSize={10}
              disableSelectionOnClick
              onCellEditCommit={propertyItemStore.updatePropertyItem}
            />
          </div>
        </Box>
        <Box item sx={{ width: '50%' }} pl={1}>
          <Typography variant="h5">Bags</Typography>
          <Box mb={1}>
            <Grid container>
              <Grid item md={6}>
                <Button
                  className={classes.buttonPadding}
                  onClick={() => propertyStore.generateBags(
                    property.id, maxBagWeight, iterations,
                    () => propertyItemStore.getPropertyItems(propertyId, true),
                  )}
                  disabled={property.idealBagDistribution}
                >
                  Generate
                </Button>
                <TextField label="Max Weight: " value={maxBagWeight || property.maxBagWeight} onChange={(e) => setMaxBagWeight(e.target.value)} />
              </Grid>
              <Grid item md={6}>
                <Button
                  className={classes.buttonPadding}
                  onClick={() => propertyStore.generateBags(
                    property.id, maxBagWeight, iterations, true,
                    () => propertyItemStore.getPropertyItems(propertyId, true),
                  )}
                  disabled={property.idealBagDistribution}
                >
                  Generate (Experimental!)
                </Button>
                <TextField label="Iterations: " value={iterations} onChange={(e) => setIterations(e.target.value)} />
              </Grid>
            </Grid>

            <hr />

          </Box>
          <Box>
            <FormControlLabel
              control={<Checkbox checked={property.idealBagDistribution} />}
              label="Use for training data"
              onChange={(_event, value) => {
                propertyStore.updateProperty({
                  id: property.id,
                  field: 'idealBagDistribution',
                  value,
                });
              }}
            />
          </Box>
          {propertyStore.bagStatusPreLoaded && (
            <Box mb={1}>
              Status:
              {propertyStore.bagStatus.map((itemStatus) => (
                <Chip
                  color={itemStatus.baggedQuantity !== itemStatus.requiredQuantity ? 'secondary' : 'primary'}
                  size="small"
                  label={`${itemStatus.itemName}: ${itemStatus.baggedQuantity} / ${itemStatus.requiredQuantity}`}
                />
              ))}
            </Box>
          )}
          {propertyStore.bagsGenerating ? <CircularProgress /> : bags}
          <Button onClick={() => propertyStore.addBag(property.id)}>Add Empty Bag</Button>
        </Box>
      </Grid>
    </Box>
  );
};

Property.propTypes = {
  propertyStore: PropTypes.any,
  propertyItemStore: PropTypes.any,
  itemStore: PropTypes.any,
  bagStore: PropTypes.any,
  bagItemStore: PropTypes.any,
};

export default observer(Property);
