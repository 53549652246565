import React from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import { Box } from '@mui/material';

const localizer = momentLocalizer(moment);

const DragAndDropCalendar = withDragAndDrop(Calendar);

const eventProps = (event) => {
  const style = {
    backgroundColor: event.eventSeriesId ? 'lightblue' : '',
  };
  return {
    style,
  };
};

const WushCalendar = ({ events, handleSelectSlot, ...props }) => (
  <Box>
    <DragAndDropCalendar
      localizer={localizer}
      events={events}
      onSelectSlot={handleSelectSlot}
      selectable
      startAccessor="start"
      endAccessor="end"
      style={{ height: 500 }}
      defaultView="week"
      eventPropGetter={eventProps}
      {...props}
    />
  </Box>
);

export default WushCalendar;
