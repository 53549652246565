import {
  Box, Button, Grid, Typography,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import Loader from '../../../../components/loader/Loader';
import config from '../../../../config';
import RequestService from '../../../../services/RequestService';
import DeliveryRouteCard from '../../../../components/cards/DeliveryRouteCard';
import DeliveryRoutesSnippetCard from '../../../../components/cards/DeliveryRoutesSnippetCard';
import ScannerPrint from '../../../scanner/ScannerPrint';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const DeliveryRoutes = () => {
  const [deliveryRoutes, setDeliveryRoutes] = useState(null);
  const [selectedDeliveryRoutes, setSelectedDeliveryRoutes] = useState([]);
  const [sentScannerTickets, setSentScannerTickets] = useState([]);

  useEffect(() => {
    RequestService.get(`${config.backendUrl}/delivery-routes`, (response) => {
      setDeliveryRoutes(response.data);
    });
  }, []);

  useEffect(() => {
    if (sentScannerTickets.length > 0) {
      window.print();
    }
  }, [sentScannerTickets]);

  const handleDeleteDeliveryRoute = (deletedRouteId) => {
    // eslint-disable-next-line max-len
    setDeliveryRoutes((prevDeliveryRoutes) => prevDeliveryRoutes.filter((route) => route.id !== deletedRouteId));
    // eslint-disable-next-line max-len
    setSelectedDeliveryRoutes((prevSelectedRoutes) => prevSelectedRoutes.filter((route) => route.id !== deletedRouteId));
  };

  const sendToScanner = () => {
    RequestService.post(
      `${config.backendUrl}/delivery-routes/send-to-scanner`,
      { deliveryRouteIds: selectedDeliveryRoutes.map((deliveryRoute) => deliveryRoute.id) },
      (response) => {
        // eslint-disable-next-line
        const scannerTickets = [];
        response.data.forEach((reservation) => {
          reservation.scannerTickets.forEach((scannerTicket) => {
            scannerTickets.push({
              reservation,
              ...scannerTicket,
            });
          });
        });
        setSentScannerTickets(scannerTickets);
      },
    );
    // IF SUCCESFULL reset selectedDeliveries to None
    setSelectedDeliveryRoutes([]);
    // Also need to remove from Delviery Routes on this page.
  };

  const handleDrop = (result) => {
    // console.log(result);
    if (
      !result.destination
      || (result.source.droppableId === 'full-list' && result.destination.droppableId === 'full-list')
    ) {
      return;
    }

    if (
      result.source.droppableId === 'send-to-pack'
      && result.destination.droppableId === 'send-to-pack'
    ) {
      const reordered = reorder(
        selectedDeliveryRoutes,
        result.source.index,
        result.destination.index,
      );

      setSelectedDeliveryRoutes(reordered);
    } else if (result.destination.droppableId === 'send-to-pack') {
      const updatedSelected = selectedDeliveryRoutes.concat(
        deliveryRoutes.filter((e) => parseInt(e.id, 10) === parseInt(result.draggableId, 10))[0],
      );
      setSelectedDeliveryRoutes(updatedSelected);
      setDeliveryRoutes(
        deliveryRoutes.filter((e) => parseInt(e.id, 10) !== parseInt(result.draggableId, 10)),
      );
    } else if (result.source.droppableId === 'send-to-pack') {
      setSelectedDeliveryRoutes(
        selectedDeliveryRoutes.filter(
          (e) => parseInt(e.id, 10) !== parseInt(result.draggableId, 10),
        ),
      );
      setDeliveryRoutes(
        deliveryRoutes.concat(
          selectedDeliveryRoutes.filter(
            (e) => parseInt(e.id, 10) === parseInt(result.draggableId, 10),
          )[0],
        ),
      );
    }
  };

  if (!deliveryRoutes) return <Loader />;

  return (
    <Box py={2} className="delivery-routes">
      <Grid container className="hide-print">
        <DragDropContext onDragEnd={handleDrop}>
          <Grid item md={9}>
            <Droppable droppableId="full-list">
              {(providedUpper) => (
                <Box
                  ref={providedUpper.innerRef}
                  {...providedUpper.droppableProps}
                  width="100%"
                  display="flex"
                  flexDirection="row"
                  flexWrap="wrap"
                >
                  {deliveryRoutes.map((deliveryRoute, index) => (
                    <Draggable
                      key={deliveryRoute.id}
                      draggableId={`${deliveryRoute.id}`}
                      index={index}
                    >
                      {(provided) => (
                        <Box
                          mb={2}
                          px={1}
                          width="25%"
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <DeliveryRouteCard
                            deliveryRoute={deliveryRoute}
                            onDeleteDeliveryRoute={handleDeleteDeliveryRoute}
                          />
                        </Box>
                      )}
                    </Draggable>
                  ))}
                </Box>
              )}
            </Droppable>
          </Grid>

          {/* Drop Routes being sent to Pack Here */}
          <Grid item md={3}>
            <Droppable droppableId="send-to-pack">
              {(providedUpper) => (
                <Box
                  border="2px solid #00b4d2"
                  borderRadius="10px"
                  ml={1}
                  display="flex"
                  flexDirection="column"
                >
                  <Box
                    padding={1}
                    bgcolor="#00b4d2"
                    borderRadius="5px 5px 0 0"
                    display="flex"
                    justifyContent="center"
                  >
                    <Typography variant="h5" color="white" fontWeight="600 !important">
                      Pack
                    </Typography>
                  </Box>
                  <Box
                    ref={providedUpper.innerRef}
                    {...providedUpper.droppableProps}
                    minHeight="30rem"
                  >
                    <Box p={1}>
                      <Typography variant="subtitle2" mx={2} textAlign="center">
                        Drag cards here to send to Pack
                      </Typography>
                    </Box>

                    {selectedDeliveryRoutes.map((deliveryRoute, index) => (
                      <Draggable
                        key={deliveryRoute.id}
                        draggableId={`${deliveryRoute.id}`}
                        index={index}
                      >
                        {(provided) => (
                          <Box
                            width="100%"
                            marginY="8px"
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <DeliveryRoutesSnippetCard deliveryRoute={deliveryRoute} />

                          </Box>
                        )}
                      </Draggable>
                    ))}
                  </Box>
                  <Box display="flex" justifyContent="center" onClick={sendToScanner}>
                    <Button className="go-to-pack">Go To Pack</Button>
                  </Box>
                </Box>
              )}
            </Droppable>
          </Grid>
        </DragDropContext>
      </Grid>
      <ScannerPrint scannerTickets={sentScannerTickets} />
    </Box>
  );
};

export default DeliveryRoutes;
