import React, { useState, useEffect } from 'react';
import {
  Box, Typography, CircularProgress, Button,
} from '@mui/material';
import { Formik, Form } from 'formik';
import { useRouteMatch } from 'react-router';
import { observer } from 'mobx-react';

import { format, startOfMonth } from 'date-fns';
import RequestService from '../../../../services/RequestService';
import config from '../../../../config';

import WushTable from '../../../../components/WushTable';
import WushModal from '../../../../components/WushModal';
import WushInputV2 from '../../../events/components/WushInputV2';
import CreditCardForm from './components/CreditCardForm';

const ViewSubscription = ({ customerStore }) => {
  const [subscription, setSubscription] = useState();
  const [subscriptionLoading, setSubscriptionLoading] = useState(true);
  const [showAddSubscriptionModal, setShowAddSubscriptionModal] = useState(false);

  const match = useRouteMatch();
  const { subscriptionId } = match.params;

  const fetchSubscription = () => {
    RequestService.get(`${config.backendUrl}/subscriptions/${subscriptionId}`, (response) => {
      setSubscription(response.data);
      setSubscriptionLoading(false);
    });
  };

  const handleSubmit = (paymentMethodId, values) => {
    RequestService.post(`${config.backendUrl}/customer_subscriptions`, {
      customerSubscription: {
        subscriptionId,
        customerId: values.customerId,
        startDate: values.startDate,
        paymentMethodId,
      },
    }, () => {
      fetchSubscription();
      setShowAddSubscriptionModal(false);
    });
  };

  useEffect(() => {
    fetchSubscription();
    customerStore.getCustomers();
  }, []);

  if (subscriptionLoading || !customerStore.customersPreLoaded) return <CircularProgress />;

  return (
    <Box pt={2}>
      <Box mb={2}>
        <Button onClick={() => setShowAddSubscriptionModal(true)} variant="contained" color="primary">
          Add Subscriber
        </Button>
      </Box>

      <WushTable
        headers={[
          { label: 'Customer Name', customGetter: (row) => `${row.customerFirstName} ${row.customerLastName}` },
          { label: 'Created Date', customGetter: (row) => format(new Date(row.createdAt), 'MM/dd/yyyy') },
          { label: 'First Charge', customGetter: (row) => (row.firstChargeOn ? format(new Date(row.firstChargeOn), 'MM/dd/yyyy') : 'N/A') },
        ]}
        data={subscription.customerSubscriptions}
      />
      <WushModal
        open={showAddSubscriptionModal}
        onClose={() => setShowAddSubscriptionModal(false)}
      >
        <Formik
          initialValues={{
            customerId: null,
            startDate: startOfMonth(new Date()),
            subscriptionId,
          }}
          onSubmit={(values, paymentMethodId) => handleSubmit(paymentMethodId, values)}
        >
          {
            (formik) => (
              <Form>

                <WushInputV2
                  inputType="date"
                  formik={formik}
                  label="Subscription Start Date"
                  name="startDate"
                  helpText="The date the subscription cycle should start. If in the future,
                    customer will not be billed until this date. If in the past, customer will be
                    billed immediately for this cycle.
                  "
                />
                <WushInputV2
                  inputType="autoselect"
                  options={customerStore.customers.map((customer) => (
                    {
                      name: `${customer.firstName} ${customer.lastName}`,
                      id: customer.id,
                    }
                  ))}
                  name="customerId"
                  formik={formik}
                  label="Customer"
                />
                { formik.values.customerId && (
                  <Box>
                    <Box my={5}>
                      <Typography variant="h6">
                        Enter payment information:
                      </Typography>
                    </Box>
                    <Box px={5}>
                      <CreditCardForm
                        customerId={formik.values.customerId}
                        subscriptionId={subscriptionId}
                        handleSuccess={(paymentMethodId) => {
                          handleSubmit(paymentMethodId, formik.values);
                        }}
                        buttonText="Subscribe"
                      />
                    </Box>

                  </Box>
                ) }
              </Form>
            )
          }
        </Formik>
      </WushModal>
    </Box>
  );
};

export default observer(ViewSubscription);
