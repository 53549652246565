import { Box, Button, CircularProgress } from '@mui/material';
import {
  Elements, PaymentElement, useElements, useStripe,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React, { useState } from 'react';

import config from '../../../../../config';
import RequestService from '../../../../../services/RequestService';

const CreditCardForm = ({
  handleSuccess, customerId, subscriptionId, buttonText = 'Submit',
}) => {
  const [stripePublicKey, setStripePublicKey] = useState(null);
  const [clientSecret, setClientSecret] = useState(null);

  const fetchClientSecret = () => {
    RequestService.get(`${config.backendUrl}/customer_subscriptions/setup_payment/${customerId}/${subscriptionId}`, (response) => {
      setClientSecret(response.data.clientSecret);
    });
  };

  const getOptions = () => ({
    clientSecret,
  });

  React.useEffect(() => {
    if (!stripePublicKey) {
      RequestService.get(`${config.backendUrl}/public/organizations/me/stripe-public-key`, (response) => {
        setStripePublicKey(response.data.stripePublicKey);
        if (!clientSecret) fetchClientSecret();
      });
    }
  });

  const stripePromise = loadStripe(stripePublicKey);

  const CheckoutForm = () => {
    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async () => {
      // We don't want to let default form submission happen here,
      // which would refresh the page.
      // event.preventDefault();

      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }

      const result = await stripe.confirmSetup({
        elements,
        confirmParams: {
          return_url: 'https://google.com',
        },
        redirect: 'if_required',
      });

      if (!result.error && handleSuccess) handleSuccess(result.setupIntent.payment_method);
    };

    return (
      <form onSubmit={(e) => { handleSubmit(e); }}>
        <Box mb="2rem">
          <PaymentElement />
        </Box>
        <Button onClick={handleSubmit} variant="contained" disabled={!stripe}>{buttonText}</Button>
      </form>
    );
  };

  if (!clientSecret || !stripePublicKey) return <CircularProgress />;

  return (
    <Box>
      <Elements stripe={stripePromise} options={getOptions()}>
        {clientSecret && <CheckoutForm />}
      </Elements>
    </Box>
  );
};

export default CreditCardForm;
