import React from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import HelpIcon from '@mui/icons-material/Help';
import {
  Autocomplete,
  Box, FormGroup, FormLabel, Grid, InputAdornment, TextField, Tooltip,
} from '@mui/material';
import { Field } from 'formik';

const IconWithTooltip = ({ helpText }) => (
  <Tooltip title={helpText} placement="top">
    <HelpIcon fontSize="12px" />
  </Tooltip>
);

const WushInput = ({
  formik, label, value, error, helperText, onChange, name,
  gridCol = 12, inputType = null, options = [], mb = 0, helpText, disabled,
}) => {
  const renderInput = () => {
    switch (inputType) {
    case 'date':
      return (
        <FormGroup>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label={(
                <Box>
                  {label}
                  {' '}
                  {helpText && <IconWithTooltip helpText={helpText} />}
                </Box>
              )}
              value={formik.values?.[name] || value}
              onChange={(val) => formik.setFieldValue(name, val)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={formik ? formik.touched?.[name] && formik.errors?.[name] : error}
                  helperText={formik && formik.touched?.[name] && formik.errors?.[name]}
                />
              )}
            />
          </LocalizationProvider>
        </FormGroup>
      );
    case 'datetime':
      return (
        <FormGroup>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              label={(
                <Box>
                  {label}
                  {' '}
                  {helpText && <IconWithTooltip helpText={helpText} />}
                </Box>
              )}
              value={formik.values?.[name] || value}
              onChange={(val) => formik.setFieldValue(name, val)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={formik ? formik.touched?.[name] && formik.errors?.[name] : error}
                  helperText={formik && formik.touched?.[name] && formik.errors?.[name]}
                />
              )}
            />
          </LocalizationProvider>
        </FormGroup>
      );
    case 'checkbox':
      return (
        <FormGroup>
          <FormLabel>
            <strong>{label}</strong>
            {' '}
            {helpText && <IconWithTooltip helpText={helpText} />}
          </FormLabel>
          <Field type="checkbox" name={name} />
        </FormGroup>
      );
    case 'money':
      return (
        <FormGroup>
          <TextField
            type="number"
            label={(
              <Box>
                {label}
                {' '}
                {helpText && <IconWithTooltip helpText={helpText} />}
              </Box>
            )}
            value={formik ? formik.values?.[name] : value}
            onChange={formik ? (e) => formik.setFieldValue(name, e.target.value) : onChange}
            error={formik ? formik.touched?.[name] && formik.errors?.[name] : error}
            helperText={formik ? formik.touched?.[name] && formik.errors?.[name] : helperText}
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            disabled={disabled}
          />
        </FormGroup>
      );
    case 'number':
      return (
        <FormGroup>
          <TextField
            type="number"
            label={(
              <Box>
                {label}
                {' '}
                {helpText && <IconWithTooltip helpText={helpText} />}
              </Box>
            )}
            value={formik ? formik.values?.[name] : value}
            error={formik ? formik.touched?.[name] && formik.errors?.[name] : error}
            helperText={formik ? formik.touched?.[name] && formik.errors?.[name] : helperText}
            onChange={formik ? (e) => formik.setFieldValue(name, e.target.value) : onChange}
            disabled={disabled}
          />
        </FormGroup>
      );
    case 'textarea':
      return (
        <FormGroup>
          <TextField
            label={(
              <Box>
                {label}
                {' '}
                {helpText && <IconWithTooltip helpText={helpText} />}
              </Box>
            )}
            multiline
            value={formik ? formik.values?.[name] : value}
            onChange={formik ? (e) => formik.setFieldValue(name, e.target.value) : onChange}
          />
        </FormGroup>
      );
    case 'radioGroup':
      return (
        <FormGroup>
          <Box mb={1}>
            <FormLabel><strong>{label}</strong></FormLabel>
          </Box>
          {options.map((option) => (
            <FormLabel>
              <Field type="radio" name={name} value={option.value} />
              {option.label}
            </FormLabel>
          ))}
        </FormGroup>
      );
    case 'autoselect':
      return (
        <Autocomplete
          id="combo-box-demo"
          options={options}
          getOptionLabel={(option) => (
            option.name || options.filter((opt) => opt.id === formik.values[name])[0].name
          )}
          value={formik.values[name]}
          isOptionEqualToValue={(option, selected) => option.id === selected}
          className={null}
          renderInput={(params) => (
            <TextField
              {...params}
              label={(
                <Box>
                  {label}
                  {' '}
                  {helpText && <IconWithTooltip helpText={helpText} />}
                </Box>
              )}
              variant="outlined"
              error={formik ? formik.touched?.[name] && formik.errors?.[name] : error}
              helperText={formik ? formik.touched?.[name] && formik.errors?.[name] : helperText}
            />
          )}
          onChange={(_event, option) => {
            formik.setFieldValue(name, option && (option.id || option));
          }}
        />
      );
    default:
      return (
        <FormGroup>
          <TextField
            label={(
              <Box>
                {label}
                {' '}
                {helpText && <IconWithTooltip helpText={helpText} />}
              </Box>
            )}
            value={formik ? formik.values?.[name] : value}
            onChange={formik ? (e) => formik.setFieldValue(name, e.target.value) : onChange}
            error={formik ? formik.touched?.[name] && formik.errors?.[name] : error}
            helperText={formik ? formik.touched?.[name] && formik.errors?.[name] : helperText}
            disabled={disabled}
          />
        </FormGroup>
      );
    }
  };
  return (
    <Grid item md={gridCol}>
      <Box p="0.25rem" mb={mb}>
        {renderInput()}
      </Box>
    </Grid>
  );
};

export default WushInput;
