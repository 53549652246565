import {
  Box, Button, Card, CardContent, Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';
import PropTypes from 'prop-types';
import { DataGrid } from '@material-ui/data-grid';

const useStyles = makeStyles((theme) => ({
  bagCard: {
    display: 'flex',
    width: '40vw',
    height: '22rem',
    background: 'transparent',
    border: '1px solid white',
    justifyContent: 'center',
    paddingBottom: '4rem',
  },
  paper: {
    position: 'absolute',
    width: '75%',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    textAlign: 'center',
  },
}));

const Bag = ({
  bag, updateBagItem, deleteBag,
}) => {
  const classes = useStyles();

  const columns = [
    {
      field: 'itemName',
      headerName: 'Item Name',
      editable: false,
      width: 150,
    },
    {
      field: 'quantity',
      headerName: 'Quantity',
      editable: true,
      width: 150,
    },
  ];

  const bagRows = (bagItems) => (bagItems.map((bagItem) => ({
    bagItem,
    id: bagItem.id,
    itemName: bagItem.itemName,
    quantity: bagItem.quantity,
  })));

  return (
    <div>
      <Box display="inline-block">
        <Card className={classes.bagCard}>
          <CardContent>
            <Box textAlign="right">
              <Button type="button" size="sm" onClick={deleteBag}>Delete</Button>
            </Box>
            <Typography sx={{ fontSize: 14 }} inline>
              <strong>Bag:</strong>
              {' '}
              { bag.id }
            </Typography>
            <Typography sx={{ fontSize: 14 }} inline>
              <strong>Weight:</strong>
              {' '}
              { bag.weight }
              {' '}
              pounds
            </Typography>
            <DataGrid
              rows={bagRows(bag.bagItems)}
              columns={columns}
              rowHeight="32"
              onCellEditCommit={updateBagItem}
            />
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

Bag.propTypes = {
  bag: PropTypes.object,
  updateBagItem: PropTypes.func,
  deleteBag: PropTypes.func,
};

export default Bag;
