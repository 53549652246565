import React, { useState, useEffect } from 'react';
import { useRouteMatch } from 'react-router';
import { Button } from 'react-bootstrap';
import {
  Box, Chip, LinearProgress, TextField, Typography, IconButton,
  Dialog, DialogTitle, DialogContent, DialogActions, FormHelperText,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { DataGrid } from '@material-ui/data-grid';
import RequestService from '../../../../services/RequestService';
import config from '../../../../config';

const EditProperty = () => {
  const match = useRouteMatch();
  const { propertyId } = match.params;

  const [property, setProperty] = useState(null);
  const [reservations, setReservations] = useState(null);

  const [aliases, setAliases] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [aliasValue, setAliasValue] = useState('');
  const [aliasError, setAliasError] = useState(false);
  const [editState, setEditState] = useState(false);
  const [aliasId, setAliasId] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  useEffect(() => {
    RequestService.get(`${config.backendUrl}/properties/${propertyId}`, (response) => {
      setProperty(response.data);
    });
    RequestService.get(`${config.backendUrl}/properties/${propertyId}/reservations`, (response) => {
      setReservations(response.data);
    });
    RequestService.get(`${config.backendUrl}/properties/${propertyId}/property_aliases`, (response) => {
      setAliases(response.data);
    });
  }, [propertyId]);

  const handleDialogOpen = () => setDialogOpen(true);
  const handleDialogClose = () => setDialogOpen(false);
  const handleAliasOnChange = (event) => setAliasValue(event.target.value);

  const handleAddAlias = () => {
    if (aliasValue.trim() === '') {
      setAliasError(true);
      return;
    }
    RequestService.post(`${config.backendUrl}/properties/${propertyId}/property_aliases`, {
      value: aliasValue,
    }, () => {
    });
    setAliasValue('');
    handleDialogClose();
  };

  const handleEditAlias = (id, value) => {
    setEditState(true);
    setAliasId(id);
    setAliasValue(value);
    setShowDeleteConfirmation(false);
    setDialogOpen(true);
  };

  const handleUpdateAlias = () => {
    if (aliasValue.trim() === '') {
      setAliasError(true);
      return;
    }
    RequestService.post(`${config.backendUrl}/properties/${propertyId}/property_aliases/${aliasId}`, {
      value: aliasValue,
    }, () => {
    });
    setAliasValue('');
    setDialogOpen(false);
    setEditState(false);
  };

  const handleShowDeleteConfirmation = () => setShowDeleteConfirmation(true);

  const handleDeleteAlias = () => {
    RequestService.delete(`${config.backendUrl}/properties/${propertyId}/property_aliases/${aliasId}`, () => {
    });
    setAliasValue('');
    setDialogOpen(false);
    setEditState(false);
    setShowDeleteConfirmation(false);
  };

  // eslint-disable-next-line
  const handleDateCellEdit = (params, reservations) => {
    const { id, field, value } = params;
    if (field === 'endDate' || field === 'startDate') {
      const row = reservations.find((r) => r.id === id);
      if (row) {
        const newStartDate = field === 'startDate' ? value : row.startDate;
        const newEndDate = field === 'endDate' ? value : row.endDate;

        RequestService.patch(
          `${config.backendUrl}/reservations/${id}`,
          { reservation: { startDate: newStartDate, endDate: newEndDate } },
          (response) => {
            const updatedReservations = reservations.filter((reservation) => reservation.id !== id);
            updatedReservations.push(response.data);
            setReservations(updatedReservations);
          },
        );
      }
    }
  };

  if (!property || !reservations) {
    return <LinearProgress />;
  }

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      width: 100,
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 200,
    },
    {
      field: 'startDate',
      headerName: 'Start Date',
      width: 150,
      editable: true,
    },
    {
      field: 'endDate',
      headerName: 'End Date',
      width: 150,
      editable: true,
    },
    {
      field: 'customerName',
      headerName: 'Customer Name',
      width: 200,
    },
  ];

  // Date handling for tables
  const currentDate = new Date();
  const pastReservations = reservations.filter((reservation) => {
    const endDate = new Date(reservation.endDate);
    return endDate < currentDate;
  });
  const upcomingReservations = reservations.filter((reservation) => {
    const startDate = new Date(reservation.startDate);
    return startDate > currentDate;
  });
  const currentReservations = reservations.filter((reservation) => {
    const startDate = new Date(reservation.startDate);
    const endDate = new Date(reservation.endDate);
    return startDate <= currentDate && endDate >= currentDate;
  });

  return (
    <Box py={2}>
      <Box>
        <Typography variant="h3">
          {property.name}
        </Typography>
        <Typography variant="h5">{property.address1}</Typography>
        <Typography variant="h5">
          {property.city}
          {' '}
          {property.state}
          {', '}
          {property.zip}
        </Typography>
        <Typography variant="h5">
          Aliases:
          {' '}
          {aliases.map((alias) => (
            <Chip
              key={alias.id}
              label={alias.value}
              onClick={() => {
                handleEditAlias(alias.id, alias.value);
              }}
              sx={{ mr: 1 }}
            />
          ))}
          <IconButton onClick={handleDialogOpen} size="small">
            <AddIcon />
          </IconButton>
          <Dialog
            open={dialogOpen}
            onClose={handleDialogClose}
          >
            <DialogTitle>
              {editState ? 'Edit ' : 'Add '}
              Alias for
              {' '}
              {property.name}
            </DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                label="Alias"
                fullWidth
                value={aliasValue}
                onChange={handleAliasOnChange}
                error={aliasError}
              />
              {aliasError && (
                <FormHelperText sx={{ color: 'red' }}>
                  Please enter an alias.
                </FormHelperText>
              )}
            </DialogContent>
            <DialogActions style={{ display: 'flex', justifyContent: 'space-between' }} sx={{ mr: 1, ml: 1 }}>
              <Button
                variant="secondary"
                onClick={() => {
                  handleDialogClose();
                  setEditState(false);
                }}
              >
                Cancel
              </Button>
              {editState ? (
                <Box>
                  <Button onClick={handleShowDeleteConfirmation} variant="danger" style={{ marginRight: '5px' }}>
                    Delete
                  </Button>
                  <Button onClick={handleUpdateAlias} variant="info">
                    Save
                  </Button>
                </Box>
              ) : (
                <Button onClick={handleAddAlias} color="info">
                  Add
                </Button>
              )}
            </DialogActions>
          </Dialog>
          <Dialog
            open={showDeleteConfirmation}
            onClose={() => setShowDeleteConfirmation(false)}
          >
            <DialogTitle>Confirm Deletion</DialogTitle>
            <DialogContent>
              Are you sure you want to delete this alias?
            </DialogContent>
            <DialogActions>
              <Button
                variant="secondary"
                onClick={() => setShowDeleteConfirmation(false)}
              >
                Cancel
              </Button>
              <Button
                onClick={() => {
                  handleDeleteAlias();
                }}
                variant="danger"
              >
                Delete
              </Button>
            </DialogActions>
          </Dialog>
        </Typography>
      </Box>
      <hr />
      <Box style={{ height: '400px', marginBottom: '70px' }}>
        <Typography variant="h4">Upcoming Reservations</Typography>
        <DataGrid
          columns={columns}
          rows={upcomingReservations}
          pageSize={20}
          onCellEditCommit={(params) => {
            handleDateCellEdit(
              params,
              upcomingReservations,
            );
          }}
        />
      </Box>
      <Box style={{ height: '400px', marginBottom: '70px' }}>
        <Typography variant="h4">Current Reservations</Typography>
        <DataGrid
          columns={columns}
          rows={currentReservations}
          pageSize={20}
          onCellEditCommit={(params) => {
            handleDateCellEdit(
              params,
              currentReservations,
            );
          }}
        />
      </Box>
      <Box style={{ height: '400px', marginBottom: '70px' }}>
        <Typography variant="h4">Past Reservations</Typography>
        <DataGrid
          columns={columns}
          rows={pastReservations}
          pageSize={20}
          onCellEditCommit={(params) => {
            handleDateCellEdit(
              params,
              pastReservations,
            );
          }}
        />
      </Box>
    </Box>
  );
};

export default EditProperty;
