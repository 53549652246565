/* eslint-disable */
import React from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import Login from './containers/login/Login';
import Dashboard from './containers/dashboard/Dashboard';
import Home from './containers/home/Home';
import UnauthenticatedRoute from './components/routes/UnauthenticatedRoute';
import AuthenticatedRoute from './components/routes/AuthenticatedRoute';
import HomeSidebar from './containers/header/HomeSidebar';
import Inventory from './containers/inventory/Inventory';
import {APPS} from './constants';
import Admin from './containers/admin/Admin';
import PropertyManagement from './containers/properties/PropertyManagement';
import ReservationManagement from './containers/reservations/ReservationManagement';
import ScannerBase from './containers/scanner/ScannerBase';
import ScannerPrint from './containers/scanner/ScannerPrint';
import Search from './containers/search/Search';
import Settings from './containers/settings/Settings';
import EventManagement from './containers/events/EventManagement';
import ReportsManagement from './containers/reports/ReportsManagement';
import SuperAdmin from './containers/superAdmin/SuperAdmin';
import Fulfillment from './containers/fulfillment/Fullfillment';


const UnauthenticatedRoutes = {
  home: '/home',
};

const Routes = ({ childProps }) => (
  <Switch>
    <UnauthenticatedRoute path="/login" exact component={Login} props={childProps} />
    <AuthenticatedRoute path="/home" exact component={Home} props={childProps} />
    <UnauthenticatedRoute path="/dashboard" exact component={HomeRoute} props={{ component: <Dashboard />, ...childProps }} />

    <AuthenticatedRoute path="/print/scanner" component={ScannerPrint} props={childProps} />
    <AuthenticatedRoute path="/search" component={Search} props={childProps} />
    <AuthenticatedRoute path="/settings" component={Settings} props={childProps} />
    
    <AuthenticatedRoute path={`/${APPS.INVENTORY.baseRoute}`} component={Inventory} props={childProps} />

    <AuthenticatedRoute path={`/${APPS.RESERVATIONS.baseRoute}`} component={ReservationManagement} props={childProps} />

    <AuthenticatedRoute path={`/${APPS.FULFILLMENT.baseRoute}`} component={Fulfillment} props={childProps} />

    <AuthenticatedRoute path={`/${APPS.EVENTS.baseRoute}`} component={EventManagement} props={childProps} />

    <AuthenticatedRoute path={`/${APPS.PROPERTIES.baseRoute}`} component={PropertyManagement} props={childProps} />

    <AuthenticatedRoute path={`/${APPS.REPORTS.baseRoute}`} component={ReportsManagement} props={childProps} />

    <AuthenticatedRoute path={`/${APPS.SCANNER.baseRoute}`} component={ScannerBase} props={childProps} />

    <AuthenticatedRoute path={`/${APPS.ADMIN.baseRoute}`} component={Admin} props={childProps} />

    <AuthenticatedRoute path={`/${APPS.SUPER_ADMIN.baseRoute}`} component={SuperAdmin} props={childProps} />

    <Redirect to="/login" />
  </Switch>
);

const HomeRoute = ({ component }) => (
  <Row className="flex-xl-nowrap">
    <Col xl="2" md="3" className="pt-5 sidebar-wrapper flex-column d-flex">
      <HomeSidebar />
    </Col>
    <Col md="10" className="pt-3">
      {component}
    </Col>
  </Row>
);

Routes.propTypes = {
  childProps: PropTypes.object,
};

export { UnauthenticatedRoutes };
export default Routes;
/* eslint-enable */
