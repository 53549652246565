import { Box, Button, CircularProgress } from '@mui/material';
import {
  Elements, PaymentElement, useElements, useStripe,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React, { useState } from 'react';
import config from '../../../config';
import RequestService from '../../../services/RequestService';

const AddPaymentMethod = ({
  handleSuccess, buttonText = 'Submit', onCancel = null, customer,
}) => {
  const [stripePublicKey, setStripePublicKey] = useState(null);
  const [clientSecret, setClientSecret] = useState(null);

  const fetchClientSecret = () => {
    RequestService.get(`${config.backendUrl}/payment_methods/${customer.id}/new`, (response) => {
      setClientSecret(response.data.clientSecret);
    });
  };

  const getOptions = () => ({
    clientSecret,
  });

  React.useEffect(() => {
    if (!stripePublicKey) {
      RequestService.get(`${config.backendUrl}/payment_methods/stripe_public_key`, (response) => {
        setStripePublicKey(response.data.stripePublicKey);
        if (!clientSecret) fetchClientSecret();
      });
    }
  });

  const stripePromise = loadStripe(stripePublicKey);

  const CheckoutForm = () => {
    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async (event) => {
      // We don't want to let default form submission happen here,
      // which would refresh the page.
      event.preventDefault();

      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }

      const result = await stripe.confirmSetup({
        elements,
        confirmParams: {
          return_url: 'https://google.com',
        },
        redirect: 'if_required',
      });

      if (!result.error && handleSuccess) handleSuccess();
    };

    return (
      <form onSubmit={(e) => { handleSubmit(e); }}>
        <Box mb="2rem">
          <PaymentElement />
        </Box>
        <Button type="submit" variant="contained" disabled={!stripe}>{buttonText}</Button>
        {onCancel && <Button onClick={onCancel}>Cancel</Button>}
      </form>
    );
  };

  if (!clientSecret || !stripePublicKey) return <CircularProgress />;

  return (
    <Box>
      <Elements stripe={stripePromise} options={getOptions()}>
        {clientSecret && <CheckoutForm />}
      </Elements>
    </Box>
  );
};

export default AddPaymentMethod;
