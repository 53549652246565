import React from 'react';
import { withRouter } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Box, Container } from '@mui/material';
import Routes from './Routes';
import './App.css';
import BaseStore from './stores/BaseStore';
import AuthStore from './stores/AuthStore';
import HealthStore from './stores/HealthStore';
import EmployeeStore from './stores/EmployeeStore';
import { getCurrentApp } from './utils';
import ItemCategoryStore from './stores/ItemCategoryStore';
import ItemStore from './stores/ItemStore';
import KitStore from './stores/KitStore';
import CustomerStore from './stores/CustomerStore';
import PropertyStore from './stores/PropertyStore';
import ClientStore from './stores/ClientStore';
import BagStore from './stores/BagStore';
import PropertyItemStore from './stores/PropertyItemStore';
import BagItemStore from './stores/BagItemStore';
import ScannerStore from './stores/ScannerStore';
import ReservationStore from './stores/ReservationStore';
import LeaderBoardStore from './stores/LeaderBoardStore';
import SearchStore from './stores/SearchStore';
import StoragesStore from './stores/StoragesStore';
import HeaderSidebar, { DrawerHeader } from './containers/headerSidebar/HeaderSidebar';
import EventCategoryStore from './stores/EventCategoryStore';
import AlertStore from './stores/AlertStore';
import WushAlert from './components/WushAlert';
import Footer from './containers/footer/Footer';
import LocationZoneStore from './stores/LocationZoneStore';

const App = () => {
  const currentApp = getCurrentApp();

  const theme = createTheme({
    palette: {
      primary: {
        light: currentApp.color || '#00b4d2',
        main: currentApp.color || '#00b4d2',
        dark: '#b18610',
        contrastText: '#fff',
      },
      secondary: {
        light: '#ff7961',
        main: '#f44336',
        dark: '#ba000d',
        contrastText: '#000',
      },
      background: {
        paper: '#ffffff',
      },
    },
  });

  const childProps = {
    user: 'Nik',
    baseStore: new BaseStore(),
    authStore: new AuthStore(),
    healthStore: new HealthStore(),
    employeeStore: new EmployeeStore(),
    itemCategoryStore: new ItemCategoryStore(),
    itemStore: new ItemStore(),
    kitStore: new KitStore(),
    customerStore: new CustomerStore(),
    propertyStore: new PropertyStore(),
    clientStore: new ClientStore(),
    bagStore: new BagStore(),
    bagItemStore: new BagItemStore(),
    propertyItemStore: new PropertyItemStore(),
    scannerStore: new ScannerStore(),
    reservationStore: new ReservationStore(),
    leaderBoardStore: new LeaderBoardStore(),
    searchStore: new SearchStore(),
    storagesStore: new StoragesStore(),
    eventCategoryStore: new EventCategoryStore(),
    locationZoneStore: new LocationZoneStore(),
    alertStore: new AlertStore(),
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <HeaderSidebar childProps={childProps} />
        <Container component="main">
          <WushAlert alertStore={childProps.alertStore} />
          <DrawerHeader />
          <Box sx={{ background: '#ffffff', borderRadius: '25px' }}>
            <Routes childProps={childProps} />
          </Box>
        </Container>
        <Footer />
      </div>
    </ThemeProvider>
  );
};

export default withRouter(App);
