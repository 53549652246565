import React, { useState } from 'react';
import { useRouteMatch } from 'react-router';
import ReservationOverlay from './ReservationOverlay';
import config from '../../../../config';
import Loader from '../../../../components/loader/Loader';
import RequestService from '../../../../services/RequestService';

const ViewReservation = () => {
  const [reservation, setReservation] = useState([]);

  const match = useRouteMatch();
  const { reservationId } = match.params;

  // .get reservations/reservationId
  if (reservation.length === 0) {
    RequestService.get(`${config.backendUrl}/reservations/${reservationId}`, (response) => {
    //   console.log(response.data);
      setReservation(response.data);
    });
  }
  if (reservation.length === 0) return <Loader />;

  return (
    <div>
      <ReservationOverlay reservation={reservation} />
    </div>
  );
};

export default ViewReservation;
