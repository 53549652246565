import React from 'react';
import DomainIcon from '@mui/icons-material/Domain';
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation';
import StoreIcon from '@mui/icons-material/Store';
import CropDinIcon from '@mui/icons-material/CropDin';
import Clients from './containers/clients/Clients';
import Properties from './containers/properties/Properties';
import StorageFacilities from './containers/storageFacilities/StorageFacilities';
import LocationZones from './containers/locationZones/LocationZones';
import PropertiesDeliveryOrder from './containers/properties/PropertiesDeliveryOrder';

export const PAGES = {
  PROPERTIES: {
    title: "Properties",
    baseRoute: "properties",
    icon: <DomainIcon />,
    component: Properties
  },
  DELIVERY_ORDER: {
    title: "Delivery Order",
    baseRoute: "delivery-order",
    icon: <DomainIcon />,
    component: PropertiesDeliveryOrder
  },
  CLIENTS: {
    title: "Clients",
    baseRoute: "clients",
    icon: <TransferWithinAStationIcon />,
    component: Clients
  },
  STORAGE: {
    title: "Storage Facilities",
    baseRoute: "storage-facilities",
    icon: <StoreIcon />,
    component: StorageFacilities
  }
  ,
  ZONES: {
    title: "Location Zones",
    baseRoute: "location-zones",
    icon: <CropDinIcon />,
    component: LocationZones
  }
}
