import {
  CircularProgress, Typography, Breadcrumbs, Link, Box, Snackbar, Alert,
} from '@mui/material';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import PropTypes from 'prop-types';
import ScannerTicket from './components/ScannerTicket';
import RequestService from '../../services/RequestService';
import config from '../../config';

const queryString = require('query-string');

const FAILURE_WORDS = ['Incorrect scan', 'Try again'];

const ScanReservation = ({ scannerStore, location }) => {
  const match = useRouteMatch();
  const { reservationId } = match.params;

  const parsed = queryString.parse(location.search);
  const selectedTicketId = parsed.ticketId;

  const history = useHistory();

  const [activeScannerTicket, setActiveScannerTicket] = useState(null);
  const [scannerTicketItemId, setScannerTicketItemId] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  React.useEffect(() => {
    let interval = -1;
    interval = setInterval(() => {
      scannerStore.getReservation(reservationId, true);
    }, 3000);
    return () => {
      clearInterval(interval);
    };
  });

  scannerStore.getReservation(reservationId);
  if (!scannerStore.selectedReservation && !scannerStore.selectedReservationError) {
    return <CircularProgress />;
  }

  if (scannerStore.selectedReservationError) {
    return (
      <Box>
        <Typography variant="h4">
          Reservation not found!
        </Typography>
      </Box>
    );
  }

  if (!activeScannerTicket) {
    // eslint-disable-next-line
    const activeTicket = scannerStore.selectedReservation.scannerTickets.filter((ticket) => parseInt(ticket.id) === parseInt(selectedTicketId))[0];
    if (activeTicket) setActiveScannerTicket(activeTicket);
  }

  const speaker = new SpeechSynthesisUtterance();
  const voice = window.speechSynthesis.getVoices().filter(
    (speechVoice) => speechVoice.name === scannerStore.currentVoice,
  )[0];
  speaker.voice = voice;

  const speakMessage = (message) => {
    speaker.text = message;
    window.speechSynthesis.speak(speaker);
  };

  const scanItem = (forcedTicketId = null, forcedTicketItemId = null) => {
    scannerStore.scanItem(
      forcedTicketItemId || scannerTicketItemId, forcedTicketId || activeScannerTicket.id,
      () => setScannerTicketItemId(''),
      (scannedTicketItem) => {
        setSnackbarOpen(true);
        speakMessage(`Scan complete for ${scannedTicketItem.itemName}`);
      },
      () => {
        speakMessage('Bag scan complete!');
      },
      () => {
        speakMessage('Reservation scan complete!');
        history.push(scannerStore.selectedReservation.isServiceTicket ? '/scanner/service-tickets' : '/scanner');
      },
      // eslint-disable-next-line
      () => speakMessage(FAILURE_WORDS[Math.floor(Math.random() * FAILURE_WORDS.length)]),
    );
  };

  const checkForSubmit = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      if (scannerTicketItemId.substring(0, 7) === 'scanner') {
        RequestService.get(`${config.backendUrl}/scanner/${activeScannerTicket.id}/status`, (response) => {
          if (response.data.status === 'complete') {
            const splitString = scannerTicketItemId.split('-');
            window.location = `/scanner/${splitString[1]}?ticketId=${splitString[2]}`;
          } else {
            speakMessage('Scanner ticket not yet complete!');
            setScannerTicketItemId('');
          }
        });
      } else {
        scanItem();
      }
    }
  };

  return (
    <Box pt={2}>
      <Breadcrumbs aria-label="breadcrumb" mb={2}>
        <Link underline="hover" color="inherit" href="/scanner">
          Scanner
        </Link>
        <Typography color="text.primary">
          {scannerStore.selectedReservation.propertyName}
          {' '}
          on
          {' '}
          {scannerStore.selectedReservation.startDate}
        </Typography>
      </Breadcrumbs>
      <Box mb={2} mt={1}>
        <Typography variant="h3">
          {scannerStore.selectedReservation.propertyName}
        </Typography>
        <Typography variant="h5">
          {`${scannerStore.selectedReservation.startDate} to ${scannerStore.selectedReservation.endDate}`}
        </Typography>
      </Box>

      {scannerStore.selectedReservation.scannerTickets.map((scannerTicket) => (
        <ScannerTicket
          isServiceTicket={scannerStore.selectedReservation.isServiceTicket}
          scannerTicket={scannerTicket}
          active={activeScannerTicket?.id === scannerTicket.id}
          setActiveScannerTicket={setActiveScannerTicket}
          scannerTicketItemId={scannerTicketItemId}
          setScannerTicketItemId={setScannerTicketItemId}
          checkForSubmit={checkForSubmit}
          scanItem={(ticketId, ticketItemId) => scanItem(ticketId, ticketItemId)}
        />
      ))}
      <Typography variant="h5">Comments:</Typography>
      {scannerStore.selectedReservation.comments.length > 0
        && scannerStore.selectedReservation.comments.map((comment) => (
          <Box mb={2}>
            {comment.text}
            {' '}
            -
            <i>{comment.employeeName}</i>
          </Box>
        ))}
      <Snackbar open={snackbarOpen} autoHideDuration={2000} onClose={() => setSnackbarOpen(false)}>
        <Alert severity="success" sx={{ width: '100%' }}>
          Item complete!
        </Alert>
      </Snackbar>
    </Box>
  );
};

ScanReservation.propTypes = {
  scannerStore: PropTypes.any,
  location: PropTypes.object,
};

export default observer(ScanReservation);
