import {
  Box, Button, LinearProgress, Typography, Stack, Dialog, Divider,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import { DataGrid } from '@material-ui/data-grid';
import { observer } from 'mobx-react';

const useStyles = makeStyles(() => ({
  categoriesWrapper: {
    paddingTop: '2rem',
  },
  categoriesTableContainer: {
    height: '500px',
  },
  buttonContainer: {
    marginBottom: '1rem',
  },
}));

const EventCategories = ({ eventCategoryStore }) => {
  const [showIframeModal, setShowIframModal] = useState(false);
  const [activeEventCategoryId, setActiveEventCategoryId] = useState({});
  const classes = useStyles();

  eventCategoryStore.getEventCategories();

  if (!eventCategoryStore.eventCategoriesPreLoaded) return (<LinearProgress />);

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      width: 150,
      editable: true,
    },
    {
      field: 'description',
      headerName: 'Description',
      width: 500,
      editable: true,
    },
    {
      field: 'Actions',
      headerName: '',
      width: 200,
      renderCell: (params) => (
        <Stack direction="row" spacing={2}>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => eventCategoryStore.deleteEventCategory(params.id)}
          >
            Delete
          </Button>
          <Button onClick={() => {
            setShowIframModal(true);
            setActiveEventCategoryId(params.id);
          }}
          >
            Iframe
          </Button>
        </Stack>

      ),
      editable: false,
      sortable: false,
    },
  ];

  const categoriesRows = eventCategoryStore.eventCategories.map((eventCategory) => (
    {
      id: eventCategory.id,
      name: eventCategory.name,
      description: eventCategory.description,
    }
  ));

  const renderEventCategoryIframe = (eventCategoryId) => `<iframe 
      src="https://wush.life/iframe/public-event-categories/${eventCategoryId}" 
      height="500px" width="500px" 
      style="border: 1px solid lightgrey; background-color: white">`;

  return (
    <Box py={2}>
      <Typography variant="h3">Event Categories</Typography>
      <Box className={classes.buttonContainer}>
        <Button
          variant="contained"
          color="primary"
          onClick={eventCategoryStore.addEventCategory}
        >
          Add Event Category
        </Button>
      </Box>
      <Box className={classes.categoriesTableContainer}>
        <DataGrid
          rows={categoriesRows}
          columns={columns}
          pageSize={10}
          disableSelectionOnClick
          onCellEditCommit={eventCategoryStore.updateEventCategory}
        />
      </Box>
      <Dialog
        open={showIframeModal}
        onClose={() => setShowIframModal(false)}
      >
        <Box p={3}>
          <Typography>
            Paste the following HTML on your site where you want the
            {' '}
            Event Category calendar to be displayed
          </Typography>
          <Box my={2}>
            <Divider />
          </Box>

          <Typography>
            {renderEventCategoryIframe(activeEventCategoryId)}
          </Typography>
        </Box>
      </Dialog>
    </Box>
  );
};

export default observer(EventCategories);
