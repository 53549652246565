import {
  Button, Checkbox, LinearProgress, Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react';
import React from 'react';
import PropTypes from 'prop-types';
import { DataGrid } from '@material-ui/data-grid';

const useStyles = makeStyles(() => ({
  clientsWrapper: {
    paddingTop: '2rem',
    paddingBottom: '2rem',
  },
  clientsTableContainer: {
    height: '500px',
  },
  buttonContainer: {
    marginBottom: '1rem',
  },
}));

const Clients = ({ clientStore }) => {
  const classes = useStyles();
  clientStore.getClients();

  if (!clientStore.clientsPreLoaded) return (<LinearProgress />);

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      width: 150,
      editable: true,
    },
    {
      field: 'address1',
      headerName: 'Address 1',
      width: 250,
      editable: true,
    },
    {
      field: 'address2',
      headerName: 'Address 2',
      width: 250,
      editable: true,
    },
    {
      field: 'state',
      headerName: 'State',
      width: 150,
      editable: true,
    },
    {
      field: 'city',
      headerName: 'City',
      width: 150,
      editable: true,
    },
    {
      field: 'zip',
      headerName: 'Zip Code',
      width: 150,
      editable: true,
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 150,
      editable: true,
    },
    {
      field: 'phone',
      headerName: 'Phone Number',
      width: 150,
      editable: true,
    },

    {
      field: 'taxExempt',
      headerName: 'Tax Exempt',
      width: 100,
      editable: false,
      renderCell: (params) => (
        <Checkbox
          // eslint-disable-next-line
          checked={params.value}
          onChange={(_e, value) => clientStore.updateClient({ field: 'taxExempt', value, id: params.id })}
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />
      ),
    },
    {
      field: 'Actions',
      headerName: '',
      width: 100,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="secondary"
          onClick={() => clientStore.deleteClient(params.id)}
        >
          Delete
        </Button>
      ),
      editable: false,
      sortable: false,
    },
  ];
  const clientRows = clientStore.clients.map((client) => (
    {
      id: client.id,
      name: client.name,
      address1: client.address1,
      address2: client.address2,
      city: client.city,
      state: client.state,
      zip: client.zip,
      email: client.email,
      phone: client.phone,
      taxExempt: client.taxExempt,
    }
  ));

  return (
    <div className={classes.clientsWrapper}>
      <Typography variant="h3">Clients</Typography>
      <div className={classes.buttonContainer}>
        <Button
          variant="contained"
          color="primary"
          onClick={clientStore.addClient}
        >
          Add Client
        </Button>
      </div>
      <div className={classes.clientsTableContainer}>
        <DataGrid
          rows={clientRows}
          columns={columns}
          pageSize={10}
          disableSelectionOnClick
          onCellEditCommit={clientStore.updateClient}
        />
      </div>
    </div>
  );
};

Clients.propTypes = {
  clientStore: PropTypes.any,
};

export default observer(Clients);
