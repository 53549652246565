import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router';
import { Container } from '@mui/material';
import Deliveries from './containers/deliveries/Deliveries';
import DeliveryRoutes from './containers/deliveries/DeliveryRoutes';
import PickupRoutes from './containers/pickups/PickupRoutes';
import Pickups from './containers/pickups/Pickups';
import Pickup from './containers/pickups/Pickup';
import ViewReservation from './containers/reservations/ViewReservation';
import ViewDeliveryRoute from './containers/deliveries/ViewDeliveryRoute';
import DeliveryRouteInProgress from './containers/deliveries/DeliveryRouteInProgress';
import ViewDelivery from './containers/deliveries/ViewDelivery';

const Fulfillment = (props) => {
  const match = useRouteMatch();
  return (
    <Container>
      <Switch>
        <Route path={`${match.path}/delivery-routes/in-progress`}>
          <DeliveryRouteInProgress {...props} />
        </Route>
        <Route path={`${match.path}/delivery-routes/:deliveryId/print`}>
          <ViewDeliveryRoute {...props} printView />
        </Route>
        <Route path={`${match.path}/delivery-routes/:deliveryId`}>
          <ViewDeliveryRoute {...props} />
        </Route>
        <Route path={`${match.path}/delivery-routes`}>
          <DeliveryRoutes {...props} />
        </Route>
        <Route path={`${match.path}/deliveries/:deliveryId`}>
          <ViewDelivery {...props} />
        </Route>
        <Route path={`${match.path}/deliveries`}>
          <Deliveries {...props} />
        </Route>
        <Route path={`${match.path}/pickup-routes/:pickupId`}>
          <Pickup {...props} />
        </Route>
        <Route path={`${match.path}/pickup-routes`}>
          <PickupRoutes {...props} />
        </Route>
        <Route path={`${match.path}/pickups`}>
          <Pickups {...props} />
        </Route>
        <Route path={`${match.path}/reservation-overlay/:reservationId`}>
          <ViewReservation {...props} />
        </Route>
      </Switch>
    </Container>
  );
};

export default Fulfillment;
