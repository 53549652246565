import {
  Box, Breadcrumbs, Button, CircularProgress, Divider, Link, Stack, Typography,
} from '@mui/material';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import config from '../../../config';
import RequestService from '../../../services/RequestService';
import EventSeriesForm from '../components/EventSeriesForm';
import ImageUploader from '../components/ImageUploader';
import WushCalendar from '../components/WushCalendar';
import { mapWushEventToCalendar } from '../services';

const ViewEventSeries = ({ eventCategoryStore, alertStore }) => {
  const [eventSeries, setEventSeries] = useState(null);
  const match = useRouteMatch();
  const history = useHistory();
  const { eventSeriesId } = match.params;

  const fetchEvent = () => {
    RequestService.get(`${config.backendUrl}/event_series/${eventSeriesId}`, (response) => {
      response.data.cost = response.data.costCents / 100;
      setEventSeries(response.data);
    });
  };

  const deleteEvent = () => {
    RequestService.delete(`${config.backendUrl}/event_series/${eventSeriesId}`, () => {
      history.push('/event-management/events');
    });
  };

  if (!eventSeries) {
    fetchEvent();
    return <CircularProgress />;
  }

  const handleSubmit = (values) => {
    const body = values;
    delete body.events;
    RequestService.patch(`${config.backendUrl}/event_series/${eventSeries.id}`, { eventSeries: body }, () => {
      alertStore.setAlertMessage('Successfully updated event series!');
    });
  };

  return (
    <Box pt={2}>
      <Breadcrumbs aria-label="breadcrumb" mb={2}>
        <Link underline="hover" color="inherit" href="/event-management/event-series">
          Event Series
        </Link>
        <Typography color="text.primary">
          {eventSeries.name}
        </Typography>
      </Breadcrumbs>
      <Typography variant="h3">{eventSeries.name}</Typography>
      <Box mb={2}>
        <Typography variant="body1">
          <strong>Registered in Series:</strong>
          {' '}
          {eventSeries.registrations.length}
        </Typography>
      </Box>
      <Stack direction="row" spacing={2} divider={<Divider orientation="vertical" flexItem />}>
        <Button variant="contained" onClick={() => history.push(`/event-management/event-series/${eventSeries.id}/registrations`)}>View Registrations</Button>
        <Button onClick={deleteEvent} variant="contained" color="secondary" disabled={eventSeries.registrations.length > 0}>Delete</Button>
      </Stack>
      <Box pt={2} mb={4}>
        <Formik
          initialValues={eventSeries}
          onSubmit={handleSubmit}
        >
          {
            (formik) => (
              <EventSeriesForm
                formik={formik}
                eventCategoryStore={eventCategoryStore}
                showEvents={false}
                update
              />
            )
          }
        </Formik>
      </Box>
      <Box mb={3}>
        <ImageUploader
          endpoint={`${config.backendUrl}/event_series/${eventSeries.id}`}
          imageUrl={eventSeries.imageUrl}
          onSuccess={() => {
            fetchEvent();
            alertStore.setAlertMessage('Successfully uploaded image!');
          }}
        />
      </Box>
      <Box>
        <Typography variant="h5">Event Schedule (Read only)</Typography>
        <WushCalendar
          defaultView="month"
          events={eventSeries.events.map((event) => mapWushEventToCalendar(event))}
        />
      </Box>
    </Box>
  );
};

export default ViewEventSeries;
