import { Box, CircularProgress, Link } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { format } from 'date-fns';
import WushTable from '../../../components/WushTable';
import config from '../../../config';
import RequestService from '../../../services/RequestService';

const CustomerRegistrations = (customer) => {
  const [registrations, setRegistrations] = useState(null);

  const fetchCustomerRegistrations = () => {
    RequestService.get(`${config.backendUrl}/customers/${customer.id}/registrations`, (response) => {
      setRegistrations(response.data);
    });
  };

  useEffect(() => {
    fetchCustomerRegistrations();
  }, []);

  if (!registrations) return <CircularProgress />;

  return (
    <Box>
      <WushTable
        headers={[
          { name: 'name', label: 'Event', customGetter: ({ id, name }) => <Link href={`/event-management/events/${id}`}>{name}</Link> },
          { name: 'startDatetime', label: 'Date', customGetter: ({ startDatetime }) => format(new Date(startDatetime), 'MM/dd/yy HH:mm aaaa') },
        ]}
        data={registrations}
      />
    </Box>
  );
};

export default CustomerRegistrations;
