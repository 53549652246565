import {
  Box, Button, Fade, Grid, Modal, Typography, IconButton,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import React, { useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import { DataGrid } from '@material-ui/data-grid';
import { format } from 'date-fns';
import Loader from '../../../../components/loader/Loader';
import ItemTransferForm from './components/ItemTransferForm';
import RequestService from '../../../../services/RequestService';
import config from '../../../../config';

const getModalStyle = () => {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    padding: '1rem',
    width: '35%',
  };
};

const useStyles = makeStyles((theme) => ({
  modalPaper: {
    position: 'absolute',
    width: '75%',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[10],
    padding: theme.spacing(2, 4, 3),
    textAlign: 'center',
  },
}));

const ViewItem = ({ itemStore, propertyStore, storagesStore }) => {
  const match = useRouteMatch();
  const { itemId } = match.params;
  const [showTransferModal, setShowTransferModal] = useState(false);
  const classes = useStyles();
  const history = useHistory();

  propertyStore.getProperties();
  storagesStore.getStorages();

  if (!propertyStore.propertiesPreLoaded || !storagesStore.storagesPreLoaded) return <Loader />;

  itemStore.getItem(itemId);
  const modalStyle = getModalStyle();

  const makeTransfer = ({ startLocationId, endLocationId, quantity }) => {
    itemStore.makeTransfer(itemId, startLocationId, endLocationId, quantity, () => {
      setShowTransferModal(false);
    });
  };

  const handleImageUpdate = ({ image }) => {
    const formData = new FormData();
    formData.append('image', image);
    RequestService.post(`${config.backendUrl}/items/${itemId}`, formData, () => {
      itemStore.getItem(itemId, true);
    });
  };

  if (!itemStore.selectedItemPreLoaded) return <Loader />;

  const itemRows = itemStore.selectedItem.itemVariations.map((item) => ({
    id: item.id,
    itemCategoryId: item.itemCategoryId,
    name: item.name,
    description: item.description,
    cost: item.cost,
    halfDayRate: item.halfDayRate,
    fullDayRate: item.fullDayRate,
    weekRate: item.weekRate,
    retailSalesPrice: item.retailSalesPrice,
    usedSalesPrice: item.usedSalesPrice,
    taxRate: item.taxRate,
    createdAt: item.createdAt && format(new Date(item.createdAt), 'PP'),
    weight: item.weight,
    legacyScanId: item.legacyScanId,
  }));

  const columns = [
    {
      field: 'item',
      headerName: '',
      width: 50,
      renderCell: (params) => (
        <IconButton
          size="small"
          onClick={() => {
            history.push(`/inventory-management/items/${params.id}`);
          }}
        >
          <EditOutlinedIcon />
        </IconButton>
      ),
      editable: false,
      sortable: false,
    },
    {
      field: 'name',
      headerName: 'Name',
      width: 150,
      editable: true,
    },
    {
      field: 'description',
      headerName: 'Description',
      width: 150,
      editable: true,
    },
    {
      field: 'cost',
      headerName: 'Cost',
      width: 150,
      editable: true,
    },
    {
      field: 'weight',
      headerName: 'Weight',
      width: 150,
      editable: true,
    },
    {
      field: 'legacyScanId',
      headerName: 'Scan ID',
      width: 150,
      editable: true,
    },
    {
      field: 'halfDayRate',
      headerName: 'Half Day',
      width: 150,
      editable: true,
      type: 'number',
    },
    {
      field: 'fullDayRate',
      headerName: 'Full Day',
      width: 200,
      editable: true,
      type: 'number',
    },
    {
      field: 'weekRate',
      headerName: 'Week',
      width: 200,
      editable: true,
      type: 'number',
    },
    {
      field: 'taxRate',
      headerName: 'Tax Rate',
      width: 200,
      editable: true,
    },
    {
      field: 'createdAt',
      headerName: 'Created At',
      width: 200,
      editable: false,
      type: 'date',
    },
    {
      field: 'Actions',
      headerName: '',
      width: 100,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="secondary"
          onClick={() => itemStore.deleteItem(params.id)}
        >
          Delete
        </Button>
      ),
      editable: false,
      sortable: false,
    },
  ];

  return (
    <Box>
      <Typography variant="h3">
        {itemStore.selectedItem.name}
        {' '}
        {itemStore.selectedItem?.parent?.name}
      </Typography>
      <Box mb={5}>
        <Box mb={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              itemStore.addItem(itemStore.selectedItem.id, () => itemStore.getItem(itemId, true));
            }}
          >
            Add Item
          </Button>
        </Box>
        <Box height="500px">
          <DataGrid
            rows={itemRows}
            columns={columns}
            pageSize={10}
            disableSelectionOnClick
            onCellEditCommit={itemStore.updateItem}
          />
        </Box>
      </Box>
      <Box>
        {itemStore.selectedItem.itemQuantities.map((itemQuantity) => (
          <Grid row="true" key={itemQuantity.id}>
            {itemQuantity.location.name}
            :
            {itemQuantity.quantity}
          </Grid>
        ))}
      </Box>
      <Box mb={5}>
        <Grid row>
          <Button
            variant="contained"
            onClick={() => setShowTransferModal(true)}
          >
            Item Transfer
          </Button>
        </Grid>
      </Box>
      <Box>
        <Typography variant="h5">Image attachment</Typography>
        <Formik
          initialValues={{
            image: '',
          }}
          onSubmit={handleImageUpdate}
        >
          {
            (formikProps) => (
              <Form>
                <input type="file" onChange={(event) => formikProps.setFieldValue('image', event.currentTarget.files[0])} />
                <Button onClick={formikProps.handleSubmit}>Save</Button>
              </Form>
            )
          }
        </Formik>
        <img width="200" src={itemStore.selectedItem.imageUrl} alt="Item" />
      </Box>
      <Box>
        <Typography variant="h5">Property Requirement Information</Typography>
      </Box>
      <Box>
        <b>
          Total Required:
          {itemStore.selectedItem.totalQuantityForProperties}
        </b>
      </Box>
      <Box>
        {
          itemStore.selectedItem.propertyItems.map((propertyItem) => (
            <Box>
              {`${propertyItem.propertyName}: ${propertyItem.quantity}`}
            </Box>
          ))
        }
      </Box>
      <Modal
        open={showTransferModal}
        onClose={() => setShowTransferModal(false)}
      >
        <Fade in={showTransferModal}>
          <div style={modalStyle} className={classes.modalPaper}>
            <ItemTransferForm
              properties={propertyStore.properties}
              makeTransfer={makeTransfer}
              storages={storagesStore.storages}
            />
          </div>
        </Fade>
      </Modal>
    </Box>
  );
};

ViewItem.propTypes = {
  itemStore: PropTypes.any,
  propertyStore: PropTypes.any,
  storagesStore: PropTypes.any,
};

export default observer(ViewItem);
