import React, { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router';
import config from '../../../../config';
import Loader from '../../../../components/loader/Loader';
import RequestService from '../../../../services/RequestService';
import DeliveryRoutePrintout from './DeliveryRoutePrintout';

const ViewDeliveryRoute = ({ printView }) => {
  const [deliveryRoute, setDeliveryRoute] = useState([]);

  useEffect(() => {
    if (printView) {
      setTimeout(() => {
        window.print();
      }, 1000);
    }
  }, [printView]);

  const match = useRouteMatch();
  const { deliveryId } = match.params;

  // .get reservations/reservationId
  if (deliveryRoute.length === 0) {
    RequestService.get(
      `${config.backendUrl}/delivery-routes/${deliveryId}`,
      (response) => {
        setDeliveryRoute(response.data);
      },
    );
  }
  if (deliveryRoute.length === 0) return <Loader />;

  return <DeliveryRoutePrintout deliveryRoute={deliveryRoute} />;
};

export default ViewDeliveryRoute;
