import React from 'react';
import {
  Box, Typography,
} from '@material-ui/core';
import RequestService from '../../../services/RequestService';
import config from '../../../config';
import EventTiles from './EventTiles';

const EventRegistrationReschedule = (props) => {
  const { currentEvent, availableEvents, handleRescheduleSuccess } = props;
  const handleReschedule = (newEventId) => {
    const baseUrl = `${config.backendUrl}/event_registrations`;
    RequestService.post(
      `${baseUrl}/${currentEvent.registrationId}/reschedule/${newEventId}`,
      {}, () => {
        if (handleRescheduleSuccess) handleRescheduleSuccess();
      },
    );
  };

  return (
    <Box p={2} pt={200}>
      <Box textAlign="center">
        <Typography variant="body1">
          <strong>Available Events</strong>
        </Typography>
      </Box>
      <EventTiles events={availableEvents} onClick={(event) => handleReschedule(event.id)} />
    </Box>
  );
};

export default EventRegistrationReschedule;
