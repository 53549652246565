import React, { useState } from 'react';
import {
  Box,
  Button,
  Menu,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography,
} from '@mui/material';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import Loader from '../loader/Loader';

const ZoneFilter = ({
  zoneFilter,
  handleZoneFilterCheck,
  locationZoneStore,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleZoneFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleZoneFilterClose = () => {
    setAnchorEl(null);
  };

  locationZoneStore.getLocationZones();

  if (!locationZoneStore.locationZones) {
    return <Loader />;
  }

  return (
    <Box>
      <Button
        className="filterButton"
        display="flex"
        id="zone-button"
        aria-controls={open ? 'zone-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleZoneFilterClick}
      >
        <PlaceOutlinedIcon />
        <Typography ml={1}>
          {`Select Zone${zoneFilter.length > 0 ? ` (${zoneFilter.length})` : ''}`}
        </Typography>
      </Button>
      <Menu
        id="zone-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleZoneFilterClose}
        MenuListProps={{
          'aria-labelledby': 'zone-button',
        }}
      >
        <FormGroup>
          {locationZoneStore.locationZones.map((itemZone) => (
            <FormControlLabel
              sx={{
                width: '200px',
                m: 0,
                p: 0,
              }}
              control={(
                <Checkbox
                  onChange={(e) => handleZoneFilterCheck(itemZone.name, e.target.checked)}
                  checked={zoneFilter.includes(itemZone.name)}
                />
              )}
              label={itemZone.name}
            />
          ))}
        </FormGroup>
      </Menu>
    </Box>
  );
};

export default ZoneFilter;
