import { Typography, Box } from '@mui/material';
import React from 'react';

import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import ListIcon from '@mui/icons-material/List';

const DeliveryRoutesSnippetCard = ({ deliveryRoute }) => {
  const deliveryArray = deliveryRoute.deliveries;
  let totalItems = 0;
  const allItemsArray = [];

  for (let i = 0; i < deliveryArray.length; i += 1) {
    const reservationItemsArray = deliveryArray[i].reservationItems;

    for (let j = 0; j < reservationItemsArray.length; j += 1) {
      const quantityOfItems = reservationItemsArray[j].reservedQuantity;
      totalItems += quantityOfItems;

      // push objects into allitemsarray
      allItemsArray.push(reservationItemsArray[j]);
    }
  }

  return (
    <Box className="delivery-route-snippet-card">
      <Box className="route-box">
        <Typography ml={1}>
          Route
          {deliveryRoute.id}
        </Typography>
      </Box>
      <Box className="stops-box">
        <PlaceOutlinedIcon />
        <Typography ml={1}>
          {deliveryRoute.deliveries.length}
          {' '}
          Stops
        </Typography>
      </Box>
      <Box className="items-box">
        <ListIcon className="list-icon" />
        <Typography ml={1}>
          {totalItems}
          {' '}
          Items
        </Typography>
      </Box>
    </Box>
  );
};

export default DeliveryRoutesSnippetCard;
