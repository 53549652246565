import React from 'react';
import { Container, Typography } from '@mui/material';
import { Route, Switch, useRouteMatch } from 'react-router';
import { APPS } from '../../constants';
import Property from './containers/properties/Property';
import EditProperty from './containers/properties/EditProperty';
import PropertiesDeliveryOrder from './containers/properties/PropertiesDeliveryOrder';

const PropertyManagement = (props) => {
  const match = useRouteMatch();
  return (
    <Container>
      <Switch>
        <Route path={`${match.path}/properties/edit/:propertyId`}>
          <EditProperty {...props} />
        </Route>
        <Route path={`${match.path}/properties/:propertyId`}>
          <Property {...props} />
        </Route>
        <Route path={`${match.path}/properties/delivery-order`}>
          <PropertiesDeliveryOrder {...props} />
        </Route>
        {Object.values(APPS.PROPERTIES.pages).map((page) => (
          <Route key={page.title} path={`${match.path}/${page.baseRoute}`}>
            <page.component {...props} />
          </Route>
        ))}
        <Route path={match.path}>
          <Typography variant="h3">Property Management</Typography>
        </Route>
      </Switch>
    </Container>
  );
};

export default PropertyManagement;
