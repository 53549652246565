import {
  Badge,
  Box, Button, CircularProgress, Container, Fade, Grid, Link, Modal, Paper, TextField,
  Tooltip, Typography, Autocomplete, Alert,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import MailIcon from '@mui/icons-material/Mail';
import React, { useState, useRef } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { matchSorter } from 'match-sorter';
import { useHistory } from 'react-router';
import AddIcon from '@mui/icons-material/Add';
import PrintIcon from '@mui/icons-material/Print';
import ReservationTable from './components/ReservationTable';
import ServiceTicketForm from './components/ServiceTicketForm';
import ScannerPrint from './ScannerPrint';
import { LinearProgressWithLabel } from './components/ScannerTicket';
import Loader from '../../components/loader/Loader';

const useStyles = makeStyles((theme) => ({
  paper: {
    height: 'auto',
    backgroundColor: '#bddad4',
  },
  modalPaper: {
    position: 'absolute',
    width: '75%',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[10],
    padding: theme.spacing(2, 4, 3),
    textAlign: 'center',
  },
  propertyDropdown: {
    width: '26rem',
  },
  form: {
    padding: '2rem',
    marginBottom: '2rem',
    borderRadius: '3px',
    border: '1px solid lightgrey',
  },
  printIcon: {
    marginRight: '0.5rem',
  },
  rightActions: {
    borderLeft: '1px solid lightgrey',
    paddingLeft: '2rem',
  },
  leftRightSpacing: {
    marginLeft: '1rem',
    marginRight: '1rem',
  },
  marginBottom: {
    marginBottom: '1rem',
  },
  stickySubHeader: {
    position: 'sticky',
  },
}));

const getModalStyle = () => {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    padding: '1rem',
    width: '65%',
  };
};

const getScannerTickets = (reservations, selectedReservations) => {
  const scannerTickets = [];
  reservations.forEach((reservation) => (
    selectedReservations.length === 0 || selectedReservations.includes(reservation.id)
  ) && (
    reservation.scannerTickets.forEach((ticket, index) => (
      scannerTickets.push({
        reservation,
        ticketIndex: index + 1,
        ...ticket,
      })
    ))
  ));
  return scannerTickets;
};

const Scanner = ({
  propertyStore, scannerStore, itemStore, serviceTickets, authStore,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [reservationStartDate, setReservationStartDate] = useState(format(new Date(), 'yyyy-MM-dd'));
  const [reservationEndDate, setReservationEndDate] = useState(format(new Date(), 'yyyy-MM-dd'));

  const [serviceTicketModalOpen, setServiceTicketModalOpen] = useState(false);
  const [navigateToString, setNavigateToString] = useState(null);

  const [modalStyle] = React.useState(getModalStyle);
  const propertyInputRef = useRef(null);
  const qrCodeRef = useRef(null);

  const filterOptions = (options, { inputValue }) => matchSorter(options, inputValue, { keys: ['name'] });

  itemStore.getItems();
  propertyStore.getProperties();
  if (!propertyStore.propertiesPreLoaded) return <Loader />;

  scannerStore.getReservations(false, serviceTickets);
  const scannerTickets = getScannerTickets(
    scannerStore.openReservations, scannerStore.selectedReservationRows,
  );
  if (!scannerStore.reservationsPreLoaded) return <Loader />;

  const checkForSubmit = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      const splitString = navigateToString.split('-');
      history.push(`/scanner/${splitString[1]}?ticketId=${splitString[2]}`);
    }
  };

  const selectRow = (id, value) => {
    scannerStore.selectRow(id, value);
  };

  return (
    <>
      <Container className="hide-print">
        <Box pt={2} className={classes.stickySubHeader}>
          <Grid container>
            {authStore.isTestOrganization && (
              <Grid item md={12}>
                <Alert severity="warning">
                  Test Organization: Please do not use real data
                </Alert>
              </Grid>
            )}
            <Grid item md={9}>
              <Typography variant="h3">
                Scanner
                {serviceTickets && ' (Service Tickets)'}
              </Typography>
            </Grid>
            <Grid item md={3}>
              <Box textAlign="right" pt={3}>
                {serviceTickets
                  ? <Link href="/scanner">Switch to Normal Tickets</Link>
                  : (

                    <Link href="/scanner/service-tickets">
                      {'Switch to Service Tickets '}
                      <Badge badgeContent={scannerStore.serviceTicketCount} color="secondary">
                        <MailIcon />
                      </Badge>
                    </Link>
                  )}
              </Box>
            </Grid>
          </Grid>
        </Box>
        <hr />
        {!serviceTickets && (
          <Box mt={4} mb={6}>
            <Box mb={1}>
              <Grid container>
                <Grid item md={6}>
                  <Typography variant="h5">
                    Create Tickets
                  </Typography>
                </Grid>
                <Grid item md={6}>
                  <Box textAlign="right">
                    <Typography variant="h5">
                      <Autocomplete
                        options={window.speechSynthesis.getVoices().map((voice) => voice.name)}
                        renderInput={(params) => <TextField variant="outlined" {...params} label="Voice" />}
                        getOptionLabel={(option) => option}
                        onChange={(_event, voice) => { scannerStore.setCurrentVoice(voice); }}
                        value={scannerStore.currentVoice}
                      />
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Paper className={classes.form}>
              <Grid container>
                <Grid item md={8}>
                  <Box mb={1}>
                    <Autocomplete
                      options={propertyStore.properties}
                      renderInput={(params) => <TextField variant="outlined" {...params} label="Property" inputRef={propertyInputRef} />}
                      getOptionLabel={(option) => option.name}
                      className={classes.propertyDropdown}
                      onChange={(_event, property) => setSelectedProperty(property)}
                      value={selectedProperty}
                      filterOptions={filterOptions}
                    />
                  </Box>
                  <Box mb={1}>
                    <TextField
                      type="date"
                      helperText="Reservation Start Date"
                      defaultValue={format(new Date(), 'yyyy-MM-dd')}
                      onChange={(event) => setReservationStartDate(event.target.value)}
                      style={{ marginRight: '2rem' }}
                      variant="outlined"
                    />
                    <TextField
                      type="date"
                      helperText="Reservation End Date"
                      defaultValue={format(new Date(), 'yyyy-MM-dd')}
                      onChange={(event) => setReservationEndDate(event.target.value)}
                      variant="outlined"
                    />
                  </Box>
                  <Box mb={1}>
                    {!serviceTickets && (
                      <Box>
                        <Tooltip title={!selectedProperty ? 'Please select a property' : ''}>
                          <span>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => {
                                scannerStore.generateTickets(
                                  selectedProperty.id,
                                  reservationStartDate,
                                  reservationEndDate,
                                );
                                setSelectedProperty(null);
                                propertyInputRef.current.focus();
                              }}
                              disabled={!selectedProperty || !reservationStartDate}
                            >
                              <div className={classes.printIcon}>
                                <AddIcon />
                              </div>
                              Add Tickets
                            </Button>
                          </span>
                        </Tooltip>
                        <Button className={classes.leftRightSpacing} variant="contained" onClick={() => window.print()}>
                          <div className={classes.printIcon}>
                            <PrintIcon />
                          </div>
                          Print Tickets
                        </Button>
                        <Button onClick={() => qrCodeRef.current.focus()}>
                          Scan Now
                        </Button>
                      </Box>
                    )}
                  </Box>
                </Grid>
                {scannerStore.reservationsPreLoaded ? (
                  <Grid item md={4} className={classes.rightActions}>
                    <Grid container>
                      <Grid item md={12} className={classes.marginBottom}>
                        <Link href="#openReservations">Open tickets</Link>
                        <LinearProgressWithLabel
                          currentValue={scannerStore.openReservationsCount}
                          totalValue={scannerStore.totalReservationsCount}
                        />
                      </Grid>
                      <Grid item md={12} className={classes.marginBottom}>
                        <Link href="#activeReservations">Active tickets</Link>
                        <LinearProgressWithLabel
                          currentValue={scannerStore.activeReservationsCount}
                          totalValue={scannerStore.totalReservationsCount}
                        />
                      </Grid>
                      <Grid item md={12} className={classes.marginBottom}>
                        <Link href="#completeReservations">Complete tickets</Link>
                        <LinearProgressWithLabel
                          currentValue={scannerStore.completeReservationsCount}
                          totalValue={scannerStore.totalReservationsCount}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                ) : <Loader />}
              </Grid>
            </Paper>
          </Box>
        )}
        {serviceTickets && (
          <Box mb={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setServiceTicketModalOpen(true)}
            >
              Create Service Ticket
            </Button>
            <Button className={classes.leftRightSpacing} variant="contained" onClick={() => window.print()}>
              <div className={classes.printIcon}>
                <PrintIcon />
              </div>
              Print Tickets
            </Button>
            <Button onClick={() => qrCodeRef.current.focus()}>
              Scan Now
            </Button>
          </Box>
        )}
        <Box mb={6}>
          <Box mb={1}>
            <Typography variant="h5">
              Start Scanning
            </Typography>
          </Box>
          <Paper className={classes.form}>
            <i>
              To start scanning, select the text field below and scan the QR code from the
              ticket you would like to bag
            </i>
            <Box mt={2} mb={2}>
              <TextField
                placeholder="Scan QR Code"
                className={classes.propertyDropdown}
                variant="outlined"
                value={navigateToString}
                onKeyDown={checkForSubmit}
                inputRef={qrCodeRef}
                onChange={(event) => setNavigateToString(event.target.value)}
              />
            </Box>
          </Paper>
        </Box>

        <Box mb={6}>
          <Box mb={1}>
            <Typography id="openReservations" variant="h5">Tickets Open</Typography>
          </Box>
          <Paper className={classes.paper} elevation={2}>
            {scannerStore.reservationsPreLoaded
              ? (
                <ReservationTable
                  reservations={scannerStore.openReservations}
                  scannerStore={scannerStore}
                  selectRow={selectRow}
                  allowProcessing={serviceTickets}
                />
              )
              : <Box textAlign="center" pt={3}><CircularProgress /></Box>}
            <Box p={3} />
          </Paper>
        </Box>

        <Box mb={6}>
          <Box mb={1}>
            <Typography id="activeReservations" variant="h5">Tickets Processing</Typography>
          </Box>
          <Paper className={classes.paper} elevation={2}>
            {scannerStore.reservationsPreLoaded
              && (
                <ReservationTable
                  reservations={scannerStore.activeReservations}
                  scannerStore={scannerStore}
                  allowProcessing={serviceTickets}
                />
              )}
            <Box p={3} />
          </Paper>
        </Box>

        <Box mb={2} />

        <Box mb={1}>
          <Typography id="completeReservations" variant="h5">Tickets Completed</Typography>
        </Box>
        <Paper className={classes.paper} elevation={2}>
          {scannerStore.reservationsPreLoaded
            && (
              <ReservationTable
                reservations={scannerStore.completeReservations}
                scannerStore={scannerStore}
                allowScanning={false}
                allowProcessing={serviceTickets}
              />
            )}
          <Box p={3} />
        </Paper>
        <Box mb={5}>
          <Button onClick={scannerStore.archiveCompletedReservations}>
            Archive Completed Tickets
          </Button>
        </Box>
        {serviceTickets && (
          <Modal
            open={serviceTicketModalOpen}
            onClose={() => setServiceTicketModalOpen(false)}
          >
            <Fade in={serviceTicketModalOpen}>
              <div style={modalStyle} className={classes.modalPaper}>
                <ServiceTicketForm
                  propertyStore={propertyStore}
                  itemStore={itemStore}
                  scannerStore={scannerStore}
                  afterSubmit={() => setServiceTicketModalOpen(false)}
                />
              </div>
            </Fade>
          </Modal>
        )}
      </Container>
      <ScannerPrint scannerTickets={scannerTickets} />
    </>
  );
};

Scanner.propTypes = {
  propertyStore: PropTypes.any,
  scannerStore: PropTypes.any,
  itemStore: PropTypes.any,
  serviceTickets: PropTypes.array,
};

export default observer(Scanner);
