import {
  Box, Button, Typography,
} from '@mui/material';
import { Formik } from 'formik';
import React from 'react';
import Loader from '../../components/loader/Loader';
import WushCard from '../../components/WushCard';
import WushTextField from '../../components/WushTextField';
import config from '../../config';
import RequestService from '../../services/RequestService';

const Settings = () => {
  const [settings, setSettings] = React.useState(null);

  React.useEffect(() => {
    if (!settings) {
      RequestService.get(`${config.backendUrl}/settings`, (response) => {
        setSettings(response.data);
      });
    }
  });

  if (!settings) return <Loader />;

  const updateSettingValue = ({ id, value }) => {
    RequestService.post(`${config.backendUrl}/settings/${id}`, { setting: { value } });
  };

  return (
    <Box pt={2}>
      <Box mb={2}>
        <Typography variant="h4">Organization Settings</Typography>
      </Box>
      <WushCard>
        {settings.map((setting) => (
          <Formik
            initialValues={{
              id: setting.id,
              value: setting.value,
            }}
            onSubmit={updateSettingValue}
          >
            {
              (formik) => (
                <Box mb={6}>
                  <Box>
                    <Typography variant="body1">{setting.name}</Typography>
                  </Box>
                  <Box>
                    <Typography variant="caption">{setting.description}</Typography>
                  </Box>
                  <Box mb={2}>
                    <WushTextField formik={formik} name="value" />
                  </Box>
                  <Box>
                    <Button variant="contained" onClick={formik.handleSubmit}>Save</Button>
                  </Box>
                </Box>
              )
            }
          </Formik>
        ))}
      </WushCard>
    </Box>
  );
};

export default Settings;
