import {
  Box, Button, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography,
} from '@mui/material';
import React, { useEffect } from 'react';
import { useRouteMatch } from 'react-router';
import PrintIcon from '@mui/icons-material/Print';
import { format } from 'date-fns';
import Loader from '../../../components/loader/Loader';
import WushCard from '../../../components/WushCard';
import config from '../../../config';
import RequestService from '../../../services/RequestService';
import { calculateItemCost } from '../new/components/CartItem';
import { CustomerInformation } from '../new/components/SelectedCustomer';
import Payment from '../components/Payment';

const Invoice = () => {
  const match = useRouteMatch();
  const { reservationId } = match.params;
  const [invoice, setInvoice] = React.useState(null);

  useEffect(() => {
    if (!invoice) {
      RequestService.get(`${config.backendUrl}/invoices/${reservationId}`, (response) => {
        setInvoice(response.data);
      });
    }
  });

  if (!invoice) return <Loader />;

  return (
    <Box pt={2}>
      <Grid container>
        <Grid item md={4}>
          <Box mr="2rem">
            <Payment setInvoice={setInvoice} invoice={invoice} />
          </Box>
        </Grid>
        <Grid item md={8} className="print-full-width">
          <Box>
            <WushCard>
              <Box px="2rem" pb="2rem">
                <Grid container className="hide-print">
                  <Grid item md={6}>
                    <Box my="1rem">
                      <Typography variant="h5">
                        Invoice #
                        {invoice.id}
                      </Typography>
                    </Box>
                  </Grid>
                  {invoice.payment && (
                    <Grid item md={6}>
                      <Box my="1rem" textAlign="right">
                        <Button variant="contained">Send Quote</Button>
                      </Box>
                    </Grid>
                  )}
                </Grid>
                <Grid container>
                  <Grid item md={6} textAlign="left">
                    <Box>
                      {invoice.customer
                        && <CustomerInformation customer={invoice.customer} />}
                    </Box>
                  </Grid>
                </Grid>
                <Grid container>
                  <Box width="100%">
                    {invoice.items.length > 0 && (
                      <Box pt="2rem">
                        <Typography variant="h6">Items</Typography>
                        <TableContainer>
                          <Table>
                            <TableHead>
                              <TableRow>
                                <TableCell>
                                  Item Summary
                                </TableCell>
                                <TableCell>
                                  Quantity
                                </TableCell>
                                <TableCell>
                                  Rental Dates
                                </TableCell>
                                <TableCell>
                                  Receiving
                                </TableCell>
                                <TableCell>
                                  Return
                                </TableCell>
                                <TableCell>
                                  Price
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {invoice.items.map((item) => (
                                <TableRow>
                                  <TableCell>{item.name}</TableCell>
                                  <TableCell>{item.selectedQuantity}</TableCell>
                                  <TableCell>
                                    {`${format(new Date(item.dateRange?.startDate), 'MM/dd')} - ${format(new Date(item.dateRange?.endDate), 'MM/dd')}`}
                                  </TableCell>
                                  <TableCell>{item.receivingMethod}</TableCell>
                                  <TableCell>{item.returningMethod}</TableCell>
                                  <TableCell sx={{ textAlign: 'right' }}>
                                    $
                                    {item.selectedQuantity
                                      ? calculateItemCost(item)
                                      : 0}
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        <Box>
                          <Grid container>
                            <Grid md={8} />
                            <Grid md={4}>
                              <TableContainer>
                                <Table size="small">
                                  <TableBody>
                                    <TableRow>
                                      <TableCell>
                                        <strong>Subtotal:</strong>
                                      </TableCell>
                                      <TableCell sx={{ textAlign: 'right' }}>
                                        <strong>
                                          $
                                          {invoice.cost}
                                        </strong>
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell>
                                        <strong>Taxes:</strong>
                                      </TableCell>
                                      <TableCell sx={{ textAlign: 'right' }}>
                                        <strong>
                                          $
                                          {invoice.taxPaid}
                                        </strong>
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell>
                                        <strong>Order Total:</strong>
                                      </TableCell>
                                      <TableCell sx={{ textAlign: 'right' }}>
                                        <strong>
                                          $
                                          {invoice.totalCost}
                                        </strong>
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell>
                                        <strong>Amount Paid:</strong>
                                      </TableCell>
                                      <TableCell sx={{ textAlign: 'right' }}>
                                        <strong>
                                          $
                                          {invoice.payment?.amount || '00.00'}
                                        </strong>
                                      </TableCell>
                                    </TableRow>
                                    <TableRow>
                                      <TableCell>
                                        <strong>Balance Due:</strong>
                                      </TableCell>
                                      <TableCell sx={{ textAlign: 'right' }}>
                                        <strong>
                                          $
                                          {invoice.totalCost - (invoice.payment?.amount || 0)}
                                        </strong>
                                      </TableCell>
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </Grid>
                          </Grid>
                        </Box>
                      </Box>
                    )}
                  </Box>
                </Grid>
                {invoice.payment && (
                  <Grid className="hide-print" container>
                    <Grid item md={6}>
                      <Box pt="2rem">
                        <Button variant="contained" onClick={() => window.print()}>
                          <Box mr="1rem">
                            <PrintIcon />
                          </Box>
                          Print Invoice
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                )}
              </Box>
            </WushCard>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Invoice;
