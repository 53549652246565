import React, { useState, useEffect } from 'react';

import {
  Box, Autocomplete, CircularProgress, TextField, Button,
} from '@mui/material';
import { Formik } from 'formik';

import RequestService from '../../../services/RequestService';
import config from '../../../config';
import WushButtonCard from '../../../components/WushButtonCard';
import AddPaymentMethod from './AddPaymentMethod';
import WushInput from './WushInputV2';

const EventCustomerRegistration = ({ event, handleSuccess }) => {
  const [customers, setCustomers] = useState(null);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [paymentMethods, setPaymentMethods] = useState(null);
  const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState(null);
  const [paymentMethodsLoading, setPaymentMethodsLoading] = useState(true);
  const [addPaymentMethod, setAddPaymentMethod] = useState(false);
  const [enrollingLoading, setEnrollingLoading] = useState(false);
  const [showCreateCustomer, setShowCreateCustomer] = useState(false);

  const getCustomers = () => {
    RequestService.get(`${config.backendUrl}/customers`, (response) => {
      setCustomers(response.data);
    });
  };

  const getPaymentMethods = (customer) => {
    setPaymentMethodsLoading(true);
    RequestService.get(`${config.backendUrl}/customers/${customer.id}/payment_methods`, (response) => {
      setPaymentMethods(response.data);
      setPaymentMethodsLoading(false);
    });
  };

  const createCustomer = ({ firstName, lastName, email }) => {
    RequestService.post(`${config.backendUrl}/customers`, {
      customer: {
        firstName,
        lastName,
        email,
      },
    }, (response) => {
      getPaymentMethods(response.data);
      setCustomers(customers.concat([response.data]));
      setSelectedCustomer(response.data);
      setShowCreateCustomer(false);
    });
  };

  const handleSelectCustomer = (customer) => {
    getPaymentMethods(customer);
    setSelectedCustomer(customer);
  };

  const handleEnrollment = () => {
    setEnrollingLoading(true);
    RequestService.post(`${config.backendUrl}/event_registrations`, {
      eventRegistration: {
        customerId: selectedCustomer.id,
        eventId: event.id,
        paymentMethodId: selectedPaymentMethodId,
      },
    }, () => {
      setEnrollingLoading(false);
      handleSuccess();
      setSelectedCustomer(null);
    });
  };

  const renderCustomers = () => {
    if (showCreateCustomer) {
      return (
        <Formik
          initialValues={{
            firstName: '',
            lastName: '',
            email: '',
          }}
          onSubmit={createCustomer}
        >
          {
            (formik) => (
              <Box>
                <WushInput
                  formik={formik}
                  name="firstName"
                  label="First name"
                />
                <WushInput
                  formik={formik}
                  name="lastName"
                  label="Last name"
                />
                <WushInput
                  formik={formik}
                  name="email"
                  label="Email"
                />
                <Button variant="contained" onClick={formik.handleSubmit}>Create</Button>
              </Box>
            )
          }
        </Formik>
      );
    }
    return (
      <Box mb={2}>
        <Autocomplete
          id="combo-box-demo"
          options={customers}
          getOptionLabel={(option) => (`${option.firstName} ${option.lastName}`)}
          className={null}
          renderInput={(params) => <TextField {...params} label="Select Customer" variant="outlined" />}
          value={selectedCustomer}
          onChange={(_event, value) => {
            handleSelectCustomer(value);
          }}
        />
        <Button onClick={() => setShowCreateCustomer(true)}>
          Add Customer
        </Button>
      </Box>
    );
  };

  const renderPayments = () => {
    if (!selectedCustomer) return <Box />;
    if (paymentMethodsLoading) return <CircularProgress />;
    if (paymentMethods.length > 0 && !addPaymentMethod) {
      return (
        <Box>
          {paymentMethods.map((paymentMethod) => (
            <Box mb={1}>
              <Box mb={1}>
                <WushButtonCard
                  onClick={() => setSelectedPaymentMethodId(paymentMethod.id)}
                  active={paymentMethod.id === selectedPaymentMethodId}
                >
                  {paymentMethod.card.brand}
                  {' '}
                  x
                  {paymentMethod.card.last4}
                  {' '}
                  -
                  {paymentMethod.card.exp_month}
                  {' '}
                  /
                  {paymentMethod.card.exp_year}
                </WushButtonCard>
              </Box>
            </Box>
          ))}
          <Button onClick={() => setAddPaymentMethod(true)}>
            Add Payment Method
          </Button>
        </Box>
      );
    }
    return (
      <Box>
        <AddPaymentMethod
          handleSuccess={() => {
            setAddPaymentMethod(false);
            getPaymentMethods(selectedCustomer);
          }}
          customer={selectedCustomer}
          buttonText="Save"
          onCancel={() => setAddPaymentMethod(false)}
        />
      </Box>
    );
  };

  useEffect(getCustomers, []);

  if (!customers) return <CircularProgress />;
  return (
    <Box pt={2}>
      {renderCustomers()}
      <Box mb={2}>
        {renderPayments()}
      </Box>
      <Box>
        {selectedPaymentMethodId && (
          <Box>
            <Button variant="contained" onClick={handleEnrollment}>
              {enrollingLoading ? (
                <Box pl={5} pr={5}>
                  <CircularProgress size={10} />
                </Box>
              ) : 'Enroll in Event'}
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default EventCustomerRegistration;
