import {
  Box, Button, Grid, MenuItem, Select, Typography,
} from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import importReservations from '../../../services/reservationImports/importReservations';
import prepareReservationImport from '../../../services/reservationImports/prepareReservationImport';

const IMPORT_FIELDS = [
  { label: 'Ignore', value: 'ignore' },
  { label: 'Property Alias', value: 'alias' },
  { label: 'Customer Name', value: 'customer_name' },
  { label: 'Start Date', value: 'start_date' },
  { label: 'End Date', value: 'end_date' },
];

const NewImport = () => {
  const [sampleRow, setSampleRow] = useState([]);
  const [reservationImportId, setReservationImportId] = useState(null);
  const [isPreparing, setIsPreparing] = useState(false);
  const [isImporting, setIsImporting] = useState(false);

  const history = useHistory();

  const handlePrepareImport = (values) => {
    setIsPreparing(true);
    prepareReservationImport(values.file, (response) => {
      setSampleRow(response.data.headers);
      setReservationImportId(response.data.import_id);
      setIsPreparing(false);
    });
  };

  const handleImportReservations = (values) => {
    setIsImporting(true);
    importReservations(reservationImportId, values, () => {
      history.push(`/reservation-management/import/${reservationImportId}`);
      setIsImporting(false);
    });
  };

  const prepareButtonLabel = () => {
    if (sampleRow.length > 0) {
      if (isPreparing) {
        return 'Re-Preparing...';
      }
      return 'Re-Prepare';
    }
    if (isPreparing) {
      return 'Preparing...';
    }
    return 'Prepare';
  };

  return (
    <Box py={2}>
      <Box mb={2}>
        <Typography variant="h3">
          New Import
        </Typography>
      </Box>
      <Box mb={2}>
        <Formik
          initialValues={{
            file: null,
          }}
          onSubmit={handlePrepareImport}
        >
          {
            (formik) => (
              <Form>
                <Box mb={2}>
                  <input type="file" onChange={(e) => formik.setFieldValue('file', e.currentTarget.files[0])} />
                </Box>
                <Box>
                  <Button
                    variant="contained"
                    disabled={isPreparing}
                    onClick={formik.handleSubmit}
                  >
                    {prepareButtonLabel()}
                  </Button>
                </Box>
              </Form>
            )
          }
        </Formik>
      </Box>
      <Box>
        {isPreparing ? null : (
          <Formik
            initialValues={{
              mapping: {},
            }}
            onSubmit={handleImportReservations}
          >
            {
              (formik) => (
                <Form>
                  {
                    sampleRow.map((item) => (
                      <Grid container mb={2}>
                        <Grid item md={4}>
                          <Box>
                            <Typography variant="h6">{item}</Typography>
                          </Box>
                        </Grid>
                        <Grid item md={8}>
                          <Box>
                            <Select
                              value={formik.values.mapping[item] || 'ignore'}
                              label="Age"
                              onChange={(e) => formik.setFieldValue(`mapping.${item}`, e.target.value)}
                              fullWidth
                            >
                              {IMPORT_FIELDS.map((option) => (
                                <MenuItem value={option.value}>{option.label}</MenuItem>
                              ))}
                            </Select>
                          </Box>
                        </Grid>
                      </Grid>
                    ))
                  }
                  {sampleRow.length === 0 ? null : (
                    <Button
                      variant="contained"
                      disabled={isImporting}
                      onClick={formik.handleSubmit}
                    >
                      {isImporting ? 'Importing...' : 'Import'}
                    </Button>
                  )}
                </Form>
              )
            }
          </Formik>
        )}
      </Box>
    </Box>
  );
};

export default NewImport;
