import {
  Box, Button, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography,
} from '@mui/material';
import React from 'react';
import { format } from 'date-fns';
import config from '../../../../config';
import RequestService from '../../../../services/RequestService';

const PickupCard = ({ pickup }) => {
  const [pickedUp, setPickedUp] = React.useState(pickup.pickedUp);
  const pickUp = () => {
    RequestService.post(`${config.backendUrl}/pickup-routes/pickup`, { pickupIds: [pickup.id] }, () => {
      setPickedUp(true);
    });
  };

  return (
    <Grid container>
      <Grid item md="4">
        <Box mb={1}>
          <b>
            Reservation #
            {pickup.reservation.id}
          </b>
        </Box>
        <Box mb={1}>
          <Typography variant="body1">
            {pickup.reservation.customerName}
          </Typography>
          <Typography variant="body1">
            {pickup.reservation.customerPhone}
          </Typography>
        </Box>
        <Box>
          <Typography variant="body1">
            {pickup.reservation.propertyName}
          </Typography>
          <Typography variant="body1">
            {pickup.reservation.propertyAddress}
          </Typography>
          <Typography variant="body1">
            {`${pickup.reservation.propertyCity}, ${pickup.reservation.propertyState} ${pickup.reservation.propertyZip}`}
          </Typography>
          {pickup.pickupDate && (
            <Typography variant="body1">
              pickedUp at:
              {' '}
              {format(new Date(pickup.pickupDate), 'HH:mm aaaa')}
            </Typography>
          )}
        </Box>
      </Grid>
      <Grid item md="8">
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  Item Name
                </TableCell>
                <TableCell>
                  Quantity
                </TableCell>
                <TableCell>
                  Pickup Date
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pickup.reservationItems.map((reservationItem) => (
                <TableRow>
                  <TableCell>
                    <Typography variant="body1">{reservationItem.itemName}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1">{reservationItem.reservedQuantity}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body1">{reservationItem.startDate}</Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item md="4">
        <Button onClick={pickUp} disabled={pickup.pickedUp || pickedUp}>Pickup</Button>
      </Grid>
    </Grid>
  );
};

export default PickupCard;
