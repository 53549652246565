import React, { useEffect, useState } from 'react';
import {
  Box, Typography, Button,
} from '@mui/material';

import Fuse from 'fuse.js';
import { useHistory } from 'react-router';
import RequestService from '../../../services/RequestService';
import config from '../../../config';
import WushInputV2 from '../components/WushInputV2';

import WushCard from '../../../components/WushCard';
import WushTable from '../../../components/WushTable';

const Customers = () => {
  const [customers, setCustomers] = useState([]);
  const [search, setSearch] = useState('');

  const history = useHistory();

  const fetchCustomers = () => {
    RequestService.get(`${config.backendUrl}/customers`, (response) => {
      setCustomers(response.data);
    });
  };

  useEffect(() => {
    fetchCustomers();
  }, []);

  const fuseOptions = { keys: ['firstName', 'lastName', 'email'] };
  const fuse = new Fuse(customers || [], fuseOptions);
  const searchedCustomers = fuse.search(search).map((item) => item.item);

  return (
    <Box py={2}>
      <Box>
        <Box>
          <Typography variant="h3">Customers</Typography>
        </Box>
        <Box mb={5}>
          <WushInputV2
            inputType="text"
            label="Search"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </Box>
        <WushCard>
          <Box mb={3}>
            <Button variant="contained" color="primary">
              Add Customer
            </Button>
          </Box>
          <WushTable
            headers={[
              { label: 'Name', customGetter: (row) => `${row.firstName} ${row.lastName}` },
              { name: 'email', label: 'Email' },
              { label: 'Has Account?', customGetter: (row) => (row.userId !== null ? 'Yes' : 'No') },
            ]}
            data={searchedCustomers.length > 0 ? searchedCustomers : customers}
            onRowClick={(row) => history.push(`/event-management/customers/${row.id}`)}
          />
        </WushCard>
      </Box>
    </Box>
  );
};

export default Customers;
