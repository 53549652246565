import {
  Autocomplete, Box, Button, FormGroup, Grid, TextField, Typography, Alert,
} from '@mui/material';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@gabmarini/ckeditor5-build-classic-base64upload';
import { Form } from 'formik';
import React from 'react';
import { addHours } from 'date-fns';
import WushInput from './WushInput';
import WushInputV2 from './WushInputV2';

const EventForm = ({
  selectedEvent, values, formik, employeeStore, handleSubmit, onCancel, handleDelete,
  eventInSeries, handleDeleteRepetition, handleUpdateRepeats,
}) => (
  <Box>
    <Form>
      <Box textAlign="center">
        <Typography variant="h4">
          {selectedEvent.id ? 'Edit' : 'New'}
          {' '}
          Event
        </Typography>
        {!eventInSeries && selectedEvent.id && (
          <Box>
            <Typography variant="body1">
              <strong>Currently enrolled: </strong>
              {selectedEvent.registrationsCount}
            </Typography>
          </Box>
        )}
        {eventInSeries || values.eventSeriesId ? (
          <Box mb={2}>
            <Alert severity="info">
              This event
              {' '}
              {selectedEvent.id ? 'is in' : 'will be added to' }
              {' '}
              the
              {' '}
              <strong>event series</strong>
              {' '}
              &quot;
              {eventInSeries || `ID: ${values.eventSeriesId}`}
              &quot;
            </Alert>
          </Box>
        ) : (
          <Box mb={2}>
            <Alert severity="warning">This event is a standalone event</Alert>
          </Box>
        )}
        <Grid container>
          <WushInput
            label="Name"
            name="name"
            value={values.name}
            onChange={(e) => formik.setFieldValue('name', e.target.value)}
            gridCol={6}
          />
          <Box my={3}>
            <CKEditor
              editor={ClassicEditor}
              data={values.description}
              onChange={(event, editor) => {
                formik.setFieldValue('description', editor.getData());
              }}
            />
          </Box>

          <WushInput
            label="Additional Information"
            name="additionalInformation"
            mb={3}
            inputType="textarea"
            value={values.additionalInformation}
            onChange={(e) => formik.setFieldValue('additionalInformation', e.target.value)}
          />
          {!eventInSeries && (
            <WushInput
              label="Cost"
              value={values.cost}
              onChange={(e) => formik.setFieldValue('cost', e.target.value)}
              mb={3}
              gridCol={4}
              inputType="money"
            />
          )}
          {!eventInSeries && (
            <WushInput
              label="Enabled"
              name="enabled"
              inputType="checkbox"
              gridCol={2}
              mb={3}
            />
          )}
          {!eventInSeries && (
            <WushInput
              label="Allow Anonymous"
              name="allowAnonymous"
              inputType="checkbox"
              gridCol={2}
              mb={3}
            />
          )}

          <WushInput
            label="Capacity"
            value={values.capacity}
            onChange={(e) => formik.setFieldValue('capacity', e.target.value)}
            inputType="number"
            gridCol={4}
          />
          {eventInSeries && <Grid item md={8} />}
          <WushInput
            label="Start"
            value={values.startDatetime}
            onChange={(newValue) => {
              formik.setFieldValue('startDatetime', newValue);
              formik.setFieldValue('endDatetime', addHours(new Date(newValue), 1));
            }}
            gridCol={6}
            inputType="datetime"
          />
          <WushInput
            label="End"
            value={values.endDatetime}
            onChange={(newValue) => {
              formik.setFieldValue('endDatetime', newValue);
            }}
            gridCol={6}
            inputType="datetime"
          />
          <WushInput
            label="Open to registration"
            value={values.visibleDate}
            onChange={(newValue) => {
              formik.setFieldValue('visibleDate', newValue);
            }}
            gridCol={6}
            inputType="date"
            mb={3}
          />
          <Grid item md={6}>
            <FormGroup>
              {employeeStore && (
                <Autocomplete
                  multiple
                  fullWidth
                  id="combo-box-demo"
                  options={employeeStore && employeeStore.employees}
                  getOptionLabel={(option) => (option.firstName ? `${option.firstName} ${option.lastName}` : '')}
                  renderInput={(params) => <TextField {...params} label="Select Employee" variant="outlined" />}
                  value={values.employees}
                  filterSelectedOptions
                  onChange={(_event, value) => {
                    formik.setFieldValue('employees', value);
                  }}
                />
              ) }
            </FormGroup>
          </Grid>
          {!values.id && (
            <WushInput
              label="Repetition"
              name="repetition"
              inputType="radioGroup"
              gridCol={12}
              options={[
                { label: 'No repeat', value: '' },
                { label: 'Every Week', value: 'week' },
                { label: 'Every weekday', value: 'weekday' },
              ]}
            />
          )}
          {values.repetition && (
            <WushInputV2
              label="Repeat until"
              name="repeatEnd"
              formik={formik}
              gridCol={12}
              inputType="date"
            />
          )}
        </Grid>
        <Box sx={{ '& button': { m: 1 } }} mt={3} textAlign="left">
          <Box>
            {handleSubmit && <Button variant="contained" onClick={handleSubmit}>Save Event</Button>}
            {handleUpdateRepeats && <Button variant="contained" onClick={handleUpdateRepeats}>Save Event Repeats</Button>}
          </Box>
          <Box>
            <Button variant="contained" color="secondary" onClick={handleDelete}>Delete</Button>
            {handleDeleteRepetition && <Button variant="contained" color="secondary" onClick={handleDeleteRepetition}>Delete Weekly Repeats</Button>}
          </Box>
          <Box>
            {onCancel && <Button variant="outlined" onClick={onCancel}>Cancel</Button>}
          </Box>
        </Box>
      </Box>
    </Form>
  </Box>
);

export default EventForm;
