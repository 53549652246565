import React, { useEffect, useState } from 'react';
import {
  Box, Breadcrumbs, Link, Typography, CircularProgress, Button, Grid, Divider, Stack,
} from '@mui/material';
import { useRouteMatch } from 'react-router';
import { useFormik, Formik } from 'formik';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@gabmarini/ckeditor5-build-classic-base64upload';
import RequestService from '../../../services/RequestService';
import config from '../../../config';

import CustomerPersonalInformationForm from '../components/CustomerPersonalInformationForm';
import CustomerOrgPersonalInformationForm from '../components/CustomerOrgPersonalInformationForm';
import WushCard from '../../../components/WushCard';
import CustomerRegistrations from '../components/CustomerRegistrations';
import CustomerPayments from '../components/CustomerPayments';
import WushModal from '../../../components/WushModal';
import WushInput from '../components/WushInputV2';

const CustomerFormWrapper = ({ customer, updateCustomer, setShowSendEmail }) => {
  const formik = useFormik({
    initialValues: customer,
    onSubmit: updateCustomer,
  });

  return (
    <Box>
      <CustomerPersonalInformationForm formik={formik} />
      <CustomerOrgPersonalInformationForm formik={formik} />
      <Stack direction="row" spacing={2}>
        <Button onClick={formik.handleSubmit} variant="contained" color="primary">
          Update
        </Button>
        <Button onClick={() => setShowSendEmail(true)} variant="contained">
          Send Email
        </Button>
      </Stack>
    </Box>
  );
};

const ViewCustomer = ({ alertStore }) => {
  const [customer, setCustomer] = useState(null);
  const [showSendEmail, setShowSendEmail] = useState(false);
  const match = useRouteMatch();
  const { customerId } = match.params;

  const fetchCustomer = () => {
    RequestService.get(`${config.backendUrl}/customers/${customerId}`, (response) => {
      setCustomer(response.data);
    });
  };

  const updateCustomer = (values) => {
    const updatePayload = {
      customer: values,
    };
    RequestService.post(`${config.backendUrl}/customers/${customerId}`, updatePayload, () => {
      fetchCustomer();
      alertStore.setAlertMessage('Customer updated!');
    });
  };

  const handleSendEmail = ({ subject, body }) => {
    RequestService.post(`${config.backendUrl}/customers/${customerId}/email`, {
      email: {
        subject,
        body,
      },
    }, () => {
      setShowSendEmail(false);
      alertStore.setAlertMessage('Email sent!');
    });
  };

  useEffect(() => {
    fetchCustomer();
  }, []);

  if (!customer) return <CircularProgress />;

  return (
    <Box pt={2}>
      <Breadcrumbs aria-label="breadcrumb" mb={2}>
        <Link underline="hover" color="inherit" href="/event-management/customers">
          Customers
        </Link>
        <Typography color="text.primary">
          {customer.firstName}
          {' '}
          {customer.lastName}
        </Typography>
      </Breadcrumbs>
      <WushCard>
        <Box p={2}>
          <Box mb={2}>
            <Typography variant="h5">Customer Information</Typography>
          </Box>
          {customer
            && (
              <CustomerFormWrapper
                customer={customer}
                updateCustomer={updateCustomer}
                setShowSendEmail={setShowSendEmail}
              />
            )}
        </Box>
      </WushCard>
      <Box my={3}>
        <Divider />
      </Box>
      <Grid container spacing={2}>
        <Grid item md={6}>
          <WushCard>
            <Box>
              <Box>
                <Typography variant="h6">Registrations</Typography>
              </Box>
              <Box>
                {customer && <CustomerRegistrations {...customer} />}
              </Box>
            </Box>
          </WushCard>
        </Grid>
        <Grid item md={6}>
          <WushCard>
            <Box>
              <Box>
                <Typography variant="h6">Payments</Typography>
                <Typography variant="caption">
                  Stripe ID:
                  {customer.stripeCustomerId}
                </Typography>
              </Box>
              <Box>
                {customer && <CustomerPayments {...customer} />}
              </Box>
            </Box>
          </WushCard>
        </Grid>
      </Grid>
      <WushModal
        open={showSendEmail}
        onClose={() => setShowSendEmail(false)}
      >
        <Box>
          <Formik
            initialValues={{
              subject: '',
              body: '',
            }}
            onSubmit={handleSendEmail}
          >
            { (emailFormik) => (
              <Box>
                <Box mb={2}>
                  <Typography variant="h6">
                    Compose Email to
                    {customer.firstName}
                    {' '}
                    (
                    {customer.email}
                    )
                  </Typography>
                </Box>
                <Box mb={2}>
                  <WushInput
                    formik={emailFormik}
                    name="subject"
                    label="Subject"
                  />
                  <CKEditor
                    editor={ClassicEditor}
                    data={emailFormik.values.body}
                    onChange={(event, editor) => {
                      emailFormik.setFieldValue('body', editor.getData());
                    }}
                  />
                </Box>
                <Box mb={10}>
                  <Button onClick={emailFormik.handleSubmit} variant="contained">
                    Send
                  </Button>
                </Box>
              </Box>
            )}
          </Formik>
        </Box>
      </WushModal>
    </Box>
  );
};

export default ViewCustomer;
