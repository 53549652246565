import { Alert, Snackbar } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';

const WushAlert = ({ alertStore }) => {
  const handleClose = () => {
    alertStore.clearAlertMessage();
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={alertStore.alertMessage}
      autoHideDuration={4000}
      onClose={handleClose}
      sx={{
        width: '50%',
      }}
    >
      <Alert onClose={handleClose} severity={alertStore.alertType} sx={{ width: '100%' }}>
        {alertStore.alertMessage}
      </Alert>
    </Snackbar>
  );
};

export default observer(WushAlert);
