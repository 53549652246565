import {APPS} from './constants';

export const getCurrentApp = () => {
  const baseRoute = window.location.pathname.split("/")[1];
  const currentApp = Object.values(APPS).find((app) => `${app.baseRoute}` === baseRoute)
  return currentApp ? currentApp : {}
}

export const getCurrentPage = () => {
  const pageRoute = window.location.pathname.split("/")[2];
  const currentApp = getCurrentApp()
  if(!currentApp.pages) return {}
  const currentPage = Object.values(currentApp.pages).find((page) => `${page.baseRoute}` === pageRoute)
  return currentPage ? currentPage : {}
}