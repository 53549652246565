import {
  Grid,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Typography,
  Stack,
} from '@mui/material';
import React from 'react';
import { format } from 'date-fns';
import Search from '@mui/icons-material/Search';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import CloseIcon from '@mui/icons-material/Close';
import { useHistory } from 'react-router-dom';

const DeliveryRoutePrintout = ({ deliveryRoute }) => {
  // loop to store all Reservation Items from all Deliveries
  const allDeliveries = deliveryRoute.deliveries;
  let totalRezItems = [];
  for (let i = 0; i < allDeliveries.length; i += 1) {
    for (let j = 0; j < allDeliveries[i].reservationItems.length; j += 1) {
      totalRezItems = totalRezItems.concat(
        allDeliveries[i].reservationItems[j],
      );
    }
  }
  // loop to filter through totalrezitems and keep only unique items
  const finalPulledItems = Object.values(
    totalRezItems.reduce((acc, curr) => {
      const { itemName, reservedQuantity } = curr;
      acc[itemName] = acc[itemName] || { itemName, reservedQuantity: 0 };
      acc[itemName].reservedQuantity += reservedQuantity;
      return acc;
    }, {}),
  );

  const history = useHistory();

  return (
    <Grid container className="delivery-route-printout">
      <Grid container alignItems="center" pt={2}>
        <Grid item xs={3}>
          <Typography variant="h4">
            Route
            {' '}
            {deliveryRoute.id}
          </Typography>
        </Grid>
        <Grid
          item
          xs={8}
          display="flex"
          flexDirection="row"
          alignItems="center"
        >
          <AccessTimeIcon className="clock" />
          <Typography>
            Delivery Date
            {' '}
            {allDeliveries[0].deliveryDate}
          </Typography>
          {/* Add Delivery Date of Route from API */}
        </Grid>
        <Grid item xs={1} display="flex" justifyContent="flex-end">
          <IconButton onClick={() => {
            window.close();
          }}
          >
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Grid container className="total-items">
        <TableContainer>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell className="table-row">
                  <h6>Pull Items</h6>
                </TableCell>
                <TableCell align="right" className="table-row">
                  <h6>QTY</h6>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {finalPulledItems.map((item) => (
                <TableRow
                  key={item.itemName}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row" className="table-row">
                    <p>{item.itemName}</p>
                  </TableCell>
                  <TableCell align="right" className="table-row">
                    <p>{item.reservedQuantity}</p>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <Grid container className="route-display">
        <h4>Delivery Route</h4>
        {allDeliveries.map((delivery) => (
          <Grid
            item
            key={delivery.id}
            xs={12}
            p={2}
            my={2}
            display="flex"
            flexDirection="row"
            className="delivery-route-card"
          >
            <Grid
              xs={6}
              display="flex"
              flexDirection="column"
              justifyContent="center"
            >
              <Stack
                direction="row"
              >
                <Box my="auto" display="flex">
                  <Typography variant="h5" fontWeight="700" sx={{ my: 'auto' }}>
                    {delivery.reservation.propertyName}
                  </Typography>
                </Box>
                <Box my="auto" className="hide-print">
                  <IconButton size="small" onClick={() => history.push(`/fulfillment/deliveries/${delivery.id}`)}>
                    <Search />
                  </IconButton>
                </Box>
              </Stack>
              <Stack direction="row">
                <Typography variant="body2" fontWeight="400">
                  Reservation date:
                  {' '}
                  {format(new Date(delivery.reservation.startDate), 'MM/dd/yyyy')}
                </Typography>
              </Stack>
              {delivery.delivered && (
                <Stack direction="row" mb={1}>
                  <Typography variant="body2" fontWeight="400">
                    <em>
                      Delivered on
                      {' '}
                      {format(new Date(delivery.deliveryDateTime), 'MM/dd/yyyy')}
                    </em>
                  </Typography>
                </Stack>
              )}
              <Box
                display="flex"
                flexDirection="row"
              >
                <FmdGoodIcon className="location" />
                <Typography sx={{ my: 'auto' }} variant="body1">{delivery.deliveryAddress}</Typography>
              </Box>
              <Box
                className="customer-contact"
                flexDirection="row"
                display="flex"
                alignItems="center"
              >
                <Box>
                  <PersonOutlineIcon className="avatar" />
                </Box>
                <Box>
                  <Typography variant="body1">{delivery.reservation.customerName}</Typography>
                  <Typography variant="body2">
                    {delivery.reservation.customerPhone}
                    {'  '}
                    |
                    {'  '}
                    {delivery.reservation.customerEmail}
                  </Typography>
                </Box>
              </Box>
              {delivery?.reservation?.comments?.length > 0 && (
                <Box ml={6}>
                  <h6>Comments</h6>
                  {/* Need to map through comments */}
                  <p>{delivery.reservation.comments}</p>
                </Box>
              )}
            </Grid>
            <Grid xs={6} className="right-section">
              <h6>SKU Number</h6>
              <Box
                className="reservation-item-container"
                display="flex"
                flexDirection="row"
                flexWrap="wrap"
              >
                {delivery.reservationItems.map((reservationItem) => (
                  <Box
                    className="reservation-item"
                    display="flex"
                    flexDirection="row"
                    alignItems="center"
                  >
                    <Box
                      className="reservation-amount"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Typography variant="h6" fontSize="14px">
                        {reservationItem.reservedQuantity}
                        x
                      </Typography>
                    </Box>
                    <Box>
                      <h6>{reservationItem.itemName}</h6>
                    </Box>
                  </Box>
                ))}
              </Box>
            </Grid>
          </Grid>
        ))}
      </Grid>
      <Grid container className="footer" item xs={12} />
    </Grid>
  );
};

export default DeliveryRoutePrintout;
