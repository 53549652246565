import React from 'react';
import { Grid, Box } from '@mui/material';
import GroupIcon from '@mui/icons-material/Group';
import { format } from 'date-fns';
import WushButtonCard from '../../../components/WushButtonCard';

const EventTiles = ({ events, onClick }) => (
  <Grid container>
    {events.map((event) => (
      <Grid item md={3}>
        <Box px={1} mb={2}>
          <WushButtonCard
            disabled={event.userEnrolled}
            onClick={() => onClick(event)}
            minHeight={200}
          >
            <Box>
              {event.name}
            </Box>
            <Box>
              {format(new Date(event.startDatetime), 'ccc MM/dd/yyyy')}
            </Box>
            <Box>
              {format(new Date(event.startDatetime), 'hh:mm b')}
            </Box>
            <Box>
              <GroupIcon />
              {event.registrationsCount}
              {' '}
              /
              {event.capacity}
            </Box>
          </WushButtonCard>
        </Box>
      </Grid>
    ))}
  </Grid>
);

export default EventTiles;
