import { Box, CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { format } from 'date-fns';
import { useHistory } from 'react-router';
import WushTable from '../../../components/WushTable';
import config from '../../../config';
import RequestService from '../../../services/RequestService';

const CustomerPayments = ({ id }) => {
  const [payments, setPayments] = useState(null);
  const history = useHistory();

  const fetchCustomerPayments = () => {
    RequestService.get(`${config.backendUrl}/customers/${id}/payments`, (response) => {
      setPayments(response.data);
    });
  };

  useEffect(() => {
    fetchCustomerPayments();
  }, []);

  if (!payments) return <CircularProgress />;

  return (
    <Box>
      <WushTable
        headers={[
          { name: 'id', label: 'ID' },
          { label: 'Amount', customGetter: (row) => `$ ${row.amount}` },
          { label: 'Date', customGetter: (row) => format(new Date(row.paymentDate), 'MM/dd/yy') },
          { label: 'Method', customGetter: (row) => `${row.paymentMethod} x${row.paymentMethodLast4}` },
          { label: 'Status', name: 'paymentStatus' },
        ]}
        data={payments}
        onRowClick={(row) => history.push(`/event-management/payments/${row.id}`)}
      />
    </Box>
  );
};

export default CustomerPayments;
