import React from 'react';
import {
  Button, FormLabel, InputAdornment, Table, TableBody, TableCell, TableHead, TableRow,
  TextField,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Field } from 'formik';
import ClearIcon from '@mui/icons-material/Clear';
import PropTypes from 'prop-types';

const sumRatesForItems = (kitItems, rateKey) => (
  kitItems.reduce((a, b) => a + (b[rateKey] * b.quantity || 0), 0)
);

const useStyles = makeStyles(() => ({
  itemsCombo: {
    maxWidth: '15rem',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  kitItemRow: {
    marginBottom: '1rem',
  },
  kitItemTable: {
    marginBottom: '2rem',
  },
  rateInput: {
    maxWidth: '4rem',
    marginRight: '.5rem',
  },
}));

const KitItemsTable = ({
  customItemPrice, kitItems, removeKitItem, handleChange,
}) => {
  const classes = useStyles();
  return (
    <Table className={classes.kitItemTable}>
      <TableHead>
        <TableRow>
          <TableCell><strong>Item name</strong></TableCell>
          <TableCell><strong>Quantity</strong></TableCell>
          {customItemPrice && (
            <>
              <TableCell>Half day rate</TableCell>
              <TableCell>Full day rate</TableCell>
              <TableCell>Week rate</TableCell>
              <TableCell />
            </>
          )}
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        { kitItems && kitItems.length > 0
          && kitItems.map((kitItem, index) => (
            <TableRow key={`${kitItem.id}-kitItem`} className={classes.kitItemRow}>
              <TableCell>
                {kitItem.itemName}
              </TableCell>
              <TableCell>
                <Field
                  label="Quantity"
                  name={`kitItems.${index}.quantity`}
                  className={classes.rateInput}
                  as={TextField}
                  type="number"
                  value={kitItems[index].quantity}
                  handleChange={handleChange}
                />
              </TableCell>
              {customItemPrice && (
                <TableCell>
                  <Field
                    name={`kitItems.${index}.halfDayRate`}
                    className={classes.rateInput}
                    as={TextField}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                    type="number"
                    value={kitItems[index].halfDayRate}
                    handleChange={handleChange}
                  />
                  <FormLabel>
                    {'Kit: '}
                    {kitItems[index]?.halfDayRate
                    * kitItems[index]?.quantity}
                  </FormLabel>
                </TableCell>
              )}
              {customItemPrice && (
                <TableCell>
                  <Field
                    name={`kitItems.${index}.fullDayRate`}
                    className={classes.rateInput}
                    as={TextField}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                    type="number"
                    value={kitItems[index].fullDayRate}
                    handleChange={handleChange}
                  />
                  <FormLabel>
                    {'Kit: '}
                    {kitItems[index]?.fullDayRate
                    * kitItems[index]?.quantity}
                  </FormLabel>
                </TableCell>
              )}
              {customItemPrice && (
                <TableCell>
                  <Field
                    name={`kitItems.${index}.weekRate`}
                    className={classes.rateInput}
                    as={TextField}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    }}
                    type="number"
                    value={kitItems[index].weekRate}
                    handleChange={handleChange}
                  />
                  <FormLabel>
                    {'Kit: '}
                    {kitItems[index]?.weekRate
                    * kitItems[index]?.quantity}
                  </FormLabel>
                </TableCell>
              )}
              <TableCell>
                <Button onClick={() => removeKitItem(index)}>
                  <ClearIcon />
                </Button>
              </TableCell>
            </TableRow>
          ))}
        {customItemPrice && (
          <TableRow>
            <TableCell>
              <b>Totals:</b>
            </TableCell>
            <TableCell />
            <TableCell data-testid="halfDayRateTotal">
              {sumRatesForItems(kitItems, 'halfDayRate')}
            </TableCell>
            <TableCell>
              {sumRatesForItems(kitItems, 'fullDayRate')}
            </TableCell>
            <TableCell>
              {sumRatesForItems(kitItems, 'weekRate')}
            </TableCell>
            <TableCell />
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
};

KitItemsTable.propTypes = {
  customItemPrice: PropTypes.bool,
  kitItems: PropTypes.array,
  removeKitItem: PropTypes.func,
  handleChange: PropTypes.func,
};

export default KitItemsTable;
