import { Typography } from '@mui/material';
import { format } from 'date-fns';
import React from 'react';

const InlineEvent = ({ event, variant = 'body1' }) => (
  <Typography variant={variant}>
    <strong>{event.name}</strong>
    {' '}
    starting
    {' '}
    {format(new Date(event.startDatetime), 'MM/dd/yyyy HH:mm b')}
    {
      event.repetition && ` repeating every ${event.repetition}`
    }
  </Typography>
);

export default InlineEvent;
