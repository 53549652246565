import React from 'react';
import {
  Button, CircularProgress, Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { format } from 'date-fns';

import { observer } from 'mobx-react';
import { DataGrid } from '@material-ui/data-grid';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  propertiesTableContainer: {
    height: '1000px',
  },
  propertiesContainer: {
    paddingTop: '2rem',
    paddingBottom: '2rem',
  },
  buttonContainer: {
    marginBottom: '1rem',
  },
  clientsCombo: {
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingTop: '8px',
  },
  paper: {
    position: 'absolute',
    width: '75%',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    textAlign: 'center',
  },
}));

const LocationZones = ({ locationZoneStore }) => {
  const classes = useStyles();
  locationZoneStore.getLocationZones();
  if (!locationZoneStore.locationZonesPreLoaded) return <CircularProgress />;
  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      width: 150,
      editable: true,
    },
    {
      field: 'description',
      headerName: 'Description',
      width: 150,
      editable: true,
    },
    {
      field: 'color',
      headerName: 'Color',
      width: 150,
      editable: true,
    },
    {
      field: 'Actions',
      headerName: '',
      width: 100,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="secondary"
          onClick={() => locationZoneStore.deleteProperty(params.id)}
        >
          Delete
        </Button>
      ),
      editable: false,
      sortable: false,
    },
  ];

  const locationZoneRows = locationZoneStore.locationZones.map((locationZone) => ({
    id: locationZone.id,
    name: locationZone.name,
    description: locationZone.description,
    color: locationZone.color,
    createdAt: locationZone.createdAt && format(new Date(locationZone.createdAt), 'PP'),
  }));

  return (
    <div className={classes.propertiesContainer}>
      <Typography variant="h3">Location Zones</Typography>
      <div className={classes.buttonContainer}>
        <Button
          variant="contained"
          color="primary"
          onClick={locationZoneStore.addLocationZone}
        >
          Add Location Zone
        </Button>
      </div>
      <div className={classes.propertiesTableContainer}>
        <DataGrid
          rows={locationZoneRows}
          columns={columns}
          pageSize={20}
          disableSelectionOnClick
          onCellEditCommit={locationZoneStore.updateLocationZone}
        />
      </div>
    </div>
  );
};

LocationZones.propTypes = {
  locationZoneStore: PropTypes.any,
};

export default observer(LocationZones);
