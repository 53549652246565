import {
  Box, Breadcrumbs, CircularProgress, Grid, Link, Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import WushButtonCard from '../../../components/WushButtonCard';
import config from '../../../config';
import RequestService from '../../../services/RequestService';

const EventSeriesRegistrations = () => {
  const [eventSeries, setEventSeries] = useState(null);
  const match = useRouteMatch();
  const history = useHistory();
  const { eventSeriesId } = match.params;

  const fetchEvent = () => {
    RequestService.get(`${config.backendUrl}/event_series/${eventSeriesId}`, (response) => {
      response.data.cost = response.data.costCents / 100;
      setEventSeries(response.data);
    });
  };

  if (!eventSeries) {
    fetchEvent();
    return <CircularProgress />;
  }

  return (
    <Box pt={2}>
      <Breadcrumbs aria-label="breadcrumb" mb={2}>
        <Link underline="hover" color="inherit" href="/event-management/event-series">
          Event Series
        </Link>
        <Link underline="hover" color="inherit" href={`/event-management/event-series/${eventSeriesId}`}>
          {eventSeries.name}
        </Link>
        <Typography color="text.primary">
          Registrations
        </Typography>
      </Breadcrumbs>
      <Box>
        <Typography variant="h6">Registrations</Typography>
        {
          eventSeries.registrations.map((registration) => (
            <Box mb={2}>
              <WushButtonCard
                onClick={() => history.push(`/event-management/event-series/${eventSeriesId}/registrations/${registration.customerId}`)}
              >
                <Grid container>
                  <Grid item md={1}>
                    ID:
                    {' '}
                    {registration.customerId}
                  </Grid>
                  <Grid item md={3}>
                    {registration.customerFirstName}
                    {' '}
                    {registration.customerLastName}
                  </Grid>
                  <Grid item md={3}>
                    {registration.customerEmail}
                  </Grid>
                  <Grid item md={3}>
                    Events purchased:
                    {' '}
                    <strong>{registration.eventCount}</strong>
                  </Grid>
                </Grid>

              </WushButtonCard>
            </Box>
          ))
        }
      </Box>
    </Box>
  );
};

export default EventSeriesRegistrations;
