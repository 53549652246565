import React, { useState, useEffect } from 'react';
import {
  Box, Typography, CircularProgress, Button,
} from '@mui/material';

import { Formik, Form } from 'formik';
import { useHistory } from 'react-router';
import WushTable from '../../../../components/WushTable';
import RequestService from '../../../../services/RequestService';
import config from '../../../../config';
import WushModal from '../../../../components/WushModal';
import WushInputV2 from '../../../events/components/WushInputV2';

const Subscriptions = () => {
  const [subscriptions, setSubscriptions] = useState(null);
  const [subscriptionsLoading, setSubscriptionsLoading] = useState(true);

  const [showCreateSubscriptionModal, setShowCreateSubscriptionModal] = useState(false);

  const history = useHistory();

  const fetchSubscriptions = () => {
    setSubscriptionsLoading(true);
    RequestService.get(`${config.backendUrl}/subscriptions`, (response) => {
      setSubscriptions(response.data);
      setSubscriptionsLoading(false);
    });
  };

  const handleSubmit = ({ name, description, cost }) => {
    RequestService.post(`${config.backendUrl}/subscriptions`, {
      subscription: {
        name,
        description,
        cost,
      },
    }, () => {
      fetchSubscriptions();
      setShowCreateSubscriptionModal(false);
    });
  };

  useEffect(() => {
    fetchSubscriptions();
  }, []);

  if (subscriptionsLoading) return <CircularProgress />;

  return (
    <Box py={2}>
      <Typography variant="h3">Subscriptions</Typography>
      <Button onClick={() => setShowCreateSubscriptionModal(true)} variant="contained" color="primary">
        Add Subscription
      </Button>
      <WushTable
        headers={[
          { name: 'name', label: 'Name' },
          { name: 'description', label: 'Description' },
          { name: 'costDollars', label: 'Cost' },
        ]}
        data={subscriptions}
        onRowClick={(subscription) => history.push(`/inventory-management/subscriptions/${subscription.id}`)}
      />
      <WushModal
        open={showCreateSubscriptionModal}
        onClose={() => setShowCreateSubscriptionModal(false)}
      >
        <Formik
          initialValues={{
            name: '',
            description: '',
            cost: 0,
          }}
          onSubmit={handleSubmit}
        >
          {
            (formik) => (
              <Form>
                <WushInputV2
                  formik={formik}
                  name="name"
                  label="Name"
                />
                <WushInputV2
                  formik={formik}
                  name="description"
                  label="Description"
                />
                <WushInputV2
                  formik={formik}
                  name="cost"
                  label="Monthly Cost"
                  inputType="money"
                />
                <Button variant="contained" onClick={formik.handleSubmit}>
                  Save
                </Button>
              </Form>
            )
          }
        </Formik>
      </WushModal>
    </Box>
  );
};

export default Subscriptions;
