import React from 'react';
import ImportReservations from './containers/ImportReservations';
import NewReservation from './containers/NewReservation';
import Reservations from './Reservations';

export const PAGES = {
  NEW_RESERVATION : {
    title: "New Reservation",
    baseRoute: "new-reservation",
    component: <NewReservation />
  },
  RESERVATIONS: {
    title: "Reservations",
    baseRoute: "reservations",
    component: <Reservations />,
  },
  IMPORT: {
    title: "Import",
    baseRoute: "import",
    component: <ImportReservations />
  }
};