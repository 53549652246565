import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router';
import { Container } from '@mui/material';
import Reservation from './Reservation';
import Reservations from './Reservations';
import ReservationItems from './ReservationItems';
import NewReservation from './containers/NewReservation';
import Invoice from './containers/Invoice';
import ImportReservations from './containers/ImportReservations';
import NewImport from './containers/NewImport';
import ViewReservationImport from './containers/ViewReservationImport';

const ReservationManagement = (props) => {
  const match = useRouteMatch();
  return (
    <Container>
      <Switch>
        <Route path={`${match.path}/:reservationId/rental-items`}>
          <ReservationItems />
        </Route>
        <Route path={`${match.path}/new-reservation`}>
          <NewReservation {...props} />
        </Route>
        <Route path={`${match.path}/invoices/:reservationId`}>
          <Invoice {...props} />
        </Route>
        <Route path={`${match.path}/import/new`}>
          <NewImport {...props} />
        </Route>
        <Route path={`${match.path}/import/:reservationImportId`}>
          <ViewReservationImport {...props} />
        </Route>
        <Route path={`${match.path}/import`}>
          <ImportReservations {...props} />
        </Route>
        <Route path={`${match.path}/reservations/:reservationId`}>
          <Reservation {...props} />
        </Route>
        <Route path={`${match.path}/reservations`}>
          <Reservations {...props} />
        </Route>
      </Switch>
    </Container>
  );
};

export default ReservationManagement;
