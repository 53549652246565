import React from 'react';
import { styled } from '@mui/styles';
import {
  Box, Button, Container, IconButton, Toolbar, Typography,
} from '@mui/material';
import MuiAppBar from '@mui/material/AppBar';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import MenuIcon from '@mui/icons-material/Menu';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import SelectEmployeeMenu from './components/SelectEmployeeMenu';
import SearchInput from '../../components/SearchInput';

const drawerWidth = 200;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex?.drawer + 1,
  transition: theme.transitions?.create(['width', 'margin'], {
    easing: theme.transitions?.easing.sharp,
    duration: theme.transitions?.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Header = ({
  authStore, employeeStore, handleDrawerOpen, open, currentApp, searchStore,
}) => {
  if (authStore.loggedIn && authStore.accountType === 'admin') {
    employeeStore.getEmployees();
  }
  const history = useHistory();
  const location = useLocation();

  return (
    <AppBar position="fixed" open={open} className="hide-print" sx={{ boxShadow: 'none' }}>
      <Container maxWidth="xxl">
        <Toolbar disableGutters>
          {authStore.loggedIn && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              style={{
                marginRight: 5,
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
          )}
          {!open && (
            <Typography
              variant="h6"
              noWrap
              component="div"
              style={{ marginRight: '3rem' }}
            >
              <b>WUSH</b>
              Admin
            </Typography>
          )}
          <Box style={{
            display: 'flex', flexDirection: 'row', position: 'fixed', left: '250px',
          }}
          >
            {currentApp.pages && Object.keys(currentApp.pages).map((page) => (
              <Button
                variant={location.pathname === `/${currentApp.baseRoute}/${currentApp.pages[page].baseRoute}` ? 'contained' : ''}
                key={page}
                onClick={() => { history.push(currentApp.pages[page].directLink ? `/${currentApp.pages[page].directLink}` : `/${currentApp.baseRoute}/${currentApp.pages[page].baseRoute}`); }}
                style={{ marginRight: '3rem', color: 'white', display: 'flex' }}
              >
                {currentApp.pages[page].title}
              </Button>
            ))}
          </Box>
          {authStore.loggedIn && (
            <Box mr="10rem" style={{ position: 'fixed', right: '50px' }}>
              <SearchInput searchStore={searchStore} />
            </Box>
          )}
          {authStore.loggedIn && (
            <Box style={{ position: 'fixed', right: '50px' }}>
              <SelectEmployeeMenu authStore={authStore} employeeStore={employeeStore} />
            </Box>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
};

Header.propTypes = {
  authStore: PropTypes.any,
  employeeStore: PropTypes.any,
  handleDrawerOpen: PropTypes.func,
  open: PropTypes.bool,
  currentApp: PropTypes.string,
};

export default observer(Header);
