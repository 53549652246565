import {
  Box, Button, CircularProgress, Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import WushTable from '../../../components/WushTable';
import fetchReservationImports from '../../../services/reservationImports/fetchReservationImports';

const ImportReservations = () => {
  const history = useHistory();

  const [imports, setImports] = useState(null);

  useEffect(() => {
    fetchReservationImports((response) => {
      setImports(response.data);
    });
  }, []);

  if (!imports) return <CircularProgress />;

  return (
    <Box py={2}>
      <Box mb={2}>
        <Typography variant="h3">
          Imports
        </Typography>
      </Box>
      <Box mb={2}>
        <Button variant="contained" onClick={() => history.push('import/new')}>
          Start new import
        </Button>
      </Box>
      <WushTable
        data={imports}
        headers={[
          { label: 'ID', name: 'id' },
          { label: 'Created Date', name: 'created_at' },
        ]}
        onRowClick={(row) => history.push(`import/${row.id}`)}
      />
    </Box>
  );
};

export default ImportReservations;
