import { observable, action, decorate, computed } from "mobx";
import config from "../config";
import { ACCOUNT_TYPES, TEST_ORGANIZATION_ID } from "../constants";
import RequestService from "../services/RequestService";

class AuthStore {
  constructor() {
    this.buildFromSession();
  }

  loggedIn = false;
  loginFailure = false;
  loginLoading = false;
  accountType = null;
  organizationId = null;

  login = (email, password, successCallback, failureCallback) => {
    this.loginLoading = true;
    RequestService.post(
      `${config.backendUrl}/login`,
      { user: { email, password } },
      (response) => {
        this.loggedIn = true;
        this.loginFailure = false;
        this.loginLoading = false;
        this.accountType = response.data.account_type;
        this.organizationId = response.data.organization_id;
        this.writeToSession(response.headers.authorization);
        successCallback && successCallback();
      },
      (error) => {
        this.loginFailure = true;
        this.loginLoading = false;
        this.writeToSession();
        failureCallback && failureCallback();
      }
    );
  };

  logout = (successCallback) => {
    RequestService.delete(`${config.backendUrl}/logout`, () => {
      this.loggedIn = false;
      localStorage.clear();
      sessionStorage.clear();
      successCallback();
    });
  };

  writeToSession = (token) => {
    const json = JSON.stringify(this);
    localStorage.setItem("AuthStore", json);
    if (token) localStorage.setItem("Token", token);
  };

  buildFromSession = () => {
    const json = JSON.parse(localStorage.getItem("AuthStore"));
    if (json) {
      this.accountType = json.accountType;
      this.organizationId = json.organizationId;
      this.loggedIn = json.loggedIn;
    }
  };

  get isAdmin() {
    return this.accountType === ACCOUNT_TYPES.ADMIN;
  }

  get isTestOrganization() {
    return this.organizationId === TEST_ORGANIZATION_ID;
  }
}

decorate(AuthStore, {
  loggedIn: observable,
  login: action,
  loginFailure: observable,
  loginLoading: observable,
  isAdmin: computed,
  organizationId: observable,
  isTestOrganization: computed,
});

export default AuthStore;
