const mapWushEventToCalendar = ({id, startDatetime, endDatetime, name, description, costCents, ...rest}) => (
  {
    id: id,
    title: name,
    start: new Date(startDatetime),
    end: new Date(endDatetime),
    desc: description,
    ...rest,
    cost: costCents / 100
  }
)

const mapCalendarEventToWush = ({id, start, end, title, desc, ...rest}) => (
  {
    id: id,
    name: title,
    startDatetime: start,
    endDatetime: end,
    description: desc,
    ...rest
  }
)

export {mapCalendarEventToWush, mapWushEventToCalendar}