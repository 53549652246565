import {
  Box, Button, CircularProgress, Typography,
} from '@mui/material';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import WushCard from '../../../components/WushCard';

import RequestService from '../../../services/RequestService';

const ImageUploader = ({ endpoint, imageUrl, onSuccess }) => {
  const [uploading, setUploading] = useState(false);
  const handleImageUpdate = ({ image }) => {
    setUploading(true);
    const formData = new FormData();
    formData.append('image', image);
    RequestService.patch(endpoint, formData, (response) => {
      setUploading(false);
      if (onSuccess) onSuccess(response.data);
    });
  };

  return (
    <WushCard p={3}>
      <Box p={3}>
        <Typography variant="h5">Attach Image</Typography>
        <Formik
          initialValues={{
            image: '',
          }}
          onSubmit={handleImageUpdate}
        >
          {
            (formikProps) => (
              <Form>
                <input type="file" onChange={(event) => formikProps.setFieldValue('image', event.currentTarget.files[0])} />
                {uploading
                  ? <CircularProgress />
                  : <Button disabled={!formikProps.values.image} variant="contained" onClick={formikProps.handleSubmit}>Upload Image</Button>}
              </Form>
            )
          }
        </Formik>
        <Box mt={2}>
          <img width="200" src={imageUrl} alt="Item" />
        </Box>
      </Box>
    </WushCard>
  );
};

export default ImageUploader;
