import {
  Box, Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { Formik } from 'formik';
import { useHistory } from 'react-router';
import * as Yup from 'yup';
import RequestService from '../../../../services/RequestService';
import config from '../../../../config';
import EventSeriesForm from '../../components/EventSeriesForm';

const EventSeriesSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  description: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  visibleDate: Yup.string().required('Required'),
  capacity: Yup.string().min(1, 'Must have at least 1 capacity').required('Required'),
  eventCount: Yup.string().min(1).required('Must have at least one event'),
  eventCategoryId: Yup.string('Required').required('Required'),
  costMatrix: Yup.array()
    .of(
      Yup.object().shape({
        eventCount: Yup.number().min(1, 'Must be a number').required('Required'),
        cost: Yup.number().required('Required'),
      }),
    )
    .required('Required'),
});

const NewEventSeries = ({ eventCategoryStore, alertStore }) => {
  const history = useHistory();
  const [eventSeriesCreating, setEventSeriesCreating] = useState(false);

  const handleSubmit = (values) => {
    setEventSeriesCreating(true);
    RequestService.post(`${config.backendUrl}/event_series`, { eventSeries: values }, () => {
      setEventSeriesCreating(false);
      alertStore.setAlertMessage('Successfully created event series!');
      history.push('/event-management/events');
    });
  };
  return (
    <Box pt={2}>
      <Box mb={2}>
        <Typography variant="h3">Create event series</Typography>
        <Typography variant="body2">
          Use event series to create a package of events that customers can purchase. Ex. For a 12
          week class series, a customer may purchase this series and then be enrolled in 12 classes
          over the course of the series.
        </Typography>
      </Box>
      <Formik
        initialValues={{
          name: '',
          description: '',
          additionalInformation: '',
          startDatetime: new Date(),
          endDatetime: '',
          eventCount: '',
          eventCategoryId: null,
          events: [],
          visibleDate: '',
          costMatrix: [],
          capacity: '',
        }}
        validationSchema={EventSeriesSchema}
        onSubmit={handleSubmit}
      >
        {
          (formik) => (
            <EventSeriesForm
              formik={formik}
              eventCategoryStore={eventCategoryStore}
              showEvents
              eventSeriesCreating={eventSeriesCreating}
            />
          )
        }
      </Formik>
    </Box>
  );
};

export default NewEventSeries;
