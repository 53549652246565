import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import Loader from '../../../../components/loader/Loader';
import RequestService from '../../../../services/RequestService';
import config from '../../../../config';

const PropertiesDeliveryOrder = () => {
  const [properties, setProperties] = useState(null);

  const getPropertyOrder = () => {
    RequestService.get(`${config.backendUrlV2}/properties`, (response) => {
      setProperties(response.data);
    });
  };

  useEffect(() => {
    getPropertyOrder();
  }, []);

  const handleDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) return;
    if (source.index === destination.index) return;

    const newOrder = [...properties];
    const [movedRow] = newOrder.splice(source.index, 1);
    newOrder.splice(destination.index, 0, movedRow);
    setProperties(newOrder);

    RequestService.patch(
      `${config.backendUrlV2}/properties/${movedRow.id}`,
      {
        source_index: source.index,
        destination_index: destination.index,
      },
      () => {
      },
    );
  };

  if (!properties) {
    return (<Loader />);
  }

  return (
    <Box py={2}>
      <Typography variant="h3">Order Properties</Typography>
      <Box my={2}>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="table">
            {(provided) => (
              <TableContainer
                {...provided.droppableProps}
                ref={provided.innerRef}
                component={Paper}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ fontWeight: 'bold' }}>Delivery Order</TableCell>
                      <TableCell style={{ fontWeight: 'bold' }}>Property Address</TableCell>
                      <TableCell style={{ fontWeight: 'bold' }}>Property Name</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {properties.map((row, index) => row.id && (
                      <Draggable key={row.id} draggableId={row.id.toString()} index={index}>
                        {/* eslint-disable-next-line */}
                        {(provided) => (
                          <TableRow
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                          >
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>
                              {`${row.address_1}, ${row.city}, ${row.state} ${row.zip}`}
                            </TableCell>
                            <TableCell>{row.name}</TableCell>
                          </TableRow>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Droppable>
        </DragDropContext>
      </Box>
    </Box>
  );
};

export default PropertiesDeliveryOrder;
