import {
  Autocomplete, Box, Button, CircularProgress, Dialog, TextField, Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router';
import { Formik, Form } from 'formik';
import { observer } from 'mobx-react';
import WushTable from '../../../../components/WushTable';
import fetchWaiver from '../../../../services/waivers/fetchWaiver';
import createCustomerWaiver from '../../../../services/waivers/createCustomerWaiver';

const ViewWaiver = ({ customerStore }) => {
  const match = useRouteMatch();
  const { waiverId } = match.params;

  const [waiver, setWaiver] = useState(null);
  const [showNewWaiver, setShowNewWaiver] = useState(false);

  const populateWaiver = () => {
    fetchWaiver(waiverId, (response) => {
      setWaiver(response.data);
      customerStore.getCustomers();
    });
  };

  useEffect(() => {
    setShowNewWaiver(false);
    populateWaiver();
  }, []);

  if (!waiver) return <CircularProgress />;

  return (
    <Box pt={2}>
      <Box>
        <Typography variant="h4">{waiver.name}</Typography>
      </Box>
      <Box>
        <Button variant="contained" onClick={() => setShowNewWaiver(true)}>
          + Add
        </Button>
      </Box>
      <Box>
        <Box>
          <Typography variant="h6">
            Waivers
          </Typography>
        </Box>
        <Box>
          <WushTable
            headers={[
              { label: 'Id', customGetter: (customer_waiver) => customer_waiver.id },
              {
                label: 'Name',
                customGetter: (customer_waiver) => (
                  `${customer_waiver.customer.first_name} ${customer_waiver.customer.last_name}`
                ),
              },
              { label: 'Url', customGetter: (customer_waiver) => `/waivers/${customer_waiver.uuid}` },
              {
                label: 'Signature',
                customGetter: ({ signed_at, signed_by_image_url }) => (
                  signed_at ? <img height="20px" src={signed_by_image_url} alt="signature" /> : 'Not signed'
                ),
              },
            ]}
            data={waiver.customer_waivers}
          />
        </Box>
      </Box>
      <Box>
        <Typography variant="h6">{waiver.description}</Typography>
      </Box>
      <Box>
        <Typography variant="h6">{waiver.uuid}</Typography>
      </Box>
      <Box>
        {/* eslint-disable-next-line react/no-danger */}
        <div dangerouslySetInnerHTML={{ __html: waiver.content }} />
      </Box>

      <Dialog
        open={showNewWaiver}
        onClose={() => setShowNewWaiver(false)}
      >
        <Box>
          <Formik
            initialValues={{
              customer_id: null,
              waiver_id: waiver.id,
            }}
            onSubmit={(values) => {
              createCustomerWaiver(values, () => {
                populateWaiver();
              });
            }}
          >
            {
              (formik) => (
                <Form>
                  <Box p={3}>
                    <Box>
                      <Typography variant="h4">
                        Add customer waiver
                      </Typography>
                    </Box>
                    <Box>
                      <Autocomplete
                        options={customerStore.customers}
                        getOptionLabel={(option) => (
                          (option.firstName && `${option.firstName} ${option.lastName}`)
                            || customerStore.customers.filter((customer) => (
                              parseInt(customer.id, 10) === parseInt(option, 10)
                            ))[0]?.firstName
                        )}
                        getOptionSelected={(option, value) => (
                          parseInt(option.id, 10) === parseInt(value, 10)
                        )}
                        style={{ width: 300 }}
                        /* eslint-disable-next-line */
                        value={formik.values.customer_id}
                        /* eslint-disable no-shadow */
                        renderInput={(params) => <TextField {...params} label="Customer" />}
                        onChange={(_event, value) => {
                          formik.setFieldValue('customer_id', value.id);
                        }}
                      />
                    </Box>
                    <Box>
                      <Button variant="contained" onClick={formik.handleSubmit}>
                        Add
                      </Button>
                    </Box>
                  </Box>
                </Form>
              )
            }
          </Formik>
        </Box>
      </Dialog>
    </Box>
  );
};

export default observer(ViewWaiver);
