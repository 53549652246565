import {
  Typography, LinearProgress, Button, IconButton, Input, Box, Autocomplete, TextField,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { DataGrid } from '@material-ui/data-grid';
import { observer } from 'mobx-react';
import { format } from 'date-fns';
import React from 'react';
import PropTypes from 'prop-types';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import { useHistory } from 'react-router';
import Fuse from 'fuse.js';
// eslint-disable-next-line import/no-cycle
import { APPS } from '../../../../constants';

const useStyles = makeStyles(() => ({
  propertiesTableContainer: {
    height: '1000px',
  },
  propertiesContainer: {
    paddingTop: '2rem',
    paddingBottom: '2rem',
  },
  buttonContainer: {
    marginBottom: '1rem',
  },
  clientsCombo: {
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingTop: '8px',
  },
}));

const Properties = ({
  propertyStore, clientStore, locationZoneStore,
}) => {
  const classes = useStyles();
  const history = useHistory();
  propertyStore.getProperties();

  const [searchParam, setSearchParam] = React.useState('');

  if (!propertyStore.propertiesPreLoaded) {
    return (<LinearProgress />);
  }
  clientStore.getClients();
  if (!clientStore.clientsPreLoaded) {
    return (<LinearProgress />);
  }
  locationZoneStore.getLocationZones();
  if (!locationZoneStore.locationZonesPreLoaded) {
    return (<LinearProgress />);
  }
  const { clients } = clientStore;
  const { locationZones } = locationZoneStore;

  const fuseOptions = { keys: ['name', 'address1'] };
  const fuse = new Fuse(propertyStore.properties, fuseOptions);
  const searchedProperties = searchParam === '' ? propertyStore.properties : fuse.search(searchParam).map((item) => item.item);

  const columns = [
    {
      field: 'property',
      headerName: '',
      width: 50,
      renderCell: (params) => (
        <IconButton
          size="small"
          onClick={() => {
            history.push(`/property-management/properties/edit/${params.id}`);
          }}
        >
          <EditOutlinedIcon />
        </IconButton>
      ),
      editable: false,
      sortable: false,
    },
    {
      field: 'edit',
      headerName: '',
      width: 50,
      renderCell: (params) => (
        <IconButton
          size="small"
          onClick={() => {
            history.push(`/${APPS.PROPERTIES.baseRoute}/${APPS.PROPERTIES.pages.PROPERTIES.baseRoute}/${params.id}`);
          }}
        >
          <Inventory2OutlinedIcon />
        </IconButton>
      ),
      editable: false,
      sortable: false,
    },
    {
      field: 'clientId',
      headerName: 'Client',
      width: 300,
      renderCell: (params) => (
        <Autocomplete
          options={clients}
          getOptionLabel={(option) => (
            (option && option.name)
              || clientStore.clients.filter((client) => client.id === option)[0]?.name
          )}
          getOptionSelected={(option, value) => option.name === value}
          className={classes.clientsCombo}
          style={{ width: 300 }}
          /* eslint-disable-next-line */
          value={params.value}
          /* eslint-disable no-shadow */
          renderInput={(params) => <TextField {...params} label="Client" />}
          onChange={(_event, value) => {
            const updatedParams = { ...params };
            updatedParams.value = value?.id;
            propertyStore.updateProperty(updatedParams);
          }}
        />
      ),
      editable: false,
      sortable: false,
    },
    {
      field: 'name',
      headerName: 'Name',
      width: 150,
      editable: true,
    },
    {
      field: 'address1',
      headerName: 'Address 1',
      width: 150,
      editable: true,
    },
    {
      field: 'address2',
      headerName: 'Address 2',
      width: 150,
      editable: true,
    },
    {
      field: 'city',
      headerName: 'City',
      width: 150,
      editable: true,
    },
    {
      field: 'state',
      headerName: 'State',
      width: 150,
      editable: true,
    },
    {
      field: 'zip',
      headerName: 'Zip',
      width: 150,
      editable: true,
    },
    {
      field: 'legacyId',
      headerName: 'Legacy ID',
      width: 150,
      editable: true,
    },
    {
      field: 'locationZoneId',
      headerName: 'Zone',
      width: 300,
      renderCell: (params) => (
        <Autocomplete
          options={locationZones}
          getOptionLabel={(option) => (
            (option && option.name)
              || locationZoneStore.locationZones.filter(
                (locationZone) => locationZone.id === option,
              )[0]?.name
          )}
          getOptionSelected={(option, value) => option.name === value}
          className={classes.clientsCombo}
          style={{ width: 300 }}
          /* eslint-disable-next-line */
          value={params.value}
          /* eslint-disable no-shadow */
          renderInput={(params) => <TextField {...params} label="Zone" />}
          onChange={(_event, value) => {
            const updatedParams = { ...params };
            updatedParams.value = value?.id;
            propertyStore.updateProperty(updatedParams);
          }}
        />
      ),
      editable: false,
      sortable: false,
    },
    {
      field: 'sleeps',
      headerName: 'Number of Guests',
      width: 150,
      editable: true,
    },
    {
      field: 'pricePerPerson',
      headerName: 'Price per Person',
      width: 150,
      editable: true,
    },
    {
      field: 'createdAt',
      headerName: 'Created At',
      width: 150,
      editable: false,
      type: 'date',
    },
    {
      field: 'Actions',
      headerName: '',
      width: 100,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="secondary"
          onClick={() => propertyStore.deleteProperty(params.id)}
        >
          Delete
        </Button>
      ),
      editable: false,
      sortable: false,
    },
  ];

  const propertyRows = searchedProperties.map((property) => ({
    property,
    id: property.id,
    clientId: property.clientId,
    name: property.name,
    address1: property.address1,
    address2: property.address2,
    city: property.city,
    state: property.state,
    zip: property.zip,
    sleeps: property.sleeps,
    pricePerPerson: property.pricePerPerson,
    locationZoneId: property.locationZoneId,
    legacyId: property.legacyId,
    createdAt: property.createdAt && format(new Date(property.createdAt), 'PP'),
  }));

  return (
    <div className={classes.propertiesContainer}>
      <Typography variant="h3">Properties</Typography>
      <div>
        <Button
          variant="contained"
          color="primary"
          onClick={propertyStore.addProperty}
        >
          Add Property
        </Button>
      </div>
      <Box mb={2} mt={2}>
        <Input
          variant="contained"
          onChange={(event) => setSearchParam(event.target.value)}
          placeholder="Search property..."
        />
      </Box>
      <div className={classes.propertiesTableContainer}>
        <DataGrid
          rows={propertyRows}
          columns={columns}
          pageSize={20}
          disableSelectionOnClick
          onCellEditCommit={propertyStore.updateProperty}
        />
      </div>
    </div>
  );
};

Properties.propTypes = {
  propertyStore: PropTypes.any,
  clientStore: PropTypes.any,
};

export default observer(Properties);
