import {
  Box,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';
import React from 'react';

import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import ContactsOutlinedIcon from '@mui/icons-material/ContactsOutlined';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import PhoneIphoneOutlinedIcon from '@mui/icons-material/PhoneIphoneOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import CommentOutlinedIcon from '@mui/icons-material/CommentOutlined';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

const ReservationOverlay = ({ reservation }) => (
  <Grid container className="reservation-overlay">
    <Grid container className="header" alignItems="center">
      <Grid item xs={8}>
        <h4>
          Order Number:
          {reservation.id}
        </h4>
      </Grid>
      <Grid item xs={4} display="flex" flexDirection="row">
        <AccessTimeIcon />
        <p>Not Scheduled</p>
      </Grid>
    </Grid>

    <Grid container className="reservation-details">
      <Grid item xs={2} alignItems="center">
        <Box height="50%" display="flex" flexDirection="row" alignItems="center">
          <Box>
            <HomeOutlinedIcon className="icon-outline blue" />
          </Box>
          <Box className="text-elements">
            <h6>Property</h6>
            <p>{reservation.propertyName}</p>
          </Box>
        </Box>
        <Box height="50%" display="flex" flexDirection="row" alignItems="center">
          <Box>
            <PlaceOutlinedIcon className="icon-outline blue" />
          </Box>
          <Box className="text-elements">
            <h6>Address</h6>
            <p>{reservation.propertyAddress}</p>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={2} alignItems="center">
        <Box height="50%" display="flex" flexDirection="row" alignItems="center">
          <Box>
            <MapOutlinedIcon className="icon-outline blue" />
          </Box>
          <Box className="text-elements">
            <h6>Zone</h6>
            <p>{reservation.propertyZoneName}</p>
          </Box>
        </Box>
        <Box height="50%" display="flex" flexDirection="row" alignItems="center">
          <Box>
            <ContactsOutlinedIcon className="icon-outline blue" />
          </Box>
          <Box className="text-elements">
            <h6>Client</h6>
            <p>{reservation.clientName}</p>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={2} alignItems="center">
        <Box height="50%" display="flex" flexDirection="row" alignItems="center">
          <Box>
            <PermIdentityOutlinedIcon className="icon-outline white" />
          </Box>
          <Box className="text-elements">
            <h6>Customer</h6>
            <p>{reservation.customerName}</p>
          </Box>
        </Box>
        <Box height="50%" display="flex" flexDirection="row" alignItems="center">
          <Box>
            <PlaceOutlinedIcon className="icon-outline white" />
          </Box>
          <Box className="text-elements">
            <h6>Home Address</h6>
            <p>
              {reservation.customerAddress === null ? 'Not in file' : reservation.customerAddress}
            </p>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={2} alignItems="center">
        <Box height="50%" display="flex" flexDirection="row" alignItems="center">
          <Box>
            <PhoneIphoneOutlinedIcon className="icon-outline white" />
          </Box>
          <Box className="text-elements">
            <h6>Mobile</h6>
            <p>
              {reservation.customerPhone === null ? 'Not in file' : reservation.customerPhone}
              {' '}
            </p>
          </Box>
        </Box>
        <Box height="50%" display="flex" flexDirection="row" alignItems="center">
          <Box>
            <EmailOutlinedIcon className="icon-outline white" />
          </Box>
          <Box className="text-elements">
            <h6>Email</h6>
            <p>{reservation.customerEmail === null ? 'Not in file' : reservation.customerEmail}</p>
          </Box>
        </Box>
      </Grid>

      <Grid item xs={4}>
        <Box height="100%" display="flex" flexDirection="row" alignItems="flex-start" my={2}>
          <Box>
            <CommentOutlinedIcon className="icon-outline blue" />
          </Box>
          <Box className="text-elements">
            <h6>Comments</h6>
            {reservation.comments.map((comment) => (
              <Box>
                <p>{comment}</p>
              </Box>
            ))}
          </Box>
        </Box>
      </Grid>
    </Grid>

    <Grid container className="item-details">
      <Grid item xs={12}>
        <Box>
          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <p>Items</p>
                  </TableCell>
                  <TableCell align="center">
                    <p>Dates</p>
                  </TableCell>
                  <TableCell align="center">
                    <p>Quantity</p>
                  </TableCell>
                  <TableCell align="center">
                    <p>Price</p>
                  </TableCell>
                  <TableCell align="center">
                    <p>Total</p>
                  </TableCell>
                  <TableCell align="center">
                    <p>Status</p>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {reservation.reservationItems.map((item) => (
                  <TableRow
                    key={item.itemName}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {item.itemName}
                    </TableCell>
                    <TableCell align="center">
                      {item.startDate}
                      {' '}
                      to
                      {' '}
                      {item.endDate}
                    </TableCell>
                    <TableCell align="center">{item.reservedQuantity}</TableCell>
                    <TableCell align="center">{item.price}</TableCell>
                    <TableCell align="center">
                      $
                      {item.price * item.reservedQuantity}
                    </TableCell>
                    <TableCell align="center">{item.status}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Grid>
    </Grid>
  </Grid>
);

export default ReservationOverlay;
