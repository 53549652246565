import React, { useState } from 'react';
import {
  Box, Breadcrumbs, Link, Typography, CircularProgress, Button,
} from '@mui/material';

import { useHistory, useRouteMatch } from 'react-router';
import { format } from 'date-fns';
import RequestService from '../../../services/RequestService';
import config from '../../../config';
import WushButtonCard from '../../../components/WushButtonCard';
import WushModal from '../../../components/WushModal';
import EventRegistrationReschedule from '../components/EventRegistrationReschedule';
import EventTiles from '../components/EventTiles';

const EventSeriesCustomerRegistrations = () => {
  const [eventSeries, setEventSeries] = useState(null);
  const [showRegistrationModal, setShowRegistrationModal] = useState(null);
  const [viewingRegistration, setViewingRegistration] = useState(null);
  const [reschedulingEvent, setReschedulingEvent] = useState(false);
  const [showEnrollModal, setShowEnrollModal] = useState(false);
  const match = useRouteMatch();
  const history = useHistory();
  const { eventSeriesId, customerId } = match.params;

  const fetchCustomerRegistrations = () => {
    RequestService.get(`${config.backendUrl}/event_series/${eventSeriesId}/${customerId}`, (response) => {
      setEventSeries(response.data);
    });
  };

  const handleUnenroll = () => {
    RequestService.delete(`${config.backendUrl}/event_registrations/${viewingRegistration.registrationId}`, () => {
      setShowRegistrationModal(false);
      fetchCustomerRegistrations();
    });
  };

  const handleUnenrollFromSeries = () => {
    RequestService.delete(`${config.backendUrl}/event_series_registrations/${eventSeries.registration.id}`, () => {
      setShowRegistrationModal(false);
      history.push(`/event-management/event-series/${eventSeries.id}`);
      fetchCustomerRegistrations();
    });
  };

  const handleEnroll = (eventId) => {
    RequestService.post(`${config.backendUrl}/event_registrations`,
      {
        eventRegistration: {
          customerId,
          eventId,
        },
      },
      () => {
        fetchCustomerRegistrations();
      });
  };

  if (!eventSeries) {
    fetchCustomerRegistrations();
    return <CircularProgress />;
  }

  return (
    <Box pt={2}>
      <Breadcrumbs aria-label="breadcrumb" mb={2}>
        <Link underline="hover" color="inherit" href="/event-management/event-series">
          Event Series
        </Link>
        <Link underline="hover" color="inherit" href={`/event-management/event-series/${eventSeriesId}`}>
          {eventSeries.name}
        </Link>
        <Link underline="hover" color="inherit" href={`/event-management/event-series/${eventSeriesId}/registrations`}>
          Registrations
        </Link>
        <Typography color="text.primary">
          {eventSeries.registration.customerFirstName}
        </Typography>
      </Breadcrumbs>
      <Box>
        <Button onClick={() => history.push(`/event-management/customers/${eventSeries.registration.customerId}`)}>
          View Customer
        </Button>
      </Box>
      <Box>
        <Button disabled={eventSeries.enrolledEvents.length > 0} variant="contained" color="secondary" onClick={handleUnenrollFromSeries}>
          Unenroll From Series
        </Button>
      </Box>
      <Box mt={2}>
        <Typography variant="h6">Enrolled Events</Typography>
        {eventSeries.events.map((event) => event.userEnrolled && (
          <Box mb={2}>
            <WushButtonCard
              onClick={() => {
                setViewingRegistration(event);
                setShowRegistrationModal(true);
              }}
            >
              <Box>
                {event.name}
                {' on '}
                {format(new Date(event.startDatetime), 'ccc MM/dd/yyyy hh:mm b')}
              </Box>
            </WushButtonCard>
          </Box>
        ))}
      </Box>
      <Box>
        <Button variant="contained" onClick={() => setShowEnrollModal(true)}>Enroll in Event</Button>
      </Box>
      <WushModal
        open={showRegistrationModal}
        onClose={() => setShowRegistrationModal(false)}
      >
        {viewingRegistration && (
          <Box>
            <Box mb={2} textAlign="center">
              <Typography variant="h6">
                {eventSeries.registration.customerFirstName}
                s
                {' '}
                registration for
                {' '}
                {viewingRegistration.name}
                {' '}
                at
                {' '}
                {format(new Date(viewingRegistration.startDatetime), 'ccc MM/dd/yyyy hh:mm b')}
              </Typography>
            </Box>
            {!reschedulingEvent && (
              <Box px="10rem" mb={2}>
                <Button fullWidth variant="contained" color="primary" onClick={() => setReschedulingEvent(true)}>Reschedule</Button>
              </Box>
            )}
            {!reschedulingEvent && (
              <Box px="10rem">
                <Button
                  fullWidth
                  variant="contained"
                  color="secondary"
                  onClick={() => handleUnenroll()}
                >
                  Unenroll
                </Button>
              </Box>
            )}
            {reschedulingEvent && (
              <Box>
                <EventRegistrationReschedule
                  currentEvent={viewingRegistration}
                  availableEvents={eventSeries.events}
                  handleRescheduleSuccess={() => {
                    fetchCustomerRegistrations(); setShowRegistrationModal(false);
                  }}
                />
              </Box>
            )}
          </Box>
        )}
      </WushModal>
      <WushModal
        open={showEnrollModal}
        onClose={() => setShowEnrollModal(false)}
      >
        <Box>
          <Typography variant="h6">Available Events</Typography>
          <EventTiles events={eventSeries.events} onClick={(event) => handleEnroll(event.id)} />
        </Box>
      </WushModal>
    </Box>
  );
};

export default EventSeriesCustomerRegistrations;
