import {
  Box, CircularProgress, Button, TextField, Typography, Autocomplete, Switch, Stack,
} from '@mui/material';
import { format } from 'date-fns';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';

import WushModal from '../../../../components/WushModal';
import WushCard from '../../../../components/WushCard';
import WushTable from '../../../../components/WushTable';
import config from '../../../../config';
import RequestService from '../../../../services/RequestService';

const Dashboard = ({ authStore }) => {
  const [organizations, setOrganizations] = useState(null);
  const [organizationId, setOrganizationId] = useState(null);
  const [logs, setLogs] = useState(null);
  const [logsLoading, setLogsLoading] = useState(false);
  const [selectedLog, setSelectedLog] = useState(null);
  const [showLog, setShowLog] = useState(false);
  const [publicAppEnabled, setPublicAppEnabled] = useState(null);

  const history = useHistory();

  const fetchLogs = () => {
    setLogsLoading(true);
    RequestService.get(`${config.backendUrl}/super_admin/log_events`, (response) => {
      setLogs(response.data);
      setLogsLoading(false);
    });
  };

  const fetchOrganizations = () => {
    RequestService.get(`${config.backendUrl}/public/organizations`, (response) => {
      setOrganizations(response.data);
    });
  };

  const updateOrganization = () => {
    RequestService.post(`${config.backendUrl}/super_admin/organization`, { organizationId }, () => {
      authStore.logout(() => history.push('/login'));
    });
  };

  const fetchPublicAppEnabled = () => {
    RequestService.get(`${config.backendUrl}/public-app-enabled`, (response) => {
      setPublicAppEnabled(response.data.enabled);
    });
  };

  const togglePublicAppEnabled = () => {
    RequestService.post(`${config.backendUrl}/toggle-public-app-enabled`, {}, () => {
      setPublicAppEnabled(!publicAppEnabled);
    });
  };

  useEffect(() => {
    fetchLogs();
    fetchOrganizations();
    fetchPublicAppEnabled();
  }, []);

  if (!logs || logsLoading) return <CircularProgress />;

  return (
    <Box pt={2}>
      <Box>
        <WushCard>
          <Box>
            <Typography variant="h6">KILL SWITCH!!!</Typography>
            <Typography variant="body1">This will turn off the public application</Typography>
            <Stack direction="row">
              <Typography>Off</Typography>
              <Switch checked={publicAppEnabled} onChange={togglePublicAppEnabled} />
              <Typography>On</Typography>
            </Stack>
          </Box>
        </WushCard>
        <Typography variant="h6">Change Organization</Typography>
        <Box>
          <Autocomplete
            id="kit-combo-box"
            options={organizations}
            getOptionLabel={(option) => (option.name)}
            renderInput={(params) => <TextField {...params} label="Please select" variant="outlined" />}
            onChange={(event, value) => setOrganizationId(value)}
          />
          <Button onClick={updateOrganization}>
            Update
          </Button>
        </Box>
      </Box>
      <Box>
        <Button onClick={fetchLogs}>
          Refresh
        </Button>
      </Box>
      <WushTable
        headers={[
          { label: 'Id', name: 'id' },
          { label: 'Description', name: 'description' },
          { label: 'Timestamp', customGetter: (row) => (format(new Date(row.createdAt), 'MM/dd hh:mm aaaa')) },
        ]}
        data={logs}
        onRowClick={(row) => {
          setSelectedLog(row);
          setShowLog(true);
        }}
      />
      <WushModal
        open={showLog}
        onClose={() => setShowLog(false)}
      >
        {
          selectedLog && (
            <Box>
              <Typography variant="h6">More info</Typography>
              {JSON.stringify(selectedLog.rawEvent)}
            </Box>
          )
        }
      </WushModal>
    </Box>
  );
};

export default Dashboard;
