import React from 'react';
import Deliveries from './containers/deliveries/Deliveries';
import DeliveryRoutes from './containers/deliveries/DeliveryRoutes';
import PickupRoutes from './containers/pickups/PickupRoutes';
import Pickups from './containers/pickups/Pickups';
import DeliveryRouteInProgress from './containers/deliveries/DeliveryRouteInProgress';

export const PAGES = {
  DELIVERIES: {
    title: "Deliveries",
    baseRoute: "deliveries",
    component: <Deliveries />
  },
  DELIVERY_ROUTES: {
    title: "Delivery Routes",
    baseRoute: "delivery-routes",
    component: <DeliveryRoutes />
  },
  DELIVERY_ROUTES_IN_PROGRESS: {
    title: "In Progress",
    baseRoute: "delivery-routes/in-progress",
    component: <DeliveryRouteInProgress/>
  },
  PICKUPS: {
    title: "Pickups",
    baseRoute: "pickups",
    component: <Pickups />
  },
  PICKUP_ROUTES: {
    title: "Pickup Routes",
    baseRoute: "pickup-routes",
    component: <PickupRoutes />
  }
};