import {
  Button, Divider, IconButton, Menu, MenuItem, TextField,
  Autocomplete, Box, ListItemIcon,
} from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { makeStyles } from '@mui/styles';
import HomeIcon from '@mui/icons-material/Home';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';

const useStyles = makeStyles(() => ({
  employeeName: {
    color: 'white',
  },
  employeeCombo: {
    width: 200,
    margin: '.5rem',
  },
}));

const SelectEmployeeMenu = ({ employeeStore, authStore }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const history = useHistory();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      id="account-menu"
      open={open}
      onClose={handleClose}
      onClick={handleClose}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: 'visible',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
          mt: 1.5,
          '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      {
        employeeStore.employeesPreLoaded && (
          <Autocomplete
            id="combo-box-demo"
            options={employeeStore.employees}
            getOptionLabel={(option) => (option.firstName ? `${option.firstName} ${option.lastName}` : '')}
            className={classes.employeeCombo}
            renderInput={(params) => <TextField {...params} label="Please select" variant="outlined" />}
            value={employeeStore.currentEmployee}
            onChange={(_event, value) => {
              employeeStore.setCurrentEmployee(value); handleMenuClose();
            }}
          />
        )
      }
      <Box mb={1}>
        <Divider />
      </Box>
      <Box textAlign="left">
        <MenuItem onClick={() => history.push('/home')}>
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          Home
        </MenuItem>
        <MenuItem onClick={() => history.push('/settings')}>
          <ListItemIcon>
            <Settings />
          </ListItemIcon>
          Settings
        </MenuItem>
        <MenuItem onClick={() => authStore.logout(() => { window.location = '/login'; })}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Box>
    </Menu>
  );

  return (
    <>
      <IconButton
        edge="end"
        onClick={handleProfileMenuOpen}
        color="inherit"
      >
        <AccountCircle />
      </IconButton>
      {employeeStore.currentEmployee?.firstName && (
        <Button onClick={handleProfileMenuOpen} className={classes.employeeName}>
          {` ${employeeStore.currentEmployee.firstName} ${employeeStore.currentEmployee.lastName}`}
        </Button>
      )}
      {renderMenu}
    </>
  );
};

SelectEmployeeMenu.propTypes = {
  employeeStore: PropTypes.any,
  authStore: PropTypes.any,
};

export default SelectEmployeeMenu;
