import {
  Box, Button, CircularProgress, Grid, Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import WaiverCard from '../../../../components/cards/WaiverCard';
import fetchWaivers from '../../../../services/waivers/fetchWaivers';

const Waivers = () => {
  const [waivers, setWaivers] = useState(null);

  const history = useHistory();

  useEffect(() => {
    fetchWaivers((response) => {
      setWaivers(response.data);
    });
  }, []);

  if (!waivers) return <CircularProgress />;

  return (
    <Box py={2}>
      <Box>
        <Typography variant="h3">
          Waivers
        </Typography>
      </Box>
      <Box>
        <Button onClick={() => history.push('waivers/new')}>
          Create a new waiver
        </Button>
      </Box>
      <Box>
        <Grid container>
          {waivers.map((waiver) => (
            <Grid item md={3}>
              <Box px={2}>
                <WaiverCard waiver={waiver} />
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default Waivers;
