import { Card } from '@mui/material';
import React from 'react';

const BaseCard = ({ children }) => (
  <Card className="wush-card">
    {children}
  </Card>
);

export default BaseCard;
