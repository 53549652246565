export const PAGES = {
  EVENTS: {
    title: "Events",
    baseRoute: "events"
  },
  EVENT_CATEGORIES: {
    title: "Event Categories",
    baseRoute: "event-categories"
  },
  EVENT_SERIES: {
    title: "Event Series",
    baseRoute: "event-series"
  },
  EVENT_CUSTOMERS: {
    title: "Customers",
    baseRoute: "customers"
  },
  WAIVERS: {
    title: "Waivers",
    baseRoute: "waivers"
  }
};