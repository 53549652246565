import React, { useState, useEffect } from 'react';
import { useRouteMatch, useHistory } from 'react-router';
import { Box, Typography } from '@mui/material';
import { DataGrid } from '@material-ui/data-grid';
import config from '../../config';
import RequestService from '../../services/RequestService';
import Loader from '../../components/loader/Loader';

const Reservation = () => {
  const match = useRouteMatch();
  const history = useHistory();
  const { reservationId } = match.params;

  const [reservation, setReservation] = useState(null);

  const fetchReservation = () => {
    RequestService.get(`${config.backendUrl}/reservations/${reservationId}`, (response) => {
      setReservation(response.data);
    });
  };

  useEffect(() => {
    fetchReservation();
  }, []);

  if (!reservation) return <Loader />;

  const columns = [
    {
      field: 'id',
      headerName: 'Delivery ID',
      width: 150,
      editable: false,
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 200,
      editable: false,
    },
    {
      field: 'delivery_date_time',
      headerName: 'Delivery Date/Time',
      width: 200,
      editable: false,
    },
    {
      field: 'delivery_route_id',
      headerName: 'Delivery Route ID',
      width: 200,
      editable: false,
    },
  ];

  const rows = reservation.deliveries.map((row) => (
    {
      id: row.id,
      status: row.status,
      deliveryDateTime: row.delivery_date_time,
      deliveryRouteId: row.delivery_route_id,
    }
  ));

  return (
    <Box py={2}>
      <Box>
        <Typography variant="h3">
          Reservation #
          {reservationId}
        </Typography>
      </Box>
      <Box>
        <Typography variant="h5">
          {reservation.propertyName}
        </Typography>
        <Typography variant="h5">
          {reservation.propertyAddress}
        </Typography>
        <Typography variant="h5">
          {reservation.startDate}
          {' '}
          -
          {' '}
          {reservation.endDate}
        </Typography>
      </Box>
      <Box mt={2}>
        <Typography variant="h6">
          Deliveries:
        </Typography>
        <Box style={{ height: '400px' }}>
          <DataGrid
            columns={columns}
            rows={rows}
            onRowClick={(row) => {
              history.push(`/fulfillment/deliveries/${row.id}`);
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Reservation;
