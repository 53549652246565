import React, { useState } from 'react';
import {
  Box,
  Button,
  Menu,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Typography,
} from '@mui/material';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';

const ItemCategoryFilter = ({
  itemCategoryFilter,
  handleItemCategoryFilterCheck,
  itemCategoryStore,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleItemCategoryFilterClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleItemCategoryFilterClose = () => {
    setAnchorEl(null);
  };
  itemCategoryStore.getItemCategories();

  return (
    <Box>
      <Button
        className="filterButton"
        display="flex"
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleItemCategoryFilterClick}
      >
        <TuneOutlinedIcon />
        <Typography ml={1}>
          {`Select Category${
            itemCategoryFilter.length > 0 ? ` (${itemCategoryFilter.length})` : ''
          }`}
        </Typography>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleItemCategoryFilterClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <FormGroup>
          {itemCategoryStore.itemCategories.map((itemCategory) => (
            <FormControlLabel
              sx={{
                width: '200px',
                m: 0,
                p: 0,
              }}
              control={(
                <Checkbox
                  onChange={(e) => handleItemCategoryFilterCheck(itemCategory.name,
                    e.target.checked)}
                  checked={itemCategoryFilter.includes(itemCategory.name)}
                />
              )}
              label={itemCategory.name}
            />
          ))}
        </FormGroup>
      </Menu>
    </Box>
  );
};

export default ItemCategoryFilter;
