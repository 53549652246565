import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useRouteMatch } from 'react-router';
import { Box, Typography, CircularProgress } from '@mui/material';
import WushTable from '../../../components/WushTable';
import fetchReservationImport from '../../../services/reservationImports/fetchReservationImport';

const ViewReservationImport = () => {
  const match = useRouteMatch();
  const { reservationImportId } = match.params;

  const [reservationImport, setReservationImport] = useState(null);

  useEffect(() => {
    fetchReservationImport(reservationImportId, (response) => {
      setReservationImport(response.data);
    });
  }, []);

  if (!reservationImport) return <CircularProgress />;

  const removePropertyName = (data) => {
    const dataWithoutProperty = { ...data };
    delete dataWithoutProperty['Property Name'];
    return dataWithoutProperty;
  };

  const propertyLinkRenderer = (row) => {
    if (row.reservation?.location_id) {
      return (
        <Link to={`/property-management/properties/edit/${row.reservation.location_id}`} target="_blank">
          {row.data['Property Name']}
        </Link>
      );
    }
    return row.data['Property Name'];
  };

  const dataRenderer = (row) => {
    const dataWithoutProperty = removePropertyName(row.data);
    return Object.entries(dataWithoutProperty)
      .map(([key, value]) => `${key}: ${value}`)
      .join(', ');
  };

  const processedRenderer = (row) => (row.processed ? 'Yes' : 'No');

  const reservationRenderer = (row) => {
    if (row.reservation?.id) {
      return (
        <Link to={`/reservation-management/reservations/${row.reservation.id}`} target="_blank">
          {row.reservation.id}
        </Link>
      );
    }
    return null;
  };

  return (
    <Box py={2}>
      <Box mb={2}>
        <Typography variant="h3">
          Import #
          {reservationImportId}
        </Typography>
      </Box>
      <WushTable
        headers={[
          { label: 'ID', name: 'id' },
          { label: 'Error', name: 'error_message' },
          { label: 'Property', customGetter: propertyLinkRenderer },
          { label: 'Data', customGetter: dataRenderer },
          { label: 'Processed', customGetter: processedRenderer },
          { label: 'Reservation', customGetter: reservationRenderer },
        ]}
        data={reservationImport.reservation_import_rows}
        defaultRowsPerPage={-1}
      />
    </Box>
  );
};

export default ViewReservationImport;
