import React, { useState } from 'react';

import {
  withScriptjs, withGoogleMap, GoogleMap, DirectionsRenderer,
} from 'react-google-maps';

import './map.css';

const location = {
  address: '577 Sandbridge Rd, Virginia Beach, VA 23456',
  lat: 36.742470,
  lng: -75.957180,
};

const DeliveryMap = ((props) => (
  <DeliveryMapWrapper
    googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCBiNt4WmInCBb8pEHhHZGj2rtIdKrPYyw&v=3.exp&libraries=geometry,drawing,places"
    loadingElement={<div style={{ height: '100%' }} />}
    containerElement={<div style={{ height: '400px' }} />}
    mapElement={<div style={{ height: '100%' }} />}
    {...props}
  />
));

const DeliveryMapWrapper = withScriptjs(withGoogleMap(({ deliveries }) => {
  const [directions, setDirections] = useState(null);
  const { google } = window;
  const DirectionsService = new google.maps.DirectionsService();
  const waypoints = deliveries?.map((delivery) => ({
    location: delivery.deliveryAddress,
    stopover: true,
  }));

  DirectionsService.route({
    origin: '577 Sandbridge Rd, Virginia Beach, VA 23456',
    destination: '601 Sandbridge Rd Virginia Beach, VA 23456',
    travelMode: google.maps.TravelMode.DRIVING,
    waypoints,
  }, (result, status) => {
    if (status === google.maps.DirectionsStatus.OK) {
      setDirections(result);
    } else {
      // eslint-disable-next-line
      console.error(`error fetching directions ${result}`);
    }
  });

  return (
    <GoogleMap
      defaultZoom={12}
      defaultCenter={{ lat: location.lat, lng: location.lng }}
    >
      {directions && <DirectionsRenderer directions={directions} />}
    </GoogleMap>
  );
}));

export default DeliveryMap;
