import React, { useState, useEffect } from 'react';
import {
  Box, Typography, TextField, InputAdornment,
} from '@material-ui/core';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import TakeoutDiningOutlinedIcon from '@mui/icons-material/TakeoutDiningOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import config from '../../../../config';
import RequestService from '../../../../services/RequestService';
import Loader from '../../../../components/loader/Loader';
import DeliveryRouteCard from '../../../../components/cards/DeliveryRouteCard';
import ItemCategoryFilter from '../../../../components/filters/ItemCategoryFilter';

const DeliveryRouteInProgress = ({ itemCategoryStore }) => {
  itemCategoryStore.getItemCategories();

  const [search, setSearch] = useState(null);
  const [inPack, setInPack] = useState(null);
  const [outForDelivery, setOutForDelivery] = useState(null);
  const [itemCategoryFilter, setItemCategoryFilter] = useState([]);

  useEffect(() => {
    // Get pack data
    RequestService.get(`${config.backendUrl}/delivery-routes/scan-in-progress`, (response) => {
      setInPack(response.data);
    });
    // Get out for delivery data
    RequestService.get(`${config.backendUrl}/delivery-routes/out-for-delivery`, (response) => {
      setOutForDelivery(response.data);
    });
  }, []);

  const handleSearch = (value) => {
    setSearch(value);
  };

  const handleItemCategoryFilterCheck = (filterValue, checked) => {
    if (checked) {
      setItemCategoryFilter(itemCategoryFilter.concat([filterValue]));
    } else {
      setItemCategoryFilter(itemCategoryFilter.filter((e) => e !== filterValue));
    }
  };

  if (!inPack) return <Loader />;
  if (!outForDelivery) return <Loader />;

  return (
    <Box py={2}>
      <Box mb={2}>
        <Box display="flex" justifyContent="space-between">
          <Box>
            <ItemCategoryFilter
              itemCategoryFilter={itemCategoryFilter}
              handleItemCategoryFilterCheck={handleItemCategoryFilterCheck}
              itemCategoryStore={itemCategoryStore}
            />
          </Box>
          <Box>
            <TextField
              className="searchBar"
              onChange={(e) => handleSearch(e.target.value)}
              value={search}
              label="Search"
              size="small"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchOutlinedIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Box>
      </Box>
      <Box mb={2}>
        <Box display="flex">
          <TakeoutDiningOutlinedIcon fontSize="large" />
          <Typography variant="h5">PACK</Typography>
        </Box>
        <hr />
        <Box
          display="flex"
          flexDirection="row"
          overflow="scroll"
          height={320}
        >
          {inPack.map((deliveryRoute) => (
            <Box
              mb={2}
              px={1}
              width="25%"
              key={deliveryRoute.id}
            >
              <DeliveryRouteCard deliveryRoute={deliveryRoute} />
            </Box>
          ))}
        </Box>
      </Box>
      <Box>
        <Box display="flex">
          <LocalShippingOutlinedIcon fontSize="large" />
          <Typography variant="h5">OUT FOR DELIVERY</Typography>
        </Box>
        <hr />
        <Box
          display="flex"
          flexDirection="row"
          overflow="scroll"
          height={320}
        >
          {outForDelivery.map((deliveryRoute) => (
            <Box
              mb={2}
              px={1}
              width="25%"
              key={deliveryRoute.id}
            >
              <DeliveryRouteCard deliveryRoute={deliveryRoute} showMarkDelivered />
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default DeliveryRouteInProgress;
