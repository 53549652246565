import React from 'react';
import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';

const PercentCapacityChart = ({ data }) => (
  <ComposedChart
    layout="vertical"
    width={1000}
    height={data.length * 40}
    data={data}
    margin={{
      top: 20,
      right: 20,
      bottom: 20,
      left: 20,
    }}
  >
    <CartesianGrid stroke="#f5f5f5" />
    <XAxis type="number" />
    <YAxis dataKey="name" type="category" scale="auto" />
    <Tooltip />
    <Legend />
    <Bar dataKey="percentEnrollment" barSize={100} fill="#413ea0" />
  </ComposedChart>
);

export default PercentCapacityChart;
