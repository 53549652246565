import {
  Box,
  Button,
  Grid,
  LinearProgress,
  TextField,
  Typography,
  InputAdornment,
  Pagination,
} from '@mui/material';
import React, { useState } from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router';
import Fuse from 'fuse.js';
import Badge from '@material-ui/core/Badge';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import Paper from '@mui/material/Paper';
import LocationOn from '@mui/icons-material/LocationOn';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { isBefore } from 'date-fns';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import config from '../../../../config';
import RequestService from '../../../../services/RequestService';
import WushCard from '../../../../components/WushCard';
import Loader from '../../../../components/loader/Loader';
import ReservationCard from '../../../../components/cards/ReservationCard';
import DeliveryMap from './DeliveryMap';
import DateFilter from '../../../../components/filters/DateFilter';
import ZoneFilter from '../../../../components/filters/ZoneFilter';
import ItemCategoryFilter from '../../../../components/filters/ItemCategoryFilter';

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 1,
  borderRadius: '10px',

  // change background colour if dragging
  background: isDragging ? 'white' : 'white',
  transform: isDragging ? 'rotate(10deg)' : 'rotate(10deg)',
  boxShadow: isDragging ? '8px 8px 10px darkgrey' : '',

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? 'lightgrey' : 'white',
  padding: grid,
  width: '100%',
});

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const Deliveries = ({ clientStore, itemCategoryStore, locationZoneStore }) => {
  const [reservationTypeFilter] = React.useState([]);
  const [itemCategoryFilter, setItemCategoryFilter] = React.useState([]);
  const [paymentStatusFilter] = React.useState([]);
  const [selectedDeliveries, setSelectedDeliveries] = React.useState([]);
  const [search, setSearch] = React.useState();
  const [deliveries, setDeliveries] = React.useState(null);
  const [showMap, setShowMap] = useState(false);
  const handleShowMap = () => {
    setShowMap(!showMap);
  };

  const [zoneFilter, setZoneFilter] = useState([]);
  const [dateFilter, setDateFilter] = useState([]);
  const [dateData, setDateData] = useState([]);

  // pagination
  const [currentPage, setCurrentPage] = useState(1);

  // drop down menu  for Item Category
  const handleItemCategoryFilterCheck = (filterValue, checked) => {
    if (checked) {
      setItemCategoryFilter(itemCategoryFilter.concat([filterValue]));
    } else {
      setItemCategoryFilter(itemCategoryFilter.filter((e) => e !== filterValue));
    }
  };

  // drop down menu for Zone
  const handleZoneFilterCheck = (filterValue, checked) => {
    if (checked) {
      setZoneFilter(zoneFilter.concat([filterValue]));
    } else {
      setZoneFilter(zoneFilter.filter((e) => e !== filterValue));
    }
  };

  // Drop Down menu for Date Filter
  const handleDateFilterCheck = (filterValue, checked, dateValue) => {
    if (checked) {
      setDateFilter(dateFilter.pop());
      setDateFilter(dateFilter.concat([filterValue]));
      setDateData(dateData.pop());
      setDateData(dateData.concat([dateValue]));
    } else {
      setDateFilter(dateFilter.filter((e) => e !== filterValue));
      setDateData(dateData.filter((e) => e !== filterValue));
    }
  };

  const handleDateFilterLogic = (deliveryDate) => {
    const newDate = new Date(deliveryDate);

    if (isBefore(newDate, dateData[0])) {
      return true;
    }
    return false;
  };

  const history = useHistory();

  const fuseOptions = {
    keys: [
      { name: 'id', weight: 1.0 },
      'reservationId',
      'reservation.propertyName',
      'reservation.customerName',
    ],
  };

  clientStore.getClients();
  itemCategoryStore.getItemCategories();
  locationZoneStore.getLocationZones();

  if (!deliveries) {
    RequestService.get(`${config.backendUrl}/deliveries`, (response) => {
      setDeliveries(response.data);
    });
  }

  if (!deliveries) return <Loader />;

  if (!itemCategoryStore.itemCategoriesPreLoaded) return <LinearProgress />;

  const fuseDeliveries = new Fuse(deliveries, fuseOptions);

  const filterDeliveries = () => deliveries.filter(
    (delivery) => reservationTypeFilter.includes(delivery.reservation.reservationType)
        || delivery.reservation.itemCategoryNames
          .some((itemCategoryName) => itemCategoryFilter.includes(itemCategoryName))
        || paymentStatusFilter.includes(delivery.reservation.paid ? 'paid' : 'unpaid')
        || zoneFilter.includes(delivery.reservation.propertyZoneName)
        || handleDateFilterLogic(delivery.deliveryDate),
  );

  const handleSearch = (value) => {
    setSearch(value);
  };

  const filteredDeliveries = () => {
    if (
      reservationTypeFilter.length > 0
      || itemCategoryFilter.length > 0
      || paymentStatusFilter.length > 0
      || zoneFilter.length > 0
      || dateFilter.length > 0
    ) {
      return filterDeliveries();
    }
    if (search) {
      return fuseDeliveries.search(search).map((item) => item.item);
    }

    return deliveries;
  };

  const createDeliveryRoute = () => {
    RequestService.post(
      `${config.backendUrl}/delivery-routes`,
      {
        deliveryRoute: {
          deliveryIds: selectedDeliveries.map((delivery) => delivery.id),
        },
      },
      () => {
        history.push('/fulfillment/delivery-routes');
      },
    );
  };

  const handleDrop = (result) => {
    if (
      !result.destination
      || (result.source.droppableId === 'full-list' && result.destination.droppableId === 'full-list')
    ) {
      return;
    }

    if (result.source.droppableId === 'route' && result.destination.droppableId === 'route') {
      const reordered = reorder(selectedDeliveries, result.source.index, result.destination.index);

      setSelectedDeliveries(reordered);
    } else if (result.destination.droppableId === 'route') {
      const updatedSelected = selectedDeliveries.concat(
        deliveries.filter((e) => parseInt(e.id, 10) === parseInt(result.draggableId, 10))[0],
      );
      setSelectedDeliveries(updatedSelected);
      setDeliveries(
        deliveries.filter((e) => parseInt(e.id, 10) !== parseInt(result.draggableId, 10)),
      );
    } else if (result.source.droppableId === 'route') {
      setSelectedDeliveries(
        selectedDeliveries.filter((e) => parseInt(e.id, 10) !== parseInt(result.draggableId, 10)),
      );
      setDeliveries(
        deliveries.concat(
          selectedDeliveries.filter(
            (e) => parseInt(e.id, 10) === parseInt(result.draggableId, 10),
          )[0],
        ),
      );
    }
  };

  const handleAddToRoute = (delivery) => {
    const updatedSelected = selectedDeliveries.concat(
      deliveries.filter((e) => parseInt(e.id, 10) === parseInt(delivery.id, 10))[0],
    );
    setSelectedDeliveries(updatedSelected);
    setDeliveries(deliveries.filter((e) => parseInt(e.id, 10) !== parseInt(delivery.id, 10)));
  };

  const handleDelete = (delivery) => {
    RequestService.delete(`${config.backendUrl}/deliveries/${delivery.id}`, () => {
      setDeliveries(deliveries.filter((e) => parseInt(e.id, 10) !== parseInt(delivery.id, 10)));
    });
  };

  function groupReservationItems(selectedDeliveriesGroups) {
    const groupedItems = {};
    let totalItems = 0;
    const newSelectedDeliveries = selectedDeliveriesGroups.map((delivery) => {
      const { reservation } = delivery;
      const items = reservation.reservationItems;
      const deliveryItems = {};

      items.forEach((item) => {
        const { itemName } = item;
        const itemQuantity = item.reservedQuantity;

        if (deliveryItems[itemName]) {
          deliveryItems[itemName] += itemQuantity;
        } else {
          deliveryItems[itemName] = itemQuantity;
        }

        if (groupedItems[itemName]) {
          groupedItems[itemName] += itemQuantity;
        } else {
          groupedItems[itemName] = itemQuantity;
        }

        totalItems += itemQuantity;
      });

      return { ...delivery, deliveryItems };
    });

    return { groupedItems, totalItems, selectedDeliveriesGroups: newSelectedDeliveries };
  }

  const { groupedItems, totalItems } = groupReservationItems(selectedDeliveries);

  // pagination logic and variables
  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };
  // set how many deliveries per screen
  const rowsPerPage = 20;
  const startIndex = (currentPage - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const currentDeliveries = filteredDeliveries().slice(startIndex, endIndex);

  const calculatePercentFull = () => (totalItems / 500) * 100;

  return (
    <Box py={2} className="deliveries">
      <Grid container>
        <Grid item md={9} textAlign="left">
          <Box display="flex" justifyContent="space-between" mr={6} pb={3}>
            <Box className="filterBox" display="flex">
              <FilterAltOutlinedIcon />
              <Typography ml={1}>Filter by Order</Typography>
            </Box>

            {/* Date Filter Button */}
            <Box>
              <DateFilter dateFilter={dateFilter} handleDateFilterCheck={handleDateFilterCheck} />
            </Box>

            {/* Zone Filter Button */}
            <Box>
              <ZoneFilter
                zoneFilter={zoneFilter}
                handleZoneFilterCheck={handleZoneFilterCheck}
                locationZoneStore={locationZoneStore}
              />
            </Box>
            {/* Item Category Button */}
            <Box>
              <ItemCategoryFilter
                itemCategoryFilter={itemCategoryFilter}
                handleItemCategoryFilterCheck={handleItemCategoryFilterCheck}
                itemCategoryStore={itemCategoryStore}
              />
            </Box>
          </Box>

          <Box mb={0} display="flex" className="reservationBox">
            <Typography variant="h5">Reservations</Typography>
            <TextField
              className="searchBar"
              onChange={(e) => handleSearch(e.target.value)}
              value={search}
              label="Search"
              size="small"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchOutlinedIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <hr />
        </Grid>

        <Grid item md={3} textAlign="right">
          <Box mb={2}>
            <Button
              variant="contained"
              onClick={() => {
                createDeliveryRoute();
              }}
              disabled={selectedDeliveries.length === 0}
            >
              Create Route
            </Button>
          </Box>
        </Grid>
        <DragDropContext onDragEnd={handleDrop}>
          <Grid item md={9} mt={-1}>
            <Droppable droppableId="full-list">
              {(providedUpper, snapshotUpper) => (
                <Box
                  {...providedUpper.droppableProps}
                  ref={providedUpper.innerRef}
                  display="flex"
                  flexWrap="wrap"
                  style={getListStyle(snapshotUpper.isDraggingOver)}
                >
                  {currentDeliveries.map((delivery, index) => (
                    <Draggable key={delivery.id} draggableId={`${delivery.id}`} index={index}>
                      {(provided, snapshot) => (
                        <Box
                          width="25%"
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                        >
                          <ReservationCard
                            reservation={delivery.reservation}
                            addToRoute={() => handleAddToRoute(delivery)}
                            onDelete={() => handleDelete(delivery)}
                          />
                        </Box>
                      )}
                    </Draggable>
                  ))}
                </Box>
              )}
            </Droppable>
            <Box mb={1} className="pagination">
              <Pagination
                count={Math.ceil(deliveries.length / rowsPerPage)}
                page={currentPage}
                onChange={handleChangePage}
                showFirstButton
                showLastButton
              />
            </Box>
          </Grid>
          <Grid item md={3}>
            <Droppable droppableId="route">
              {(providedUpper, snapshotUpper) => (
                <Box border="1px solid lightblue" borderRadius="10px" mb={2}>
                  <Box
                    padding={1}
                    bgcolor="lightblue"
                    borderRadius="10px 10px 0px 0"
                    border="1px solid lightblue"
                  >
                    <strong>New Delivery Route</strong>
                  </Box>
                  <Box
                    {...providedUpper.droppableProps}
                    ref={providedUpper.innerRef}
                    style={getListStyle(snapshotUpper.isDraggingOver)}
                  >
                    {selectedDeliveries.length === 0 && (
                      <Box border="1px dashed grey" p={1}>
                        <Typography variant="body1">Drag cards here to create route</Typography>
                      </Box>
                    )}
                    {selectedDeliveries.map((delivery, index) => (
                      <Draggable key={delivery.id} draggableId={`${delivery.id}`} index={index}>
                        {(provided, snapshot) => (
                          <Box
                            width="100%"
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                          >
                            <WushCard>
                              <Box display="flex" alignItems="center">
                                <Box mr={2}>
                                  <Badge badgeContent={index + 1} color="white">
                                    <LocationOn style={{ color: '#00ccff' }} />
                                  </Badge>
                                </Box>
                                <Box mr={2}>
                                  <Typography variant="subtitle2">
                                    <strong>{delivery.reservation.propertyName}</strong>
                                    <strong>{'  '}</strong>
                                    {delivery.reservation.propertyAddress}
                                  </Typography>
                                </Box>
                              </Box>
                            </WushCard>
                          </Box>
                        )}
                      </Draggable>
                    ))}
                    {selectedDeliveries.length > 0 && (
                      <>
                        <Box textAlign="right" mb={1}>
                          <Button variant="contained" color="primary" onClick={handleShowMap}>
                            {showMap ? 'Hide Map' : 'Show Map'}
                          </Button>
                        </Box>
                        {showMap && <DeliveryMap deliveries={selectedDeliveries} />}
                      </>
                    )}
                  </Box>
                </Box>
              )}
            </Droppable>
            <Paper
              sx={{
                padding: '8px',
                borderRadius: '20px',
                boxShadow:
                  '0px 0px 5px rgba(255, 255, 255, 2), 0px 0px 5px rgba(128, 128, 128, 0.5)',
                position: 'relative',
                bgcolor: 'grey.200',
              }}
            >
              <div
                style={{
                  top: '-10px',
                  left: '-10px',
                  right: '-10px',
                  bottom: '-10px',
                  background:
                    'linear-gradient(to bottom right, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3))',
                  borderRadius: '30px',
                  zIndex: '-1',
                  boxShadow: '0 0 10px rgba(255, 255, 255, 0.5) inset',
                }}
              />
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Box
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  className="progress-bar"
                >
                  <p>% Full</p>

                </Box>
                <Box display="flex" flexDirection="row" p={0.5}>
                  <LocalShippingOutlinedIcon />
                  <Box sx={{ width: '100%' }} p={1}>
                    <LinearProgress variant="determinate" value={calculatePercentFull()} />
                  </Box>
                </Box>

                {Object.keys(groupedItems).map((itemName) => (
                  <Box sx={{ marginBottom: '5px' }} className="progress-bar route-item-listout">
                    <p>
                      {groupedItems[itemName]}
                      {'x      '}
                      {itemName}
                    </p>
                  </Box>
                ))}
                <Typography>
                  Total Items:
                  {' '}
                  {totalItems}
                </Typography>
              </Box>
            </Paper>
          </Grid>
        </DragDropContext>
      </Grid>
    </Box>
  );
};

Deliveries.propTypes = {
  clientStore: PropTypes.any,
};

export default observer(Deliveries);
