import {
  Box, FormLabel,
} from '@mui/material';
import { Field } from 'formik';
import React from 'react';

const OnlinePayment = () => (
  <Box>
    <Box p="0.5rem">
      <Box>
        <FormLabel>Payer Name</FormLabel>
      </Box>
      <Box>
        <Field name="payerName" />
      </Box>
    </Box>
    <Box p="0.5rem">
      <Box>
        <FormLabel>Invoice Number</FormLabel>
      </Box>
      <Box>
        <Field name="onlinePaymentId" />
      </Box>
    </Box>
  </Box>
);

export default OnlinePayment;
