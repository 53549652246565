import config from "../../config"
import RequestService from "../RequestService"

const prepareReservationImport = (file, successCallback, failureCallback) => {
  let formData = new FormData()
  formData.append("file", file)

  RequestService.post(`${config.backendUrlV2}/reservation_imports/prepare`, formData, successCallback, failureCallback)
}

export default prepareReservationImport;