import axios from "axios";

const config = () => ({
  headers: {
    Authorization: localStorage.getItem("Token"),
    EmployeeId: localStorage.getItem("CurrentEmployeeId"),
  },
});

const RequestService = {
  get: async (url, callback, failureCallback) => {
    axios
      .get(url, config())
      .then((response) => {
        callback(response);
      })
      .catch((err) => {
        if (err.response.status === 401) {
          localStorage.clear();
          window.location = "/login";
        } else {
          failureCallback && failureCallback(err);
        }
      });
  },
  post: async (url, body, callback, failureCallback) => {
    axios
      .post(url, body, config())
      .then((response) => {
        callback(response);
      })
      .catch((err) => failureCallback && failureCallback(err));
  },
  patch: async (url, body, callback, failureCallback) => {
    axios
      .patch(url, body, config())
      .then((response) => {
        callback(response);
      })
      .catch((err) => failureCallback && failureCallback(err));
  },
  delete: async (url, callback, failureCallback) => {
    axios
      .delete(url, config())
      .then((response) => {
        callback(response);
      })
      .catch((err) => failureCallback && failureCallback(err));
  },
};

export default RequestService;
