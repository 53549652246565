import {
  Box,
  Button,
  CardActions,
  CardContent,
  CardHeader,
  Collapse,
  Divider,
  IconButton,
  styled,
  Typography,
  Dialog,
} from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import ListIcon from '@mui/icons-material/List';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import Delete from '@mui/icons-material/Delete';
import React, { useState } from 'react';
import SellOutlinedIcon from '@mui/icons-material/SellOutlined';
import { format } from 'date-fns';
import BaseCard from './BaseCard';
import ReservationOverlay from '../../containers/fulfillment/containers/reservations/ReservationOverlay';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const ReservationCard = ({
  reservation, addToRoute, ispickup, onDelete,
}) => {
  const [itemsExpanded, setItemsExpanded] = useState(false);
  // for Modal
  const [dialogOpen, setDialogOpen] = useState(false);
  const handleDialogOpen = () => setDialogOpen(true);
  const handleDialogClose = () => setDialogOpen(false);

  // date visual editing
  const startDate = format(new Date(reservation.startDate), 'M/d/yy');
  const endDate = format(new Date(reservation.endDate), 'M/d/yy');

  const calculateTotalItemQuantity = () => {
    let totalQuantity = 0;
    reservation.reservationItems.forEach((item) => {
      totalQuantity += item.reservedQuantity;
    });
    return totalQuantity;
  };

  return (
    <BaseCard>
      <CardHeader
        className={ispickup ? 'MuiCardHeader-root pickup' : 'MuiCardHeader-root green'}
        title={(
          <Box className="card-header-text">
            <Typography variant="h6" className="card-header-id">
              D#
              {reservation.id}
            </Typography>
          </Box>
        )}
        action={(
          <Box className="card-header-text">
            <PhoneAndroidIcon fontSize="8" />
            <Typography pl={0.3} variant="h6" className="card-header-id">
              {` ${reservation.propertyName}`}
            </Typography>
            <IconButton size="small" aria-label="settings" disabled>
              <ExpandLessIcon className="expand-less" fontSize="20" />
            </IconButton>
          </Box>
        )}
      />
      <Collapse in={!itemsExpanded} timeout="auto">
        <CardContent>
          <Box>
            {/* Person Name */}
            {(reservation.customerName || reservation.clientName) && (
              <Box className="card-content-row">
                <Box pr={1}>
                  <PersonOutlineOutlinedIcon className="bg_outline" />
                </Box>
                <Typography>{reservation.customerName || reservation.clientName}</Typography>
              </Box>
            )}
            {/*  Property Name */}
            <Box className="card-content-row">
              <Box pr={1}>
                <HomeOutlinedIcon className="bg_outline" />
              </Box>
              <Typography>{reservation.propertyName}</Typography>
            </Box>
            {/*  Address  */}
            <Box className="card-content-row">
              <Box pr={1}>
                <LocationOnOutlinedIcon className="bg_outline" />
              </Box>
              <Typography>{reservation.propertyAddress}</Typography>
            </Box>
            {/*  Date Range  */}
            <Box className="card-content-row">
              <Box pr={1}>
                <DateRangeOutlinedIcon className="bg_outline" />
              </Box>
              <Box className="dates">
                <Typography>{startDate}</Typography>
                <Typography px={0.5}>to</Typography>
                <Typography>{endDate}</Typography>
              </Box>
            </Box>
          </Box>
        </CardContent>
      </Collapse>
      <CardContent>
        <Box>
          {reservation.propertyZoneName && (
            <Box className="card-content-row" bgcolor={reservation.propertyZoneColor} my={1}>
              <Box pr={1}>
                <MapOutlinedIcon className="bg_outline" />
              </Box>
              <Box className="zone">
                <Typography>{reservation.propertyZoneName}</Typography>
                <Typography>Delivery</Typography>
              </Box>
            </Box>
          )}
          <Box className="card-content-row outline" my={1}>
            <Box pr={1}>
              <ListIcon className="bg_outline dark" />
            </Box>
            <Typography>{calculateTotalItemQuantity()}</Typography>
            <Typography pl={0.5}>Items</Typography>
            <ExpandMore
              expand={itemsExpanded}
              onClick={() => setItemsExpanded(!itemsExpanded)}
              aria-expanded={itemsExpanded}
              aria-label="show more"
              size="small"
            >
              <ExpandMoreIcon fontSize="8" />
            </ExpandMore>
          </Box>
        </Box>
      </CardContent>
      <Collapse in={itemsExpanded} timeout="auto">
        <CardContent>
          <Box overflow="scroll" style={{ height: '135px', overflow: 'hidden' }}>
            {reservation.reservationItems.map((reservationItem) => (
              <Box className="card-content-row">
                <Box pr={2}>
                  <Typography>
                    <strong>{reservationItem.reservedQuantity}</strong>
                  </Typography>
                </Box>
                <Typography>{reservationItem.itemName}</Typography>
              </Box>
            ))}
          </Box>
        </CardContent>
      </Collapse>
      <Divider />
      <CardActions className="MuiCardFooter">
        {reservation.isServiceTicket && (
          <Box className="service-ticket">
            <SellOutlinedIcon />
            Service Ticket
          </Box>
        )}
        {addToRoute && (
          <Box>
            <Button size="small" variant="contained" onClick={addToRoute} sx={{ fontSize: '12px' }}>
              Add to Route
            </Button>
          </Box>
        )}
        <IconButton size="small" onClick={handleDialogOpen}>
          <OpenInFullIcon className="expand-styles" sx={{ width: '18px', height: '18px' }} />
        </IconButton>
        {onDelete && (
          <IconButton size="small" onClick={onDelete}>
            <Delete className="expand-styles" sx={{ width: '18px', height: '18px' }} />
          </IconButton>
        )}
        <Dialog
          maxWidth="lg"
          open={dialogOpen}
          onClose={handleDialogClose}
        >
          <Box className="dialog-reservation-overlay">
            <ReservationOverlay reservation={reservation} />
          </Box>
        </Dialog>
      </CardActions>
    </BaseCard>
  );
};

export default ReservationCard;
